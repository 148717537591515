<template>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;padding: 40px 0 20px 0">
        <va-card>
            <va-card-title>
                Password Assistance
            </va-card-title>
            <va-card-content>
                <div class="va-spacing-y-4">
                    <p>Enter the email (username) associated with your WareMS account.</p>
                
                    <form @submit.prevent="resetPwd" class="mt-3 va-spacing-y-4">
                        <div class="row">
                            <va-input label="Email (Username)" v-model="form.username" name="Email" type="email" minlength="3" required/>
                        </div>
                        <div class="row">
                            <va-button type="submit">Submit</va-button>
                        </div>
                    </form>
                </div>
            </va-card-content>
        </va-card>
    </div>
</template>
<script setup lang="ts">
    import { reactive } from 'vue'
    import { useModal } from 'vuestic-ui'

    import authService from '@/services/auth-service'

    const { confirm } = useModal()
    const form = reactive({
        username: ''
    })

    const resetPwd = async() => {
        let res = await authService.resetPwdToken(form.username)

        if (res.status === 200) {
            confirm({ message: 'Email will send to the address if username is on our system.', cancelText: '', noDismiss: true })
                .then(() => window.location.href = '/login')
        } else {
            confirm('Reset Password failed.')
        }
    }
</script>