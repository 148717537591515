import axios from "axios"

import type { App } from "vue"

export default {
  install: (app: App): void => {
    app.config.globalProperties.$http = axios
    const $http = app.config.globalProperties.$http

    const handleResponse = (response: unknown) => {
      return response
    };

    const handleError = (err: any) => {
      if (err.response && err.response.status == 401) {
        localStorage.removeItem('jwttoken')
      }

      return Promise.reject(err)
    }
    $http.interceptors.response.use(handleResponse, handleError);
    $http.interceptors.request.use((request: any) => {
      if (localStorage.getItem('jwttoken')) {
        request.headers.Authorization = `Bearer ${localStorage.getItem('jwttoken')}`
      }
      request.headers['Content-Type'] = 'application/json'
      return request
    });
  },
};
