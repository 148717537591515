<template>
    <div class="mt-5 ml-4 mr-5" style="display: grid;">
        <table class="va-table va-table--hoverable">
            <thead>
                <tr class="table-header-default">
                    <th>Product Name</th>
                    <th style="width: 120px;">Available</th>
                    <th style="width: 100px;">Ordered</th>
                    <th>Notes</th>
                    <th>Selected Weight</th>
                    <th>Ready</th>
                    <th style="width: 150px;">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="orderItem of order.orderItems">
                    <td>{{ orderItem.name }}</td>
                    <td>{{ orderItem.availableQty || orderItem.availableWeight }}</td>
                    <td>{{ orderItem.quantity || orderItem.weight }}</td>
                    <td>{{ orderItem.notes }}</td>
                    <td class="va-spacing-x-1" style="padding-top: 0;">
                        <va-chip v-for="inv in orderItem.selectedInventories" :key="inv.inventoryId" class="ml-2 mt-1">
                            {{ inv.weight }}
                        </va-chip>
                    </td>
                    <td>
                        <va-icon v-if="orderItem.invalid" name="cancel" color="danger" size="large"/>
                        <va-icon v-else name="check_circle" color="success" size="large"/>
                    </td>
                    <td>
                        <va-button v-if="orderItem.hasWeight === 'Yes'" @click="selectWeights(orderItem)">Select Weights</va-button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="flex md12 sm12 xs12 va-spacing-x-4 mt-5" style="text-align: center">
            <va-button @click="back" preset="outline">Back</va-button>
            <va-button @click="submitForm" :disabled="disableSubmit">Submit</va-button>
        </div>
    </div>

    <va-modal v-model="selectWeightModal.show" hideDefaultActions noDismiss>
        <p class="va-h6 mb-4">{{ selectWeightModal.inventories[0].productName }}</p>
        <div class="mb-3">
            <label class="va-input-label" style="color: #154EC1;">Weights</label>
            <div>
                <va-input style="width: 80px;" type="number" step="0.01" v-model="selectWeightModal.inputWeights[i-1]"
                    v-for="i in Array.from({ length: selectWeightModal.orderItem.quantity*1 }, (v, k) => k + 1)"
                    :key="i" 
                    :style="{border: !selectWeightModal.invalid || selectWeightModal.weightCheckedIdx.includes(i-1) ? '' : '.2rem solid red'}"/>
            </div>
            <p class="mt-3" style="color: red;" v-if="selectWeightModal.invalid">*Please review unavailable weight(s)</p>
        </div>
        <!-- <div class="mt-4" style="width: 500px;">
            <va-data-table v-model="selectWeightModal.selectedInventoryIds" :items="selectWeightModal.inventories" :columns="inventoryColumn"
                items-track-by="inventoryId" select-mode="multiple" sticky-header hoverable>
                <template #cell(time)="{ value }">
                    {{ new Date(value*1).toLocaleString() }}
                </template>
            </va-data-table>
        </div> -->
        <div class="mt-4">
            <va-button @click="applySelectedWeight">OK</va-button>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { useModal } from 'vuestic-ui'

    import type OrderDetails from '@/types/OrderDetails'
    import type OrderItem from '@/types/OrderItem'
    import type { InventoryDTO, OrderOutboundRequest } from '@/models/warems.models'
    import type { AxiosResponse, AxiosError } from 'axios'

    import orderService from '@/services/order-service'
    import inventoryService from '@/services/inventory-service'
    import AccessUtil from '@/utils/access'
    import Utils from '@/utils/utils'
    import { useLoadingStore } from '@/stores/loading-store'

    const { showLoading, hideLoading } = useLoadingStore()
    const { confirm } = useModal()
    const route = useRoute()
    const order = ref({orderItems: [] as OrderItem[]} as OrderDetails)
    const selectWeightModal = ref({
        show: false,
        orderItem: {} as OrderItem,
        inventories: [] as InventoryDTO[],
        selectedInventoryIds: [] as string[],
        inputWeights: [] as number[],
        weightCheckedIdx: [] as number[],
        invalid: false
    })
    // const inventoryColumn = [
    //     { key: 'weight' },
    //     { key: 'time' }
    // ]
    const disableSubmit = computed(() => {
        return order.value.orderItems.findIndex(item=>item.invalid) > -1
    })

    onBeforeMount(async() => {
        const orderRes = await orderService.findOrder(route.query.orderId, true, true)
        if (!AccessUtil.hasOutboundAccess() || orderRes.status !== 'PROCESSING') window.location.href = '/in/order'

        order.value.orderId = orderRes.orderId

        for (let orderItem of orderRes.orderItems) {
            order.value.orderItems.push(orderItem)
            if (orderItem.hasWeight === 'Yes') orderItem.invalid = true
            if (orderItem.hasWeight === 'Weighting') orderItem.invalid = orderItem.availableWeight < orderItem.weight
        }
    })

    const back = () => {
        Utils.setPreselectRow(route.query.displayId, route.query.currentPage)
        window.location.href = '/in/order'
    }

    const submitForm = () => {
        showLoading()
        const req = {
            orderId: order.value.orderId,
            inventoryList: order.value.orderItems.filter(o=>o.hasWeight==='Yes').flatMap(o => o.scannedInvIds),
            nonWeightOrderItems: order.value.orderItems.filter(o=>o.hasWeight==='No')
        } as OrderOutboundRequest

        orderService.outboundCompleteOrder(req, 'outbound').then((res: AxiosResponse) => {
            hideLoading()
            confirm({message: 'Order outbound success.', cancelText: '', noDismiss: true}).then(() => window.location.href = '/in/order')
        }, () => { Utils.openErrorModal(confirm, hideLoading) })
    }

    const selectWeights = async(orderItem: OrderItem) => {
        selectWeightModal.value.inventories = await inventoryService.getInventoriesByProductId(orderItem.productId, true)
        selectWeightModal.value.show = true
        selectWeightModal.value.orderItem = orderItem

        if (orderItem.selectedInventories?.length) {
            selectWeightModal.value.selectedInventoryIds = orderItem.selectedInventories.map(inv=>inv.inventoryId)
        } else {
            selectWeightModal.value.selectedInventoryIds = []
        }
    }

    const applySelectedWeight = () => {
        selectWeightModal.value.weightCheckedIdx = []
        selectWeightModal.value.selectedInventoryIds = []
        selectWeightModal.value.invalid = false

        for (let inventory of selectWeightModal.value.inventories) {
            for (let i = 0; i < selectWeightModal.value.inputWeights.length; i++) {
                if (inventory.weight != selectWeightModal.value.inputWeights[i]) continue
                if (selectWeightModal.value.weightCheckedIdx.includes(i)) continue

                selectWeightModal.value.weightCheckedIdx.push(i)
                selectWeightModal.value.selectedInventoryIds.push(inventory.inventoryId)
                break
            }
        }
        
        if (selectWeightModal.value.selectedInventoryIds.length !== selectWeightModal.value.orderItem.quantity) {
            selectWeightModal.value.invalid = true
            return
        }

        selectWeightModal.value.orderItem.scannedInvIds = JSON.parse(JSON.stringify(selectWeightModal.value.selectedInventoryIds))
        selectWeightModal.value.orderItem.selectedInventories = selectWeightModal.value.inventories.filter(inv=>selectWeightModal.value.selectedInventoryIds.indexOf(inv.inventoryId) > -1)
        selectWeightModal.value.orderItem.invalid = selectWeightModal.value.orderItem.scannedInvIds?.length !== selectWeightModal.value.orderItem.quantity
        selectWeightModal.value.show = false
    }
</script>
<style scoped>
.table-header-default th {
    text-transform: capitalize;
    font-size: 15px;
}
</style>