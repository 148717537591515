<template>
    <va-card>
        <va-card-content>
            <div class="row mb-3" style="justify-content: space-between;">
                <va-input v-model="filter" placeholder="filter..."/>
                <va-button v-if="!Utils.getViewOnlyFromToken()" color="primary" @click="addProduct">Add Product</va-button>
            </div>

            <va-data-table class="table-crud" :items="products" :columns="columns" :per-page="perPage" :current-page="currentPage"
                :filter="filter" :row-bind="getRowBind" @filtered="filtered = $event.items" hoverable>
                <template #cell(category1)="{ rowData }">
                    {{ rowData.category ? rowData.category.name : null }}
                </template>

                <template #cell(category2)="{ rowData }">
                    {{ rowData.subCategory ? rowData.subCategory.name : null }}
                </template>

                <template #cell(supplier)="{ rowData }">
                    {{ rowData.brand ? rowData.brand.name : null }}
                </template>

                <template #cell(actions)="{ rowData }" v-if="!Utils.getViewOnlyFromToken()">
                    <template v-if="rowData.disabled">
                        <va-button class="ml-3" preset="plain" icon="add_box" @click="enableProduct(rowData)" />
                    </template>
                    <template v-else>
                        <va-button preset="plain" icon="edit" v-if="AccessUtil.hasEditProductAccess()" @click="editProduct(rowData)" />
                        <va-button class="ml-3" preset="plain" icon="delete" @click="deleteProduct(rowData)" />
                    </template>
                </template>

                <template #cell(price)="{ rowData }">
                    {{ rowData.price ? "$" + rowData.price : null }}
                </template>

                <template #cell(wholesalePrice)="{ rowData }">
                    {{ rowData.wholesalePrice ? "$" + rowData.wholesalePrice : null }}
                </template>

                <template #bodyAppend v-if="products.length > perPage">
                    <tr>
                        <td colspan="12">
                        <div class="flex mt-5">
                            <va-pagination v-model="currentPage" :pages="pages" :visible-pages="10" class="justify-center sm:justify-start"/>
                        </div>
                        </td>
                    </tr>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useModal } from 'vuestic-ui'
    import { useRoute } from 'vue-router'

    import type Product from '@/types/Product'

    import productService from '@/services/product-service'
    import Utils from '@/utils/utils'
    import AccessUtil from '@/utils/access'

    const filter = ref()
    const filtered = ref([] as Product[])
    const route = useRoute()
    const currentPage = ref(1)
    const perPage = ref(100)
    const columns = [
        { key: "secondaryName", sortable: true },
        { key: "tertiaryName", sortable: true },
        { key: "supplier", sortable: true },
        { key: "warehouse", sortable: true },
        { key: "category1", sortable: true },
        { key: "category2", sortable: true },
        { key: "location" },
        { key: "price" },
        { key: "wholesalePrice" },
        { key: "hasWeight" },
        { key: "gtin" },
        { key: "code" },
        { key: "description" },
        { key: "reorderPoint" },
        { key: "actions", width: 80 },
    ]
    const { confirm } = useModal()
    const products = ref([] as Product[])

    onBeforeMount(async () => {
        products.value = await productService.findAllProductsIncludeDisabled()
        
        products.value.forEach(product => {
            product.warehouse = product.subWarehouse?.name
        })

        if (route.query.page) currentPage.value = route.query.page*1
        preNavigate()
    })

    const preNavigate = () => {
        const currentRow = Utils.getPreselectRow()
        if (currentRow) {
            currentPage.value = currentRow.currentPage*1
            Utils.goToElement(`product-${currentRow.id}`)
        }
    }

    const pages = computed(() => {
        if (filter.value) currentPage.value = 1
        return Math.ceil(filtered.value.length / perPage.value)
    })

    // Methods
    const deleteProduct = (product: Product) => {
        if (!product.productId) return

        confirm({message: 'Are you sure you want to delete "' + (product.tertiaryName ? product.tertiaryName : product.secondaryName) + '"?'})
            .then(async(ok) => {
            if (ok) {
                try {
                    await productService.deleteProduct(product.productId)
                    confirm({message: 'Product deleted.', cancelText: ''}).then(() => {
                        Utils.setPreselectRow(product.productId, route.query.page)
                        window.location.reload()
                    })
                } catch(err: any) {
                    confirm({ message: err, cancelText: '' }).then(() => {
                        Utils.setPreselectRow(product.productId, route.query.page)
                        window.location.reload()
                    })
                }
            }
        })
    }

    const enableProduct = async(product: Product) => {
        await productService.enableProduct(product.productId)
        confirm({message: 'Product enabled.', cancelText: ''}).then(() => {
            Utils.setPreselectRow(product.productId, route.query.page)
            window.location.reload()
        })
    }

    const editProduct = (product: Product) => {
        window.location.href = `/in/settings/product/edit?id=${encodeURIComponent(product.productId)}&page=${currentPage.value}`
    }

    const addProduct = () => {
        window.location.href = '/in/settings/product/new'
    }

    const getRowBind = (row: any) => {
        return {
          id: 'product-' + row.productId,
          style: row.disabled ? {'text-decoration': 'line-through', color: 'grey', 'opacity': .8} : null
        }
    }
</script>