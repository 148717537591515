<template>
    <div class="va-spacing-y-3" style="padding: 20px 40px;">
        <div class="row" style="justify-content: end;">
            <va-button @click="addNewTemplate">Add New Template</va-button>
        </div>
        <div class="row va-spacing-x-3 va-spacing-y-3" style="align-items: flex-start;">
            <va-card v-for="orderTemplate of orderTemplates">
                <va-card-title>
                    <div class="row" style="justify-content: space-between;text-transform: none;">
                        <p style="font-size: 1rem;font-weight: bold;margin-right: 30px;">{{ orderTemplate.name }}</p>
                        <div>
                            <va-icon name="edit" color="primary" @click="editOrderTemplate(orderTemplate.id)"/>
                            <va-icon class="ml-2" name="delete" color="primary" @click="deleteOrderTemplate(orderTemplate.id)"/>
                        </div>
                    </div>
                </va-card-title>
                <va-card-content>
                    <table class="va-table">
                        <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="orderItem in orderTemplate.orderItems">
                                <td>{{ orderItem.productName }}</td>
                                <td>{{ orderItem.quantity }}</td>
                            </tr>
                        </tbody>
                    </table>
                </va-card-content>
            </va-card>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { OrderTemplateDTO } from '@/models/warems.models'

    import orderTemplateService from '@/services/order-template-service'

    const { confirm } = useModal()
    const orderTemplates = ref([] as OrderTemplateDTO[])

    onBeforeMount(async() => {
        orderTemplates.value = await orderTemplateService.getOrderTemplates()
    })

    const addNewTemplate = () => {
        window.location.href = '/in/settings/order-templates/new'
    }

    const editOrderTemplate = (id: string) => {
        window.location.href = `/in/settings/order-templates/edit?id=${encodeURIComponent(id)}`
    }

    const deleteOrderTemplate = async(id: string) => {
        confirm({message: 'Are you sure you want to remove this order template?'}).then(async(ok) => {
            if (ok) {
                await orderTemplateService.deleteOrderTemplate(id)
                window.location.reload()
            }
        })
    }
</script>