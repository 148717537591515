import axios from 'axios'

import type { UserDTO } from '@/models/warems.models'
import type Selection from '@/types/Selection'

import ApiUtil from '@/utils/api-util'

const userService = {
    async getUser(id:string):Promise<UserDTO> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/user?id=${encodeURIComponent(id)}`)).data.obj
    },
    async getUsersByRole(role:string):Promise<UserDTO[]> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/user?role=${role}`)).data.list
    },
    async getUsers() {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/user`)).data.list
    },
    async addUser(user: UserDTO) {
        return (await axios.post(`${import.meta.env.VITE_APP_API_BASE}/user`, user)).data.message
    },
    async updateUser(user: UserDTO) {
        return (await axios.put(`${import.meta.env.VITE_APP_API_BASE}/user`, user)).data.message
    },
    async getAllStoreSelection():Promise<Selection[]> {
        const storeSelection: Selection[] = []
        const stores = await this.getUsersByRole('STORE')

        stores.forEach((store: UserDTO) => {
            storeSelection.push({
                text: store.name,
                value: store.id,
                optional: { wholesalePrice: store.wholesalePrice }
            } as Selection)
        })

        return storeSelection
    },
    async resendActivationEmail(id: string) {
        return await ApiUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/user/resend-activation-email?id=${encodeURIComponent(id)}`)
    }
};

export default userService;