<template>
    <va-card class="row" style="margin: 0;justify-content: center;">
        <va-card-title style="display: flex; justify-content: space-between;">
            <p class="va-h5">Category</p>
            <va-button @click="categoryModal.show=true" v-if="!Utils.getViewOnlyFromToken()">Add Category 1</va-button>
        </va-card-title>
        <va-card-content>
            <va-data-table class="customize-data-table" :items="categories" :columns="columns" sticky-header hoverable>
                <template #cell(warehouse)="{ rowData }">
                    {{ rowData.subWarehouse.name }}
                </template>
                <template #cell(category1)="{ rowData }">
                    {{ rowData.name }}
                </template>

                <template #cell(actions)="{ rowData }" v-if="!Utils.getViewOnlyFromToken()">
                    <div class="row">
                        <div class="row va-spacing-x-3">
                            <va-icon name="edit" @click="openEditCategoryModal(rowData)" color="primary"/>
                            <va-icon name="delete" @click="deleteCategoryHandler(rowData)" color="primary"/>
                        </div>
                        <va-button @click="addCategory2Handler(rowData)" v-if="!Utils.getViewOnlyFromToken()" size="small" >
                            Add Category 2
                        </va-button>
                    </div>
                </template>

                <template #cell(category2)="{ row, rowData, isExpanded }">
                    <va-button v-if="rowData.subCategories" @click="row.toggleRowDetails()" :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'" 
                        preset="secondary" class="w-full">
                        {{ isExpanded ? 'Hide': 'Details' }}
                    </va-button>
                </template>
                <template #expandableRow="{ rowData }">
                    <va-data-table class="table-crud" :items="rowData.subCategories" :columns="columns2" hoverable>
                        <template #cell(actions)="{ rowData }" v-if="!Utils.getViewOnlyFromToken()">
                            <div class="va-spacing-x-2">
                                <va-icon name="edit" @click="openEditSubCategoryModal(rowData)" color="primary"/>
                                <va-icon name="delete" @click="deleteSubCategory(rowData)" color="primary"/>
                            </div>
                        </template>
                        <template #cell(category2)="{ rowData }">
                            {{ rowData.name }}
                        </template>
                    </va-data-table>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>

    <va-modal v-model="categoryModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input v-model="categoryModal.category.name" label="Name"/>
            </div>
            <div class="row">
                <va-select v-model="categoryModal.category.subWarehouse" label="Warehouse" :options="subWarehouseSelection"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="categoryModal.show=false;categoryModal.category={}" preset="secondary">Close</va-button>
                <va-button v-if="!categoryModal.isEdit" @click="addCategory(categoryModal.category)" :disabled="disableAddBtn">Add</va-button>
                <va-button v-else @click="updateCategory(categoryModal.category)">Edit</va-button>
            </div>
        </div>
    </va-modal>

    <va-modal v-model="subCategoryModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input  style="min-width: 300px;" v-model="subCategoryModal.subCategory.name" label="Name"/>
            </div>
            <div class="row">
                <va-select v-model="subCategoryModal.subCategory.category" label="Category" :options="categorySelection" readonly/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="subCategoryModal.show=false;subCategoryModal.subCategory={}" preset="secondary">Close</va-button>
                <va-button v-if="!subCategoryModal.isEdit" @click="addSubCategory(subCategoryModal.subCategory)" :disabled="disableAddSubCategoryBtn">Add</va-button>
                <va-button v-else @click="updateSubCategory(subCategoryModal.subCategory)">Edit</va-button>
            </div>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { CategoryDTO, SubCategoryDTO } from '@/models/warems.models'
    import type Selection from '@/types/Selection'

    import categoryService from '@/services/category-service'
    import warehouseService from '@/services/warehouse-service'
    import Utils from '@/utils/utils'

    const { confirm } = useModal()
    const categories = ref([] as CategoryDTO[])
    const subWarehouseSelection = ref([] as Selection[])
    const categorySelection = ref([] as Selection[])
    const columns = [
        { key: 'category1' },
        { key: 'warehouse' },
        { key: 'category2' },
        { key: 'actions' }
    ]
    const columns2 = [
        { key: 'category2' },
        { key: 'actions' }
    ]
    const categoryModal = ref({
        show: false,
        isEdit: false,
        category: {} as CategoryDTO
    })
    const subCategoryModal = ref({
        show: false,
        isEdit: false,
        subCategory: {} as SubCategoryDTO
    })

    onBeforeMount(async() => {
        categories.value = await categoryService.getAllCategories()
        subWarehouseSelection.value = await warehouseService.findAllSubWarehouseSelection()
        categorySelection.value = await categoryService.getAllCategorySelection(categories.value)
    })

    const disableAddBtn = computed(() => {
        return !categoryModal.value.category || !categoryModal.value.category.name || !categoryModal.value.category.subWarehouse
    })

    const disableAddSubCategoryBtn = computed(() => {
        return !subCategoryModal.value.subCategory || !subCategoryModal.value.subCategory.name || !subCategoryModal.value.subCategory.category
    })

    const addCategory = async(category: CategoryDTO) => {
        if (await categoryService.addCategory({...category, subWarehouse: { id: category.subWarehouse.value }})) {
            confirm({message: 'Category 1 Added!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const addSubCategory = async(subCategory: SubCategoryDTO) => {
        if (await categoryService.addSubCategory({...subCategory, category: { id: subCategory.category.value }})) {
            confirm({message: 'Category 2 Added!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const updateCategory = async(category: CategoryDTO) => {
        if (await categoryService.updateCategory({...category, subWarehouse: { id: category.subWarehouse.value }})) {
            confirm({message: 'Category 1 Updated!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const updateSubCategory = async(subCategory: SubCategoryDTO) => {
        if (await categoryService.updateSubCategory({...subCategory, category: { id: subCategory.category.value }})) {
            confirm({message: 'Category 2 Updated!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const openEditCategoryModal = (category: CategoryDTO) => {
        const categoryEditObj = JSON.parse(JSON.stringify(category))

        for (let selection of subWarehouseSelection.value) {
            if (selection.value === categoryEditObj.subWarehouse?.id) {
                categoryEditObj.subWarehouse = selection
                break
            }
        }

        categoryModal.value.category = categoryEditObj
        categoryModal.value.isEdit = true
        categoryModal.value.show = true
    }

    const openEditSubCategoryModal = (subCategory: SubCategoryDTO) => {
        const subCategoryEditObj = JSON.parse(JSON.stringify(subCategory))

        for (let selection of categorySelection.value) {
            if (selection.value === subCategoryEditObj.category?.id) {
                subCategoryEditObj.category = selection
                break
            }
        }

        subCategoryModal.value.subCategory = subCategoryEditObj
        subCategoryModal.value.isEdit = true
        subCategoryModal.value.show = true
    }

    const addCategory2Handler = (rowData: any) => {
        for (let selection of categorySelection.value) {
            if (selection.value === rowData.id) {
                subCategoryModal.value.subCategory.category = selection
                break
            }
        }

        subCategoryModal.value.show = true
    }

    const deleteCategoryHandler = async(rowData: any) => {
        confirm({message: 'Do you want to remove this Category 1?'}).then(async(ok) => {
            if (ok) {
                try {
                    await categoryService.deleteCategory(rowData.id)
                    confirm({message: 'Category 1 removed.', cancelText: ''}).then(()=> window.location.reload())
                } catch {
                    confirm({message: 'This category can not be removed, it probablly because there is product associated with it.', cancelText: ''})
                }
            }
        })
    }

    const deleteSubCategory = async(rowData: any) => {
        confirm({message: 'Do you want to remove this Category 2?'}).then(async(ok) => {
            if (ok) {
                try {
                    await categoryService.deleteSubCategory(rowData.id)
                    confirm({message: 'Category 2 removed.', cancelText: ''}).then(()=> window.location.reload())
                } catch {
                    confirm({message: 'This category 2 can not be removed, it probablly because there is product associated with it.', cancelText: ''})
                }
            }
        })
    }
</script>
<style>
    .customize-data-table {
        --va-data-table-thead-font-size: .8rem;
        --va-data-table-height: 800px;
        
        .va-data-table__table-th-wrapper span{
            font-weight: bold;
        }
    }
</style>