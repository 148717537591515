<template>
    <div class="va-spacing-y-3">
        <div class="row va-spacing-x-3" v-for="(orderItem, index) in orderItems" :key="index">
            <va-select label="Product" v-model="orderItem.product" :options="filteredAllProducts" searchable highlight-matched-text 
                @update:modelValue="productSelected(orderItem)" :readonly="props.controller?.product?.readonly"/>
            
            <va-input label="Warehouse" v-if="props.controller?.warehouse?.show && orderItem.product" v-model="orderItem.product.optional.subwarehouse" readonly/>

            <va-input v-if="orderItem.product && orderItem.product.optional && orderItem.product.optional.hasWeight === 'Weighting'" v-model="orderItem.weight" 
                type="number" step="0.01" label="Weight" style="max-width: 80px;" :readonly="props.controller?.quantity?.readonly"/>
            <va-input v-else label="Quantity" v-model="orderItem.quantity" type="number" min="1" style="max-width: 80px;" 
                @update:modelValue="productQtyCheck(orderItem)" :readonly="props.controller?.quantity?.readonly"/>
            
            <va-input label="Rate" v-if="props.controller?.rate?.show" v-model="orderItem.rate" type="number" step="0.01" style="max-width: 110px;">
                <template #prependInner>
                    <va-icon name="attach_money" />
                </template>
            </va-input>

            <va-input v-if="orderItem.notes && props.controller?.notes?.show" label="Notes" v-model="orderItem.notes" readonly/>

            <div class="flex items-center" style="margin: 16px 0 0 15px" v-if="props.controller?.removeBtn?.show">
                <va-button icon="clear" color="danger" class="mr-3 mb-2" @click="removeItem(index)"/>
            </div>
        </div>
        
        <div class="flex md12 sm12 xs12" v-if="props.controller?.addBtn?.show">
            <va-button icon="add" class="mr-3 mt-3" @click="addItem">Add More Product</va-button>
        </div>

        <div class="flex md12 sm12 xs12 va-spacing-x-4" style="text-align: center">
            <va-button class="mt-3" @click="goToPrevious" v-if="props.controller?.goBackBtn?.show">Go Back</va-button>
            <va-button @click="submitForm" class="mt-3" :disabled="disableBtn">{{ props.controller?.submitBtn?.text }}</va-button>
            <va-button class="mt-3" preset="outline" v-if="props.controller?.showReset" @click="reset">Reset</va-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed, watch, onMounted } from 'vue'

    import type { PropType } from 'vue'
    import type OrderItem from '@/types/OrderItem'
    import type OrderDetails from '@/types/OrderDetails'
    import type Selection from '@/types/Selection'
    import type OrderController from '@/types/OrderController'

    const emits = defineEmits(['submit', 'back'])
    const orderItems = ref([] as OrderItem[])
    const allProducts = ref([] as Selection[])

    const props = defineProps({
        controller: Object as PropType<OrderController>,
    })

    onMounted(() => {
        watch(orderItems, (newVal: OrderItem[]) => {
            if (newVal && newVal.length && newVal[0].product) localStorage.setItem('cached_order_product_block', JSON.stringify(newVal))
        }, { deep: true })
    })

    const filteredAllProducts = computed(() => {
        if (!orderItems || orderItems.value.length === 0) return allProducts

        let ids = orderItems.value.map(orderItem => orderItem.product?.value)
        return allProducts.value.filter(p => !ids.includes(p.value));
    })

    const productSelected = (orderItem: OrderItem) => orderItem.quantity = 1

    const productQtyCheck = (orderItem: OrderItem) => {
        // TODO: process, booked and avilable is 0
        if (props.controller?.exceedAvailableQuantityAllow) return
        if (orderItem.quantity > orderItem.product.optional.availableQty) {
            orderItem.quantity = orderItem.product.optional.availableQty
        }
    }

    const addItem = () => orderItems.value.push({} as OrderItem)

    const removeItem = (idx: number) => {
        if (idx === 0 && orderItems.value.length === 1) {
            orderItems.value = [{} as OrderItem]
        } else {
            orderItems.value.splice(idx, 1)
        }
    }
    
    const goToPrevious = () => emits('back')

    const submitForm = () => {
        const orderItemList: OrderItem[] = []
        
        orderItems.value.forEach(orderItem => {
            let isWeighting = orderItem.product.optional.hasWeight === 'Weighting'
            orderItemList.push({
                name: orderItem.product.text,
                productId: orderItem.product.value,
                quantity: isWeighting ? null : orderItem.quantity,
                weight: isWeighting ? orderItem.weight : null,
                rate: props.controller?.rate?.show ? orderItem.rate * 1 : null,
                isWeighting: isWeighting
            })
        })

        emits('submit', orderItemList)
    }

    const intialOrderItems = (allProducts_: Selection[], retainItems: boolean) => {
        if (props.controller?.loadCache) {
            let cachedProducts = localStorage.getItem('cached_order_product_block')
            if (cachedProducts) {
                orderItems.value = [...JSON.parse(cachedProducts)]
            } else {
                orderItems.value = [{} as OrderItem]
            }
        } else {
            if (!retainItems) orderItems.value = [{} as OrderItem]
        }
        
        allProducts.value = [...allProducts_]
    }

    const preloadOrderItems = (orderDetails: OrderDetails) => {
        if (!orderDetails || !orderDetails.orderItems || !orderDetails.orderItems.length) return

        orderItems.value = []
        for (let orderItem of orderDetails.orderItems) {
            for (let product of allProducts.value) {
                if (product.value !== orderItem.productId) continue
                let rate = product.optional.price

                if (orderDetails.wholesalePrice && product.optional.wholesalePrice) {
                    rate = product.optional.wholesalePrice
                }

                if (orderItem.availableQty != product.optional.availableQty) {
                    product.optional.availableQty = orderItem.availableQty
                }

                orderItems.value.push({ 
                    product: product, 
                    quantity: orderItem.quantity, 
                    rate, 
                    notes: orderItem.notes, 
                    subWarehouseName: orderItem.subWarehouseName,
                    weight: orderItem.weight
                } as OrderItem)
                break
            }
        }
    }
    
    const disableBtn = computed(() => {
        if (!orderItems.value || !orderItems.value.length) return true

        for (let orderItem of orderItems.value) {
            if (!orderItem.product || (!orderItem.quantity && !orderItem.weight)) return true
        }

        return false
    })

    const reset = () => {
        localStorage.removeItem('cached_order_product_block')
        orderItems.value = [{} as OrderItem]
    }

    defineExpose({ intialOrderItems, preloadOrderItems })
</script>