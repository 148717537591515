<template>
    <va-data-table :items="deliveryReceipts" :columns="columns" sticky-header hoverable>
        <template #cell(id)="{ rowData }">
            {{ rowData.displayId }}
        </template>
        <template #cell(time)="{ value }">
            {{ new Date(value*1).toLocaleString() }}
        </template>
        <template #cell(estimateArrivalTime)="{ value }">
            {{ new Date(value*1).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }}
        </template>
        <template #cell(createdOn)="{ rowData }">
            {{ new Date(rowData.time*1).toLocaleString() }}
        </template>
        <template #cell(boxes)="{ row, isExpanded }">
            <va-button
                @click="row.toggleRowDetails()"
                :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
                preset="secondary"
                class="w-full"
            >
                {{ isExpanded ? 'Hide': 'Details' }}
            </va-button>
        </template>
        <template #expandableRow="{ rowData }">
            <div class="va-table-responsive" style="padding: 10px">
                <table class="va-table va-table--hoverable">
                    <thead>
                        <tr>
                            <th>Box Name</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, idx) in rowData.deliveryReceiptItems" :key="idx">
                            <td>{{ item.nameOnBox }}</td>
                            <td>{{ item.productName }}</td>
                            <td>{{ item.quantity }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template #cell(actions)="{ rowData }">
            <va-button v-if="rowData.status === 'NEW' && AccessUtil.hasInboundAccess()" @click="handleInboundRequest(rowData.deliveryReceiptId)">
                Inbound
            </va-button>
        </template>
    </va-data-table>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import type DeliveryReceipt from '@/types/DeliveryReceipt'

    import AccessUtil from '@/utils/access'
    import deliveryReceiptService from '@/services/delivery-receipt-service'

    const deliveryReceipts = ref([] as DeliveryReceipt[])
    const columns = [
      { key: "id" },
      { key: "status" },
      { key: "estimateArrivalTime" },
      { key: "createdBy" },
      { key: "createdOn" },
      { key: "boxes", width: 80 },
      { key: "actions", width: 80 },
    ];

    onBeforeMount(async() => {
        const res = await deliveryReceiptService.getAllDeliveryReceipt()
        deliveryReceipts.value.push(...res.data.list)
    })

    // Methods

    const handleInboundRequest = (deliveryReceiptId: string) => {
        window.location.href = ('/in/inbound?deliveryReceiptId=' + encodeURIComponent(deliveryReceiptId))
    }
</script>

<style>
    .va-data-table__table-tr--expanded td {
        background: var(--va-background-border);
    }

    .va-data-table__table-expanded-content td {
        background-color: var(--va-background-element);
    }
</style>