<template>
    <va-card>
        <va-card-content>
            <div class="va-spacing-x-3 mb-5" v-if="!success">
                <va-select v-model="selectedSubWarehouse" :options="subWarehouseSelection" label="Warehouse" 
                    @update:modelValue="subWarehouseSelected"/>
                <va-select v-model="selectedSupplier" :options="supplierSelection" label="Supplier"
                    @update:modelValue="supplierSelected"/>
                <va-date-input label="order date" v-model="date"/>
            </div>

            <template v-if="!loading && allProductSelections.length && selectedSupplier">
                <AddInventoryForm @submit-res="handleFormSubmitRes" v-if="!success" :controller="controller" :inventories="productsFromCache"
                    :allProductSelections="allProductSelections" @on-changed="handleOnChanged" @reset="reset" ref="addInventoryForm"/>
                <AddInventorySuccessView v-else :resBody="addInvRes"/>
            </template>
        </va-card-content>
    </va-card>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import type OrderController from '@/types/OrderController'
    import type Inventory from '@/types/Inventory'
    import type Selection from '@/types/Selection'

    import AddInventoryForm from '@/views/inventory/AddInventoryForm.vue'
    import AddInventorySuccessView from '@/views/inventory/AddInventorySuccessView.vue'
    import inventoryService from '@/services/inventory-service'
    import warehouseService from '@/services/warehouse-service'
    import productService from '@/services/product-service'
    import brandService from '@/services/brand-service'
    import Utils from '@/utils/utils'

    const addInventoryForm = ref()
    const addInvRes = ref([])
    const success = ref(false)
    const controller = ref({
        confirmWindow: {
            show: true,
            text: 'Are you sure you want to add those inventories?'
        },
        showReset: true
    } as OrderController)
    const productsFromCache = ref([] as Inventory[])
    const selectedSubWarehouse = ref()
    const selectedSupplier = ref()
    const supplierSelection = ref([] as Selection[])
    const subWarehouseSelection = ref([] as Selection[])
    const allProductSelections = ref([] as Selection[])
    const allProductSelectionsBK = ref([] as Selection[])
    const loading = ref(true)
    const date = ref(new Date())

    onBeforeMount(async() => {
        subWarehouseSelection.value = await warehouseService.findAllSubWarehouseSelection()
        supplierSelection.value = await brandService.getAllBrandSelection()
        selectedSubWarehouse.value = subWarehouseSelection.value[0]
        await subWarehouseSelected()

        const cachedObj = localStorage.getItem('cached_add_received')
        if (cachedObj) {
            productsFromCache.value = JSON.parse(cachedObj).products
            date.value = new Date(JSON.parse(cachedObj).date)
            selectedSubWarehouse.value = JSON.parse(cachedObj).selectedSubWarehouse
            selectedSupplier.value = JSON.parse(cachedObj).selectedSupplier
        }
        loading.value = false
    })

    const handleFormSubmitRes = async(data: any) => {
        data.date = date.value.getTime()
        data.needRecord = true
        data.supplierId = selectedSupplier.value.value
        data.subWarehouseId = selectedSubWarehouse.value.value
        const res = await inventoryService.addInventory(data)
        reset()
        success.value = true
    }

    const handleOnChanged = (data: any) => {
        if (!data || !data.length || (data.length === 1 && !data[0].product)) return
        localStorage.setItem('cached_add_received', JSON.stringify({ 
            products: data,
            selectedSubWarehouse: selectedSubWarehouse.value,
            selectedSupplier: selectedSupplier.value,
            date: date.value
        }))
    }

    const reset = () => {
        localStorage.removeItem('cached_add_received')
    }

    const subWarehouseSelected = async() => {
        let products = await productService.findAllProducts(selectedSubWarehouse.value.value)
        allProductSelections.value = Utils.getProductsForSelection(products, false, true, false)
        allProductSelectionsBK.value = JSON.parse(JSON.stringify(allProductSelections.value))
        if (addInventoryForm.value) {
            addInventoryForm.value.loadAllProducts(allProductSelections.value)
            addInventoryForm.value.resetInventory()
        }
    }

    const supplierSelected = async() => {
        allProductSelections.value = [...allProductSelectionsBK.value.filter(p => p.optional?.brand?.id === selectedSupplier.value.value)]
        if (addInventoryForm.value) {
            addInventoryForm.value.loadAllProducts(allProductSelections.value)
            addInventoryForm.value.resetInventory()
        }
    }
</script>