<template>
    <div class="row mt-4 ml-4" style="justify-content: center;">
        <div class="va-spacing-y-4">
            <div class="row" style="justify-content: space-between;max-width: 550px;">
                <va-chip color="success">Billing Address</va-chip>
                <va-button preset="secondary" @click="handleEditAddress(billingAddress, 'BILL')">Edit</va-button>
            </div>
            <AddressComponent :address="billingAddress" name="Billing Name" readonly/>
        </div>
    </div>
    
    <div class="row mt-4 ml-4" style="justify-content: center;">
        <div class="va-spacing-y-4">
            <div class="row" style="justify-content: space-between;max-width: 550px;">
                <va-chip color="success">Shipping Address</va-chip>
                <va-button preset="secondary" @click="handleEditAddress(shippingAddress, 'SHIP')">Edit</va-button>
            </div>
            <AddressComponent :address="shippingAddress" name="Billing Name" readonly/>
        </div>
    </div>

    <va-modal :model-value="editAddressModal.show" size="small" @ok="submitEditAddress" @cancel="resetEditAddress" no-outside-dismiss>
        <AddressComponent :address="editAddressModal.address" :name="editAddressModal.address.type === 'BILL' ? 'Billing Name' : 'Shipping Name'"/>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import type {ClientAddressDTO} from '@/models/warems.models'

    import clientInfoService from '@/services/client-info-service'
    import AddressComponent from '@/components/AddressComponent.vue'

    const billingAddress = ref({} as ClientAddressDTO)
    const shippingAddress = ref({} as ClientAddressDTO)
    const editAddressModal = ref({
        show: false,
        address: {} as ClientAddressDTO
    })

    onBeforeMount(async() => {
        const clientAddresses: ClientAddressDTO[] = await clientInfoService.getClientAddresses()

        clientAddresses.forEach((clientAddress: ClientAddressDTO) => {
            if (clientAddress.type === 'BILL') {
                billingAddress.value = clientAddress
            } else if (clientAddress.type === 'SHIP') {
                shippingAddress.value = clientAddress
            }
        })
    })

    // Methods
    const handleEditAddress = (address: ClientAddressDTO, type: string) => {
        editAddressModal.value.address = JSON.parse(JSON.stringify(address))
        editAddressModal.value.address.type = type
        editAddressModal.value.show = true
    }

    const submitEditAddress = () => {
        clientInfoService.updateClientAddress(editAddressModal.value.address)
        window.location.href = '/in/settings/address'
    }

    const resetEditAddress = () => {
        editAddressModal.value.address = {} as ClientAddressDTO
        editAddressModal.value.show = false
    }
</script>