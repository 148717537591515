<template>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;padding: 40px 0 20px 0;">
        <va-card>
            <va-card-content>
                <h4 class="va-h4 mb-4">Sign in</h4>
                <LoginForm @ok="login"/>
            </va-card-content>
        </va-card>
    </div>
</template>
<script setup lang="ts">
    import LoginForm from '@/components/LoginForm.vue'
    import Utils from '@/utils/utils'

    const login = () => {
        if ("STORE" === Utils.getRoleFromToken()) {
            window.location.href = '/in/order'
        } else {
            window.location.href = '/in/dashboard'
        }
    }
</script>