<template>
    <va-layout>
        <template #top>
            <va-navbar color="primary" class="py-2">
                <template #left v-if="showLeftPanel">
                    <VaButton :icon="showSidebar ? 'menu_open' : 'menu'" @click="showSidebar = !showSidebar" />
                </template>
                <template #center>
                    <va-navbar-item class="font-bold text-lg">
                        {{ displayTitle }}
                    </va-navbar-item>
                </template>
                <template #right>
                    <TimeCounter :target-date="Utils.getTokenExpDate()" />
                </template>
            </va-navbar>
        </template>

        <template #left v-if="showLeftPanel">
            <va-sidebar v-model="showSidebar" class="py-4" style="height: 90vh;">
                <div style="text-align: center;" class="mb-3">
                    <h1>Welcome, {{ name }}</h1>
                </div>

                <internal-sidebar :items="sidebarItems" @click-sidebar-item="handleSidebarItem"/>

                <va-sidebar-item @click="logout">
                    <va-sidebar-item-content>
                        <va-icon name="logout" />
                        <va-sidebar-item-title>Logout</va-sidebar-item-title>
                    </va-sidebar-item-content>
                </va-sidebar-item>
            </va-sidebar>
        </template>

        <template #content>
            <router-view />
        </template>

        <template #bottom>
            <div style="height: 5vh;">
                <va-navbar style="text-align: center;padding-bottom: 0;">
                    <template #center>
                        <span style="font-size: 12px;opacity: .8;">
                            © {{ new Date().getFullYear() }} Ternary Innovation, LLC. v{{ version }}
                        </span>
                    </template>
                </va-navbar>
            </div>
        </template>
    </va-layout>
</template>
<script setup lang="ts">
    import { ref, computed, onMounted } from 'vue'
    import { useRoute } from 'vue-router'

    import type { INavigationRoute } from '@/router/internal-routes'

    import Utils from '@/utils/utils'
    import TimeCounter from '@/components/TimeCounter.vue'
    import InternalSidebar from '@/components/sidebar/InternalSidebar.vue'
    import internalRoutes from '@/router/internal-routes'
    import { useRouter } from 'vue-router'

    const version = import.meta.env.VITE_APP_VERSION
    const router = useRouter()
    const showSidebar = ref(false)
    const displayTitle = ref('')
    const sidebarItems = ref(internalRoutes as INavigationRoute[])

    onMounted(() => {
        const currentRoute = useRoute()
        setPageSetting(currentRoute.meta.displayName)
    })

    // Methods
    const setPageSetting = (title: string) => {
        displayTitle.value = title
    }

    const handleSidebarItem = (route: INavigationRoute) => {
        setPageSetting(route.meta.displayName)
        router.push({ name: route.name })
    }

    const logout = () => {
        localStorage.clear()
        window.location.href = '/login'
    }

    const showLeftPanel = computed(() => {
        return localStorage.getItem('jwttoken')
    });

    const name = computed(() => {
        return Utils.getSubFromToken()
    })
</script>