<template>
    <InvoicePDF :invoice="props.invoice" v-if="!showEditInvoice"/>
    <EditInvoiceContent v-if="showEditInvoice" :invoice="props.invoice" :rowIdentifier="props.rowIdentifier" @close="showEditInvoice=false"/>

    <div class="va-spacing-x-3" style="text-align: center;margin: 30px 0 20px 0;">
        <va-button v-if="props.enableSwitch && !showEditInvoice && !props.isFirst" @click="emits('before')"><va-icon name="navigate_before"/></va-button>

        <va-button @click="showEditInvoice = true" v-if="!showEditInvoice && AccessUtil.hasInvoiceGenerationAccess()">Edit</va-button>

        <va-button @click="previewInvoice" v-if="!showEditInvoice">Preview</va-button>
        <va-button @click="markInvoicePaid" v-if="AccessUtil.hasInvoiceGenerationAccess() && !paidFull && !showEditInvoice" color="success">Mark as PAID</va-button>

        <va-button v-if="props.enableSwitch && !showEditInvoice && !props.isLast" @click="emits('next')"><va-icon name="navigate_next"/></va-button>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { PropType } from 'vue'
    import type RowIdentifier from '@/types/RowIdentifier'
    import type { InvoiceDTO } from '@/models/warems.models'

    import AccessUtil from '@/utils/access'
    import InvoicePDF from '@/components/report/InvoicePDF.vue'
    import EditInvoiceContent from '@/components/modal/EditInvoiceContent.vue'
    import invoiceService from '@/services/invoice-service'
    import { useLoadingStore } from '@/stores/loading-store'
    
    const { confirm } = useModal()
    const props = defineProps({
        invoice: Object as PropType<InvoiceDTO>,
        isFirst: Boolean,
        isLast: Boolean,
        enableSwitch: Boolean,
        rowIdentifier: Object as PropType<RowIdentifier>
    })
    const emits = defineEmits(['close', 'before', 'next', 'edit'])
    const paidFull = computed(() => {
      return props.invoice?.formattedTotal === props.invoice?.formattedPaid
    })
    const { showLoading, hideLoading } = useLoadingStore()
    const showEditInvoice = ref(false)

    const markInvoicePaid = async() => {
        confirm({message: 'Are you sure the customer paid for this order? You will not able to edit after confirm', noDismiss: true})
            .then(async(ok) => {
                if (ok) {
                    showLoading()
                    await invoiceService.invoicePaid({invoiceIds: [props.invoice?.invoiceIdQuery]})
                    window.location.reload()
                }
            })
    }

    const previewInvoice = async() => {
        showLoading()
        const response = await invoiceService.downloadInvoices([props.invoice?.invoiceIdQuery])
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
        hideLoading()
    }
</script>