import type { ReceivedDTO } from '@/models/warems.models'

import APIUtil from '@/utils/api-util'

const receivedService = {
    async getAllReceived(): Promise<ReceivedDTO[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/received`)
        return res?.data.list
    },
}

export default receivedService;