import type Product from '@/types/Product'
import type { SaveProductRequest, UpdateProductRequest } from '@/models/warems.models'

import APIUtil from '@/utils/api-util'

const productService = {
    async findAllProducts(subWarehouseId?: string):Promise<Product[]> {
        if (subWarehouseId) {
            const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/product?subWarehouseId=${encodeURIComponent(subWarehouseId)}`)
            return res?.data.list
        } else {
            const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/product`)
            return res?.data.list
        }
    },
    async findAllProductsIncludeDisabled():Promise<Product[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/product?includeDisabled=true`)
        return res?.data.list
    },
    async getProductById(productId: string):Promise<Product> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/product?id=${encodeURIComponent(productId)}`)
        return res?.data.obj
    },
    async getAltProductById(productId: string):Promise<Product[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/product/alt-ids?id=${encodeURIComponent(productId)}`)
        return res?.data.list
    },
    async getAltProductIdsById(productId: string):Promise<string[]> {
        const products = await this.getAltProductById(productId)
        if (!products || !products.length) return []
        return products.map(p=>p.productId)
    },
    async updateProduct(updateRequest: UpdateProductRequest) {
        return await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/product`, updateRequest)
    },
    async updateProductWeight(updateRequest: UpdateProductRequest) {
        return await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/product/weight`, updateRequest)
    },
    async createProduct(saveProductRequest: SaveProductRequest) {
        return await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/product`, saveProductRequest)
    },
    async deleteProduct(productId: string) {
        return await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/product?id=${encodeURIComponent(productId)}`)
    },
    async enableProduct(productId: string) {
        await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/product/enable`, { product: { productId } })
    }
};

export default productService;