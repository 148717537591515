<template>
    <form @submit.prevent="submitForm">
        <div class="row va-spacing-x-3 va-spacing-y-3" v-for="(item, index) in props.inboundItems" :key="index">
            <va-input v-model="item.receiptItem.nameOnBox" label="Name On the Box" />
            <va-select v-model="item.receiptItem.product" label="Product" readonly/>
            <va-input v-model="item.receiptItem.quantity" type="number" min="1" label="Quantity" style="max-width: 80px;margin-bottom: auto;" readonly/>
        </div>
        
        <div class="flex md12 sm12 xs12" style="text-align: center">
            <va-button type="submit" class="mt-3" :disabled="disableNextBtn">Next</va-button>
        </div>
    </form>
</template>
<script setup lang="ts">
    import { computed, type PropType } from 'vue'
    import type InboundItem from '@/types/InboundItem'

    const emit = defineEmits()
    
    const props = defineProps({
        inboundItems: Array as PropType<InboundItem[]>
    })

    // Methods
    const disableNextBtn = computed(() => {
        if (!props.inboundItems) return true

        for (let inboundItem of props.inboundItems) {
            if (!inboundItem.receiptItem) return true
            if (!inboundItem.receiptItem.nameOnBox) return true
        }

        return false
    })

    const submitForm = async () => {
        emit('next')
    };
</script>