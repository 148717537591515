<template>
    <div style="margin: 10px 20px 0 20px;display: flex;justify-content: end;">
        <va-button @click="addBrandModal.show=true" v-if="!Utils.getViewOnlyFromToken()">Add Supplier</va-button>
    </div>

    <va-card class="row" style="margin: 20px 0;justify-content: center;">
        <va-card-content>
            <va-data-table class="table-crud" :items="brands" :columns="columns" hoverable>
                <template #cell(warehouse)="{ rowData }">
                    {{ rowData.subWarehouse.name }}
                </template>

                <template #cell(actions)="{ rowData }" v-if="!Utils.getViewOnlyFromToken()">
                    <div class="va-spacing-x-3">
                        <va-icon name="edit" @click="openEditBrandModal(rowData)" color="primary"/>
                        <va-icon name="delete" @click="deleteBrand(rowData)" color="primary" />
                    </div>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>

    <va-modal v-model="addBrandModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input v-model="addBrandModal.brand.name" label="Name"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="addBrandModal.show=false;addBrandModal.brand={}" preset="secondary">Close</va-button>
                <va-button @click="addBrand(addBrandModal.brand)" :disabled="disableAddBtn">Add</va-button>
            </div>
        </div>
    </va-modal>

    <va-modal v-model="editBrandModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input v-model="editBrandModal.brand.name" label="Name"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="editBrandModal.show=false" preset="secondary">Close</va-button>
                <va-button @click="updateBrand(editBrandModal.brand)">Edit</va-button>
            </div>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { BrandDTO } from '@/models/warems.models'

    import brandService from '@/services/brand-service'
    import Utils from '@/utils/utils'

    const { confirm } = useModal()
    const brands = ref([] as BrandDTO[])
    const columns = [
        { key: 'name' },
        { key: 'actions' }
    ]
    const addBrandModal = ref({
        show: false,
        brand: {} as BrandDTO
    })
    const editBrandModal = ref({
        show: false,
        brand: {} as BrandDTO
    })

    onBeforeMount(async() => {
        brands.value = await brandService.getAllBrands()
    })

    const disableAddBtn = computed(() => {
        return !addBrandModal.value.brand || !addBrandModal.value.brand.name
    })

    const addBrand = async(brand: BrandDTO) => {
        if (await brandService.addBrand(brand)) {
            confirm({message: 'Brand Added!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const updateBrand = async(brand: BrandDTO) => {
        if (await brandService.updateBrand(brand)) {
            confirm({message: 'Brand Updated!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const openEditBrandModal = (brand: BrandDTO) => {
        const editObj = JSON.parse(JSON.stringify(brand))
        editBrandModal.value.brand = editObj
        editBrandModal.value.show = true
    }

    const deleteBrand = (brand: BrandDTO) => {
        confirm({message: `Are you sure you want to delete "${brand.name}" ?`}).then(async(ok) => {
            if (ok) {
                if (await brandService.deleteBrand(brand)) {
                    confirm({message: 'Brand deleted!', cancelText: ''}).then(() => window.location.reload())
                }
            }
        })
    }
</script>