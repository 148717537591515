import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type { CategoryDTO, SubCategoryDTO } from '@/models/warems.models'
import type Selection from '@/types/Selection'
import type Product from '@/types/Product'

const categoryService = {
    async getAllCategories():Promise<CategoryDTO[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/category`)
        return res?.data.list
    },
    async getAllSubCategories():Promise<SubCategoryDTO[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/category/sub`)
        return res?.data.list
    },
    async getAllCategorySelection(categories_?:CategoryDTO[]):Promise<Selection[]> {
        const categorySelection: Selection[] = []
        const categories = categories_ ? categories_ : await this.getAllCategories()

        categories.forEach((category: CategoryDTO) => {
            const subCategorySelection:Selection[] = []
            if (category.subCategories && category.subCategories.length) {
                category.subCategories.forEach(subCategory => {
                    subCategorySelection.push({text: subCategory.name, id: subCategory.id, value: subCategory.id} as Selection)
                })
            }

            categorySelection.push({
                text: category.name,
                value: category.id,
                optional: { subWarehouse: category.subWarehouse, subCategories: subCategorySelection.length === 0 ? null : subCategorySelection }
            } as Selection)
        })

        return categorySelection
    },
    async getCategorySelectionFromProducts(products:Product[]):Promise<Selection[]> {
        const categorySelection: Selection[] = []
        const categoryMap = new Map()
        
        for (let product of products) {
            let category = product.category
            if (!product.subCategory) {
                categoryMap.set(category.id, category)
                continue
            }
            
            let subCategory = { id: product.subCategory.id, name: product.subCategory.name } as CategoryDTO

            if (categoryMap.has(category.id)) {
                category = categoryMap.get(category.id)

                if (!category.subCategories?.find(s => s.id === subCategory.id)) {
                    category.subCategories?.push(subCategory)
                    categoryMap.set(category.id, category)
                }
            } else {
                category.subCategories = [ subCategory ]
                categoryMap.set(category.id, category)
            }
        }

        categoryMap.forEach((category: CategoryDTO) => {
            const subCategorySelection:Selection[] = []
            if (category.subCategories && category.subCategories.length) {
                category.subCategories.forEach(subCategory => {
                    subCategorySelection.push({text: subCategory.name, id: subCategory.id, value: subCategory.id} as Selection)
                })
            }

            categorySelection.push({
                text: category.name,
                value: category.id,
                optional: { subWarehouse: category.subWarehouse, subCategories: subCategorySelection.length === 0 ? null : subCategorySelection }
            } as Selection)
        })

        return categorySelection
    },
    async addCategory(category: CategoryDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/category`, category)
        return res?.data
    },
    async addSubCategory(subCategory: SubCategoryDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/category/sub`, subCategory)
        return res?.data
    },
    async updateCategory(category: CategoryDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/category`, category)
        return res?.data
    },
    async updateSubCategory(subCategory: SubCategoryDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/category/sub`, subCategory)
        return res?.data
    },
    async deleteCategory(categoryId: string):Promise<boolean> {
        const res = await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/category?id=${encodeURIComponent(categoryId)}`)
        return res?.data.obj
    },
    async deleteSubCategory(subCategoryId: string):Promise<boolean> {
        const res = await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/category/sub?id=${encodeURIComponent(subCategoryId)}`)
        return res?.data.obj
    }
};

export default categoryService;