<template>
    <form @submit.prevent="submitForm">
        <div class="row mb-3 va-spacing-x-3">
            <va-date-input v-model="estimateArrivalTime" label="estimate arrival date" style="max-width: 150px;"/>
            <va-time-input v-model="estimateArrivalTime" label="Time" class="w-28" style="max-width: 150px;"/>
        </div>

        <div class="row va-spacing-x-3 va-spacing-y-3" v-for="(item, index) in deliveryReceiptItems" :key="index">
            <va-select v-model="item.product" label="Product" :options="allProducts" searchable highlight-matched-text />
            <va-input v-model="item.quantity" type="number" min="1" :label="t('form.input.quantity')" style="max-width: 80px;"/>

            <div class="flex items-center" style="margin: 16px 0 0 15px" v-show="index > 0">
                <va-button icon="clear" color="danger" class="mr-3 mb-2" @click="removeItem(index)"/>
            </div>
        </div>
        
        <div class="flex md12 sm12 xs12">
            <va-button icon="add" class="mr-3 mt-3" @click="addItem">Add More Product</va-button>
        </div>
        <div class="flex md12 sm12 xs12" style="text-align: center">
            <va-button type="submit" class="mt-3" :disabled="disableSubmit">Submit</va-button>
        </div>
    </form>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import Utils from '@/utils/utils'
    import productService from '@/services/product-service'
    import deliveryReceiptService from '@/services/delivery-receipt-service'

    import type DeliveryReceiptItem from '@/types/DeliveryReceiptItem'
    import type Selection from '@/types/Selection'

    const { t } = useI18n()
    const emit = defineEmits();

    const deliveryReceiptItems = ref([ {} as DeliveryReceiptItem ])
    const allProducts = ref([] as Selection[])
    const estimateArrivalTime = ref(new Date())

    onBeforeMount(async () => {
        let products = await productService.findAllProducts(null)
        allProducts.value.push(...Utils.getProductsForSelection(products, false, true, false))
    })

    // Methods

    const disableSubmit = computed(() => {
        for (let deliveryReceiptItem of deliveryReceiptItems.value) {
            if (!deliveryReceiptItem.quantity) return true
        }
        return deliveryReceiptItems.value.length < 1
    })

    const addItem = () => {
        deliveryReceiptItems.value.push({} as DeliveryReceiptItem)
    };

    const removeItem = (idx: number) => {
        deliveryReceiptItems.value.splice(idx, 1)
    };

    const submitForm = async () => {
        const deliveryReceiptItemList: any = []
        
        deliveryReceiptItems.value.forEach(deliveryReceiptItem => {
            deliveryReceiptItemList.push({
                productId: deliveryReceiptItem.product.value,
                quantity: deliveryReceiptItem.quantity
            })
        })

        const data = {
            userId : Utils.getIdFromToken(),
            deliveryReceiptItems: deliveryReceiptItemList,
            estimateArrivalTime: estimateArrivalTime.value.getTime()
        };

        emit('submit-res', await deliveryReceiptService.createDeliveryReceipt(data))
    };
</script>