<template>
    <div class="mt-3 ml-4 mr-4">
        <va-chip class="mb-3" color="success">{{ userDetails.name }}</va-chip>

        <h6 class="va-h6 mb-3">Product Visibility</h6>
        <va-data-table v-model="selectedProducts" :items="allProducts" :columns="columns" @selectionChange="productSelected" 
            select-mode="multiple" :loading="loading" selectable sticky-header hoverable>
            <template #cell(brand)="{ rowData }">
                {{ rowData.brand ? rowData.brand.name : null }}
            </template>
            <template #cell(warehouse)="{ rowData }">
                {{ rowData.subWarehouse ? rowData.subWarehouse.name : null }}
            </template>
        </va-data-table>

        <div class="row va-spacing-x-3 mt-4" style="justify-content: center;">
            <va-button @click="updateProductVisibility" :disabled="disableUpdateBtn">Update</va-button>
            <va-button @click="back">Back</va-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { onBeforeMount, ref, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { useModal } from 'vuestic-ui'

    import type { UserDTO } from '@/models/warems.models'
    import type Product from '@/types/Product'

    import userService from '@/services/user-service'
    import productService from '@/services/product-service'
    import userProductService from '@/services/user-product-service'
    import Utils from '@/utils/utils'

    const { confirm } = useModal()
    const route = useRoute()
    const columns = [
        { key: 'secondaryName', sortable: true },
        { key: 'tertiaryName', sortable: true },
        { key: 'brand', sortable: true },
        { key: 'warehouse', sortable: true },
        { key: 'price', sortable: true },
        { key: 'wholesalePrice', sortable: true }
    ]
    const allProducts = ref([] as Product[])
    const selectedProducts = ref([] as Product[])
    const storeProductIds = ref([] as string[])
    const userDetails = ref({} as UserDTO)
    const loading = ref(true)

    onBeforeMount(async() => {
        if (route.query.userId) {
            storeProductIds.value = await userProductService.getProductIdsByStoreId(route.query.userId)
            userDetails.value = await userService.getUser(route.query.userId)
            allProducts.value = await productService.findAllProducts(null)

            if (storeProductIds.value && storeProductIds.value.length) {
                for (let product of allProducts.value) {
                    if (!product.productId) continue
                    if (storeProductIds.value.indexOf(product.productId) > -1) {
                        selectedProducts.value.push(product)
                    }
                }
            }

            loading.value = false
        }
    })

    const disableUpdateBtn = computed(() => {
        if (selectedProducts.value.length > 0 && selectedProducts.value.length === storeProductIds.value.length) {
            for (let product of selectedProducts.value) {
                if (!storeProductIds.value.includes(product.productId)) return false
            }
            return true
        }
        return false
    })

    const updateProductVisibility = async() => {
        await userProductService.updateUserProductByUser({ userId: userDetails.value.id, productIds: selectedProducts.value.map(product => product.productId) })
        window.location.reload()
    }

    const back = () => {
        window.location.href = '/in/settings/user'
    }

    const productSelected = ({currentSelectedItems}) => {
        const seen:string[] = []
        const removeIdx:number[] = []

        for (let i = 0; i < currentSelectedItems.length; i++) {
            let product = currentSelectedItems[i]
            const productName = Utils.getProductName(product)
            if (seen.indexOf(productName) > -1) {
                removeIdx.push(i)
                confirm({message: `Can not select duplicate '${productName}'!`, cancelText: ''})
            } else {
                seen.push(productName)
            }
        }

        removeIdx.sort((a, b) => b - a)
        removeIdx.forEach(idx => {
            if (idx >= 0 && idx < currentSelectedItems.length) {
                currentSelectedItems.splice(idx, 1)
            }
        })
    }
</script>