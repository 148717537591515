export default {
  sizesConfig: {
    defaultSize: 19,
    sizes: {
      small: 14,
      medium: 19,
      large: 26,
    },
  },
}
