<template>
    <va-modal v-model="showModal" hideDefaultActions noDismiss>
        <div class="va-spacing-y-3">
            <h6 class="va-h6">Login expiring after</h6>
            <h2 class="va-h2">{{ diff.hours }} : {{ diff.minutes }} : {{ diff.seconds }}</h2>
            <h6 class="va-h6">Please Login again</h6>
            <div class="row">
                <LoginForm @ok="login" no-forget-pwd/>
            </div>
        </div>
    </va-modal>
</template>
  
<script setup lang="ts">
    import { ref, onMounted, onUnmounted } from 'vue'
    
    import LoginForm from '@/components/LoginForm.vue'
    import Utils from '@/utils/utils'
  
    const props = defineProps({
        targetDate: Date
    })

    let intervalId: number | null = null
    const diff = ref({ hours: 0, minutes: 0, seconds: 0 })
    const showModal = ref(false)

    onMounted(() => {
        intervalId = window.setInterval(() => {
            getTimeDifference(props.targetDate)
        }, 1000)
    })

    onUnmounted(() => {
        if (intervalId !== null) {
            window.clearInterval(intervalId)
        }
    })

    const resetTimer = (targetDate: Date) => {
        window.clearInterval(intervalId)

        intervalId = window.setInterval(() => {
            getTimeDifference(targetDate)
        }, 1000)
    }

    const getTimeDifference = (targetDate: Date) => {
        let diffInMilliseconds = targetDate.getTime() - new Date().getTime()

        if (diffInMilliseconds <= 0) {
            localStorage.clear()
            window.location.href = '/login'
        }

        const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))
        diffInMilliseconds -= days * (1000 * 60 * 60 * 24)

        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
        diffInMilliseconds -= hours * (1000 * 60 * 60)

        const minutes = Math.floor(diffInMilliseconds / (1000 * 60))
        diffInMilliseconds -= minutes * (1000 * 60)

        const seconds = Math.floor(diffInMilliseconds / 1000)

        diff.value = {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }

        if (!showModal.value && days === 0 && hours === 0 && minutes <= 5) {
            showModal.value = true
        } else if (showModal.value && days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            localStorage.clear()
            window.location.href = '/login'
        }
    }

    const login = () => {
        resetTimer(Utils.getTokenExpDate())
        showModal.value = false
    }
</script>  