<template>
    <div class="ml-5 mt-5 va-spacing-y-5" v-if="userPreference">
        <div>
            <p class="va-h5 mb-4">Display Settings</p>
            <div>
                <va-select label="Order Product View" :options="orderProductViewSelections" v-model="userPreference.orderProductView" value-by="valueBy"/>
                <va-icon name="info" color="primary" class="ml-2" @click="showOrderProductViewHelp = true"/>
            </div>
        </div>
        <div v-if="Utils.isAdminRole()">
            <p class="va-h5 mb-4">Email Notice</p>
            <div>
                <va-checkbox label="New Order Notice" v-model="userPreference.newOrderNotice"/>
            </div>
            <!-- <div>
                <va-checkbox v-if="Utils.isStoreRole()" label="Order Status Notice" v-model="userPreference.orderStatusNotice"/>
            </div> -->
        </div>
        <div class="row">
            <va-button @click="updatePreference">Update</va-button>
        </div>
    </div>

    <va-modal v-model="showOrderProductViewHelp" hideDefaultActions noDismiss close-button>
        <div class="va-spacing-y-3">
            <h3 class="va-h3">Display as List (列表选择)</h3>
            <img :src="IMG_ORDER_ITEM_LIST" width="600"/>
            <h3 class="va-h3">Display as Card (块状条列)</h3>
            <img :src="IMG_ORDER_ITEM_CARD" width="600"/>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import IMG_ORDER_ITEM_CARD  from '@/assets/img/order-item-display-as-card.png'
    import IMG_ORDER_ITEM_LIST  from '@/assets/img/order-item-display-as-list.png'

    import type { PreferenceDTO } from '@/models/warems.models'

    import preferenceService from '@/services/preference-service'
    import { useModal } from 'vuestic-ui'
import Utils from '@/utils/utils'

    const { confirm } = useModal()
    const userPreference = ref({} as PreferenceDTO)
    const orderProductViewSelections = [{ text: 'Display as List (列表选择)', valueBy: 'LIST' }, { text: 'Display as Card (块状条列)', valueBy: 'CARD' }]
    const showOrderProductViewHelp = ref(false)

    onBeforeMount(async() => {
        const getUserPrefenceRes = await preferenceService.getUserPrefence()
        
        if (getUserPrefenceRes) {
            userPreference.value = getUserPrefenceRes
        }
    })

    const updatePreference = async() => {
        if (await preferenceService.updateUserPrefence(userPreference.value)) {
            confirm({message: 'User Preference Updated.', cancelText: ''}).then(() => window.location.reload())
        } else {
            confirm({message: 'User Preference Not found.', cancelText: ''})
        }
    }
</script>