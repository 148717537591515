import AccessUtil from '@/utils/access'
import RouterBypass from '@/components/RouterBypass.vue'

import InventoryDashboardView from '@/views/inventory/InventoryDashboardView.vue'
import ProductDashboardView from '@/views/product/ProductDashboardView.vue'
import OrderDashboardView from '@/views/order/OrderDashboardView.vue'
import InboundView from '@/views/inbound/InboundView.vue';
import DeliveryReceiptView from '@/views/delivery-receipt/DeliveryReceiptView.vue'
import DeliveryReceiptAddView from '@/views/delivery-receipt/DeliveryReceiptAddView.vue'
import ReportSalesView from '@/views/report/ReportSalesView.vue'
import ReportCategoryView from '@/views/report/ReportCategoryView.vue'
import ReportProductUsageView from '@/views/report/ReportProductUsageView.vue'
import InvoicesView from '@/views/invoices/InvoicesView.vue'
import AddressView from '@/views/settings/AddressView.vue'
import UserDashboardView from '@/views/user/UserDashboardView.vue'
import UserDetailsViewVue from '@/views/user/UserDetailsView.vue'
import CategoryViewVue from '@/views/settings/CategoryView.vue'
import ProductEditViewVue from '@/views/product/ProductEditView.vue'
import ProductAddViewVue from '@/views/product/ProductAddView.vue'
import DashboardViewVue from '@/views/dashboard/DashboardView.vue'
import BrandViewVue from '@/views/settings/BrandView.vue'
import SubWarehouseViewVue from '@/views/settings/SubWarehouseView.vue'
import AddInventoryView from '@/views/inventory/AddInventoryView.vue'
import ActivityLogView from '@/views/admin/ActivityLogView.vue'
import PreferenceView from '@/views/settings/PreferenceView.vue'
import OrderTemplateView from '@/views/settings/order-template/OrderTemplateView.vue'
import OrderTemplateEditView from '@/views/settings/order-template/OrderTemplateEditView.vue'
import WarehouseControlView from '@/views/admin/WarehouseControlView.vue'
import MismatchQuantityView from '@/views/admin/MismatchQuantityView.vue'
import OutboundView from '@/views/order/OutboundView.vue'
import ReceivedDashboardView from '@/views/received/ReceivedDashboardView.vue'
import AddReceivedView from '@/views/received/AddReceivedView.vue'

export interface INavigationRoute {
    path: string
    name: string
    meta: { icon: string, roles: string[], showInSidebar: boolean, displayName: string }
    children?: INavigationRoute[]
}

export default [
    {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardViewVue,
        meta: { icon: 'dashboard', roles: AccessUtil.inventoryRoles(), showInSidebar: AccessUtil.hasInventoryAccess(), displayName: 'Dashboard' }
    },
    {
        path: 'order',
        name: 'order',
        component: RouterBypass,
        meta: { icon: 'list_alt', showInSidebar: true, displayName: 'Order' },
        children: [
            {
                path: '',
                name: 'order-overview',
                component: OrderDashboardView,
                meta: { roles: AccessUtil.allRoles(), showInSidebar: true, displayName: 'Order Overview' }
            },
            {
                path: 'new',
                name: 'add-order',
                component: () => import('@/views/order/AddOrderView.vue'),
                meta: { type: 'add', roles: AccessUtil.addOrderRoles(), showInSidebar: AccessUtil.hasAddOrderAccess(), displayName: 'New Order' }
            },
            {
                path: 'edit',
                name: 'edit-order',
                component: () => import('@/views/order/EditOrderView.vue'),
                meta: { type: 'edit', roles: AccessUtil.adminRoles(), displayName: 'Edit Order' }
            },
            // {
            //     path: 'outbound',
            //     name: 'outbound',
            //     component: () => import('@/components/OrderInventoryScanView.vue'),
            //     meta: { type: 'outbound', roles: AccessUtil.outboundRoles(), displayName: 'Outbound' }
            // },
            {
                path: 'outbound',
                name: 'outbound',
                component: OutboundView,
                meta: { roles: AccessUtil.outboundRoles(), displayName: 'Outbound' }
            },
            {
                path: 'process',
                name: 'process-order',
                component: () => import('@/views/order/ProcessOrderView.vue'),
                meta: { roles: AccessUtil.orderProcessingRoles(), displayName: 'Processing Order' }
            },
            {
                path: 'invoice',
                name: 'generate-order-invoice',
                component: () => import('@/views/invoices/AddInvoiceView.vue'),
                meta: { roles: AccessUtil.generateInvoiceRoles(), displayName: 'Generate Invoice' }
            },
            // {
            //     path: 'complete',
            //     name: 'complete-order',
            //     component: () => import('@/components/OrderInventoryScanView.vue'),
            //     meta: { type: 'complete', roles: AccessUtil.orderCompleteRoles(), displayName: 'Complete Order' }
            // },
            {
                path: 'manual',
                name: 'manual-order',
                component: () => import('@/views/order/ManulOrderView.vue'),
                meta: { roles: AccessUtil.adminRoles(), showInSidebar: AccessUtil.isAdminGroup(), displayName: 'Add Manual Order' },
            }
        ]
    },
    {
        path: 'return',
        name: 'return',
        component: RouterBypass,
        meta: { icon: 'delete_forever', roles: AccessUtil.returnRoles(), showInSidebar: AccessUtil.hasReturnAccess(), displayName: 'Return' },
        children: [
            {
                path: '',
                name: 'return-dashboard',
                component: () => import('@/views/return/ReturnOrderDashboard.vue'),
                meta: { showInSidebar: AccessUtil.hasReturnAccess(), displayName: 'Return Overview' }
            },
            {
                path: 'new',
                name: 'add-return',
                component: () => import('@/views/return/EditReturnOrderView.vue'),
                meta: { type: 'new', showInSidebar: AccessUtil.hasReturnAccess(), displayName: 'Add Return' }
            },
            {
                path: 'edit',
                name: 'edit-return',
                component: () => import('@/views/return/EditReturnOrderView.vue'),
                meta: { type: 'edit', showInSidebar: false, displayName: 'Edit Return' }
            }
        ]
    },
    {
        path: 'inventory',
        name: 'inventory',
        component: RouterBypass,
        meta: { icon: 'inventory_2', roles: AccessUtil.inventoryRoles(), showInSidebar: AccessUtil.hasInventoryAccess(), displayName: 'Inventory' },
        children: [
            {
                path: '',
                name: 'inventory-dashboard',
                component: InventoryDashboardView,
                meta: { showInSidebar: AccessUtil.hasInventoryAccess(), displayName: 'Inventory Overview' }
            },
            {
                path: 'new',
                name: 'add-inventory',
                component: AddInventoryView,
                meta: { showInSidebar: AccessUtil.hasAddInventoryAccess(), displayName: 'Add Inventory' }
            },
            {
                path: 'details',
                name: 'inventory-details',
                component: () => import('@/views/inventory/InventoryDetailsView.vue'),
                meta: { displayName: 'Inventory Details' }
            },
            {
                path: 'history',
                name: 'inventory-history',
                component: () => import('@/views/inventory/InventoryHistoryView.vue'),
                meta: { displayName: 'Inventory History' }
            }
        ]
    },
    {
        path: 'received',
        name: 'received',
        component: RouterBypass,
        meta: { icon: 'call_received', roles: AccessUtil.inventoryRoles(), showInSidebar: AccessUtil.hasInventoryAccess(), displayName: 'Received' },
        children: [
            {
                path: '',
                name: 'received-dashboard',
                component: ReceivedDashboardView,
                meta: { showInSidebar: AccessUtil.hasInventoryAccess(), displayName: 'Received Overview' }
            },
            {
                path: 'new',
                name: 'add-received',
                component: AddReceivedView,
                meta: { showInSidebar: AccessUtil.hasAddInventoryAccess(), displayName: 'Add Received' }
            }
        ]
    },
    {
        path: 'delivery-receipt',
        name: 'delivery-receipt',
        component: RouterBypass,
        meta: { icon: 'receipt_long', roles: AccessUtil.deliveryReceiptRoles(), showInSidebar: AccessUtil.hasDeliveryReceiptAccess(), displayName: 'Delivery Receipt' },
        children: [
            {
                path: '',
                name: 'delivery-receipt-dashboard',
                component: DeliveryReceiptView,
                meta: { showInSidebar: AccessUtil.hasDeliveryReceiptAccess(), displayName: 'Delivery Receipt Overview' }
            },
            {
                path: 'new',
                name: 'add-delivery-receipt',
                component: DeliveryReceiptAddView,
                meta: { showInSidebar: AccessUtil.hasAddDeliveryReceiptAccess(), displayName: 'Add Delivery Receipt' }
            }
        ]
    },
    {
        path: 'report',
        name: 'report',
        component: RouterBypass,
        meta: { icon: 'description', roles: AccessUtil.reportPageRoles(), showInSidebar: AccessUtil.hasReportAccess(), displayName: 'Report' },
        children: [
            {
                path: 'statement',
                name: 'statement',
                component: ReportSalesView,
                meta: { roles: AccessUtil.reportPageRoles(), showInSidebar: AccessUtil.hasReportAccess(), displayName: 'Statement' }
            },
            {
                path: 'category',
                name: 'report-category',
                component: ReportCategoryView,
                meta: { roles: AccessUtil.adminReportPageRoles(), showInSidebar: AccessUtil.hasAdminReportAccess(), displayName: 'Category' }
            },
            {
                path: 'product-usage',
                name: 'report-product-usage',
                component: ReportProductUsageView,
                meta: { roles: AccessUtil.adminReportPageRoles(), showInSidebar: AccessUtil.hasAdminReportAccess(), displayName: 'Product Usage' }
            }
        ]
    },
    {
        path: 'settings',
        name: 'settings',
        component: RouterBypass,
        meta: { icon: 'settings', showInSidebar: AccessUtil.hasSettingsAccess(), displayName: 'Settings' },
        children: [
            {
                path: 'subwarehouse',
                name: 'subwarehouse',
                component: SubWarehouseViewVue,
                meta: { roles: AccessUtil.categoryRoles(), showInSidebar: AccessUtil.hasCategoryPageAccess(), displayName: 'Warehouse' }
            },
            {
                path: 'category',
                name: 'category',
                component: CategoryViewVue,
                meta: { roles: AccessUtil.categoryRoles(), showInSidebar: AccessUtil.hasCategoryPageAccess(), displayName: 'Category' }
            },
            {
                path: 'brand',
                name: 'brand',
                component: BrandViewVue,
                meta: { roles: AccessUtil.categoryRoles(), showInSidebar: AccessUtil.hasCategoryPageAccess(), displayName: 'Supplier' }
            },
            {
                path: 'product',
                name: 'product',
                component: RouterBypass,
                meta: { roles: AccessUtil.productPageRoles(), showInSidebar: AccessUtil.hasProductPageAccess(), displayName: 'Product' },
                children: [
                    {
                        path: '',
                        name: 'product-dashboard',
                        component: ProductDashboardView,
                        meta: { displayName: 'Product Overview' }
                    },
                    {
                        path: 'new',
                        name: 'product-add',
                        component: ProductAddViewVue,
                        meta: { displayName: 'New Product' }
                    },
                    {
                        path: 'edit',
                        name: 'product-edit',
                        component: ProductEditViewVue,
                        meta: { displayName: 'Edit Product', roles: AccessUtil.editProductPageRoles() }
                    }
                ]
            },
            {
                path: 'address',
                name: 'address',
                component: AddressView,
                meta: { roles: AccessUtil.addressPageRoles(), showInSidebar: AccessUtil.hasAddressAccess(), displayName: 'Address' }
            },
            {
                path: 'user',
                component: RouterBypass,
                meta: { roles: AccessUtil.userPageRoles(), showInSidebar: AccessUtil.hasUserAccess(), displayName: 'User' },
                children: [
                  {
                    path: '',
                    name: 'user',
                    component: UserDashboardView,
                    meta: { displayName: 'User Overview' }
                  },
                  {
                    path: 'details',
                    name: 'user-details',
                    component: UserDetailsViewVue,
                    meta: { displayName: 'User Details' }
                  }
                ]
            },
            {
                path: 'order-templates',
                component: RouterBypass,
                meta: { roles: AccessUtil.addOrderRoles(), showInSidebar: AccessUtil.hasAddOrderAccess(), displayName: 'Order Templates' },
                children: [
                    {
                        path: '',
                        name: 'order-templates',
                        component: OrderTemplateView,
                        meta: { displayName: 'Order Templates' }
                    },
                    {
                        path: 'new',
                        name: 'new-order-templates',
                        component: OrderTemplateEditView,
                        meta: { displayName: 'New Order Template', type: 'new' }
                    },
                    {
                        path: 'edit',
                        name: 'edit-order-templates',
                        component: OrderTemplateEditView,
                        meta: { displayName: 'Edit Order Template', type: 'edit' }
                    },
                ]
            },
            {
                path: 'preference',
                name: 'preference',
                component: PreferenceView,
                meta: { roles: AccessUtil.allRoles(), showInSidebar: true, displayName: 'Preference' }
            }
        ]
    },
    {
        path: 'inbound',
        name: 'inbound',
        component: InboundView,
        meta: { roles: AccessUtil.inboundRoles(), displayName: 'Inbound' }
    },
    {
        path: 'invoices',
        name: 'invoices',
        component: InvoicesView,
        meta: { roles: AccessUtil.viewInvoicesRoles(), displayName: 'Invoice' }
    },
    {
        path: 'admin',
        name: 'admin',
        component: RouterBypass,
        meta: { icon: 'admin_panel_settings', roles: AccessUtil.systemAdminRoles(), displayName: 'Admin', showInSidebar: AccessUtil.isSystemAdmin() },
        children: [
            {
                path: 'warehouse-control',
                name: 'warehouse-control',
                component: WarehouseControlView,
                meta: { displayName: 'Warehouse Control', roles: AccessUtil.systemAdminRoles(), showInSidebar: AccessUtil.isSystemAdmin() }
            },
            {
                path: 'activity-logs',
                name: 'activity-logs',
                component: ActivityLogView,
                meta: { displayName: 'Activity Logs', roles: AccessUtil.systemAdminRoles(), showInSidebar: AccessUtil.isSystemAdmin() }
            },
            {
                path: 'mismatch-products',
                name: 'mismatch-products',
                component: MismatchQuantityView,
                meta: { displayName: 'Mismatch Product', roles: AccessUtil.systemAdminRoles(), showInSidebar: AccessUtil.isSystemAdmin() }
            },
        ]
    },
] as INavigationRoute[]