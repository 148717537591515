<template>
    <va-card>
        <va-card-content>
            <va-data-table class="table-crud" :items="receivedItems" :columns="columns" hoverable>
                <template #cell(supplier)="{ rowData }">
                    {{ rowData.brand }}
                </template>
                <template #cell(warehouse)="{ rowData }">
                    {{ rowData.subWarehouse }}
                </template>
                <template #cell(date)="{ rowData }">
                    {{ new Date(rowData.date*1).toLocaleDateString() }}
                </template>
                <template #cell(items)="{ row, isExpanded }">
                    <va-button
                        @click="row.toggleRowDetails()"
                        :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
                        preset="secondary"
                        class="w-full"
                    >
                        {{ isExpanded ? 'Hide': 'Details' }}
                    </va-button>
                </template>
                <template #expandableRow="{ rowData }">
                    <div class="va-table-responsive" style="padding: 10px">
                        <table class="va-table va-table--hoverable">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in rowData.inventoryHistories" :key="idx">
                                    <td>{{ item.productName }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.weight }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import receivedService from '@/services/received-service'

    import type { ReceivedDTO } from '@/models/warems.models'

    const receivedItems = ref([] as ReceivedDTO[])
    const columns = [
        { key: "id" },
        { key: "date" },
        { key: "warehouse" },
        { key: "supplier" },
        { key: "items" }
    ]

    onBeforeMount(async() => {
        receivedItems.value = await receivedService.getAllReceived()
    })
</script>
<style>
    .insufficient-inventory {
        background-color: darkorange;
    }
</style>