<template>
    <div style="margin-top: 5px; float: right;">
        <va-button :preset="showFilter ? 'none' : 'secondary'" @click="showFilter = !showFilter">
            <va-icon name="filter_alt"/> Filter
        </va-button>
    </div>

    <div class="row" style="margin: 20px 0;justify-content: center;" v-if="showFilter">
        <va-card stripe stripe-color="success">
            <va-card-content class="va-spacing-y-4">
                <va-date-input v-model="filterDateRange" :format-date="Util.formatDateOnDateTable" :parse-date="Util.parseDateOnDateTable" label="Date Range"
                    @update:modelValue="getSalesReport"/>

                <div class="row va-spacing-x-4" style="margin-bottom: 10px;justify-content: center;">
                    <va-button :preset="filterBtns.allTime" border-color="primary" round @click="getAllTimeReport">All Time</va-button>
                    <va-button :preset="filterBtns.thisMonth" border-color="primary" round @click="getCurrentMonthReport">This Month</va-button>
                </div>
                <div class="row va-spacing-x-4" style="justify-content: center;">
                    <va-button :preset="filterBtns.lastMonth" border-color="primary" round @click="getLastMonthReport">Last Month</va-button>
                    <va-button :preset="filterBtns.thisYear" border-color="primary" round @click="getThisYearReport">This Year</va-button>
                </div>
            </va-card-content>
        </va-card>
    </div>

    <va-data-table :items="customerSalesList" :columns="columns" :noDataHtml="'No reports available. Try adjusting filters.'" virtual-scroller sticky-header hoverable>
        <template #cell(customer)="{ rowData }">
            <a v-if="filterDateRange && filterDateRange.start && filterDateRange.end" 
                :href="filterDateRange.preset ? `/in/invoices?userId=${encodeURIComponent(rowData.userId)}&start=${filterDateRange.start.getTime()}&end=${filterDateRange.end.getTime()}&preset=${filterDateRange.preset}` : 
                `/in/invoices?userId=${encodeURIComponent(rowData.userId)}&start=${filterDateRange.start.getTime()}&end=${filterDateRange.end.getTime()}`">
                {{ rowData.name }}
            </a>
            <span v-else>{{ rowData.name }}</span>
        </template>
        <template #cell(sales)="{ rowData }">
            {{ rowData.formattedSales }}
        </template>
        <template #cell(paid)="{ rowData }">
            {{ rowData.formattedPaid }}
        </template>
        <template #cell(owed)="{ rowData }">
            {{ rowData.formattedOwed }}
        </template>
        <template #cell(actions)="{ rowData }">
            <va-button icon="description" preset="secondary" @click="previewStatement(rowData.userId)">Statement</va-button>
        </template>
    </va-data-table>

    <StatementModal v-if="statementModal.show" :statement="statementModal.statement" :start="statementModal.start" :end="statementModal.end"
        :user-id="statementModal.userId" @close="statementModal.show = false"/>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import { useRoute } from 'vue-router'
    
    import Util from '@/utils/utils'

    import type { CustomerSalesDTO, StatementDTO } from '@/models/warems.models'

    import StatementModal from '@/components/modal/StatementModal.vue'
    import reportService from '@/services/report-service'

    const route = useRoute()
    const columns = [
      { key: "customer" },
      { key: "sales" },
      { key: "paid" },
      { key: "owed" },
      { key: 'actions', width: 80 }
    ];
    const customerSalesList = ref([] as CustomerSalesDTO[])
    const filterDateRange = ref({ start: {} as Date, end: {} as Date, preset: null as String|null })
    const filterBtns = ref({
        allTime: 'secondary',
        thisMonth: 'secondary',
        lastMonth: 'secondary',
        thisYear: 'secondary',
    })
    const showFilter = ref(false)
    const statementModal = ref({
        show: false,
        statement: {} as StatementDTO,
        userId: '',
        start: 0,
        end: 0
    })

    onBeforeMount(async() => {
        if (route.query.preset) {
            if(route.query.preset === 'last') {
                getLastMonthReport()
            } else if (route.query.preset === 'ytd') {
                getThisYearReport()
            } else if (route.query.preset === 'all') {
                getAllTimeReport()
            } else if (route.query.preset === 'current') {
                getCurrentMonthReport()
            }
        } else if (route.query.start && route.query.end) {
            const startTime: number = route.query.start * 1
            const endTime: number = route.query.end * 1
            filterDateRange.value.start = new Date(startTime)
            filterDateRange.value.end = new Date(endTime)
            getSalesReport()
        } else {
            getCurrentMonthReport()
        }
    })

    // Methods
    const getSalesReport = async() => {
        const res = await reportService.getSalesReport(filterDateRange.value.start.getTime(), filterDateRange.value.end.getTime())

        if (res.data) {
            customerSalesList.value = res.data.list
        }
    }

    const getCurrentMonthReport = async() => {
        if (filterBtns.value.thisMonth === 'none') return

        filterBtns.value.allTime = 'secondary'
        filterBtns.value.lastMonth = 'secondary'
        filterBtns.value.thisMonth = 'none'
        filterBtns.value.thisYear = 'secondary'

        let today = new Date();
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0)

        filterDateRange.value = { start: firstDayOfMonth, end: new Date(), preset: 'current' }
        
        await getSalesReport()
    }

    const getLastMonthReport = async() => {
        if (filterBtns.value.lastMonth === 'none') return

        filterBtns.value.allTime = 'secondary'
        filterBtns.value.lastMonth = 'none'
        filterBtns.value.thisMonth = 'secondary'
        filterBtns.value.thisYear = 'secondary'

        let today = new Date()
        let firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1, 23, 59, 59, 999)
        
        let lastDayOfLastMonth = new Date(firstDayOfCurrentMonth)
        lastDayOfLastMonth.setDate(lastDayOfLastMonth.getDate() - 1)

        let firstDayOfLastMonth = new Date(lastDayOfLastMonth.getFullYear(), lastDayOfLastMonth.getMonth(), 1, 0, 0, 0, 0);

        filterDateRange.value = { start: firstDayOfLastMonth, end: lastDayOfLastMonth, preset: 'last' }
        
        await getSalesReport()
    }

    const getThisYearReport = async() => {
        if (filterBtns.value.thisYear === 'none') return

        filterBtns.value.allTime = 'secondary'
        filterBtns.value.lastMonth = 'secondary'
        filterBtns.value.thisMonth = 'secondary'
        filterBtns.value.thisYear = 'none'

        let today = new Date()
        let start = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);

        filterDateRange.value = { start, end: today, preset: 'ytd' }
        
        await getSalesReport()
    }

    const getAllTimeReport = async() => {
        if (filterBtns.value.allTime === 'none') return

        filterBtns.value.allTime = 'none'
        filterBtns.value.lastMonth = 'secondary'
        filterBtns.value.thisMonth = 'secondary'
        filterBtns.value.thisYear = 'secondary'

        let today = new Date()
        let start = new Date(2023, 0, 1, 0, 0, 0, 0);

        filterDateRange.value = { start, end: today, preset: 'all' }
        
        await getSalesReport()
    }

    const previewStatement = async(userId: string) => {
        const start = filterDateRange.value.start.getTime()
        const end = filterDateRange.value.end.getTime()

        const res = await reportService.getStatementReport(start, end, userId)

        if (res.data) {
            statementModal.value.statement = res.data.obj
            statementModal.value.userId = userId
            statementModal.value.start = start
            statementModal.value.end = end
            statementModal.value.show = true
        }
    }
</script>