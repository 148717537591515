<template>
    <va-modal v-model="showModal" no-outside-dismiss hideDefaultActions no-padding @close="closeModal" close-button>
        <StatementPDF :statement="props.statement"/>

        <div class="va-spacing-x-3" style="text-align: center; margin: 30px 0 20px 0;">
            <va-button @click="previewStatement">Preview</va-button>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref } from 'vue'

    import type { PropType } from 'vue'
    import type { StatementDTO } from '@/models/warems.models'

    import StatementPDF from '@/components/report/StatementPDF.vue'
    import invoiceService from '@/services/invoice-service'
    import { useLoadingStore } from '@/stores/loading-store'
    
    const props = defineProps({
        statement: Object as PropType<StatementDTO>,
        userId: String,
        start: Number,
        end: Number,
    })
    const emits = defineEmits(['close'])
    const showModal = ref(true)
    const { showLoading, hideLoading } = useLoadingStore()

    // Methods
    const closeModal = () => {
        emits('close')
    }

    const previewStatement = async() => {
        showLoading()
        const response = await invoiceService.downloadStatementByUserAndTimeRange(props.userId, props.start, props.end)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
        hideLoading()
    }
</script>