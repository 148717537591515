import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type { DashboardInfo } from '@/models/warems.models'

const dashboardService = {
    async getDashboardInfo():Promise<DashboardInfo> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/dashboard`)
        return res?.data.obj
    }
}

export default dashboardService