import axios from 'axios'

import Utils from '@/utils/utils'

import type { CategoryDTO, ProductUsageReportRequest, ProductUsageReport } from '@/models/warems.models'

const reportService = {
    async getSalesReport(startDate: number, endDate: number) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/report/sales`, {
            startDate, endDate, timezone: Utils.getTimezoneFromToken()
        })
    },
    async getStatementReport(start: number, end: number, userId: string) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/report/statement`, { start, end, userId })
    },
    async getCategoryReport(): Promise<CategoryDTO[]> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/report/category`)).data.list
    },
    async getProductUsageReport(request: ProductUsageReportRequest): Promise<ProductUsageReport[]> {
        return (await axios.post(`${import.meta.env.VITE_APP_API_BASE}/report/product-usage`, request)).data.list
    }
};

export default reportService;