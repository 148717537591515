<template>
    <form @submit.prevent="submitForm" class="va-spacing-y-3">
        <div class="va-spacing-x-4 mb-5">
            <va-select label="Category 1" v-if="props.controller?.showCategory" v-model="selectedCategory" :options="categorySelection"
                @update:modelValue="handleCategorySelected"/>
            <va-select label="Category 2" v-if="props.controller?.showCategory" v-model="selectedSubCategory" :options="subCategorySelection"
                @update:modelValue="processFilter"/>
            <va-date-input label="order date" v-if="props.controller?.orderDate?.show" v-model="orderDate"/>
            <va-date-input label="invoice date" v-if="props.controller?.invoiceDate?.show" v-model="invoiceDate"/>
            <va-button v-if="props.controller?.orderTemplate?.show" style="margin-top: 17px;" @click="showOrderTemplateModal=true">
                Select Template
            </va-button>
        </div>
        <OrderProductCards v-if="props.userPreference && props.userPreference.orderProductView && props.userPreference.orderProductView === 'CARD'" 
            ref="orderProductsCardsRef" :controller="props.controller" @back="goToPrevious" @submit="submitForm"/>
        <OrderProductsBlock v-else ref="orderProductsBlockRef" :controller="props.controller" @back="goToPrevious" @submit="submitForm"/>
    </form>

    <va-modal v-model="showOrderTemplateModal">
        <va-select label="Order Template" v-model="selectedOrderTemplate"
            :options="orderTemplateSelection" :readonly="props.controller?.orderTemplate?.readonly" @update:modelValue="orderTemplateSelected"/>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import Utils from '@/utils/utils'

    import productService from '@/services/product-service'
    import categoryService from '@/services/category-service'
    import OrderProductsBlock from '@/components/order/OrderProductsBlock.vue'
    import OrderProductCards from '@/components/order/OrderProductCards.vue'
    import { useLoadingStore } from '@/stores/loading-store'

    import type { PropType } from 'vue'
    import type OrderItem from '@/types/OrderItem'
    import type Selection from '@/types/Selection'
    import type OrderController from '@/types/OrderController'
    import type OrderDetails from '@/types/OrderDetails'
    import type { OrderTemplateDTO, PreferenceDTO } from '@/models/warems.models'
    import type Product from '@/types/Product'

    const props = defineProps({
        controller: Object as PropType<OrderController>,
        orderDetails: Object as PropType<OrderDetails>,
        orderDate: Object as PropType<Date>,
        invoiceDate: Object as PropType<Date>,
        step: Number as PropType<Number>,
        userPreference: Object as PropType<PreferenceDTO>,
        orderTemplateSelection: Object as PropType<Selection[]>
    })
    const { showLoading, hideLoading } = useLoadingStore()
    const emits = defineEmits(['submit', 'back'])
    const allProductSelections = ref([] as Selection[])
    const products = ref([] as Product[])
    const selectedOrderTemplate = ref({} as Selection)
    const orderDate = ref({} as Date)
    const invoiceDate = ref({} as Date)
    const orderProductsBlockRef = ref(null)
    const orderProductsCardsRef = ref(null)
    const selectedCategory = ref({text: 'All', id: 'ALL', value: 'ALL'} as Selection)
    const selectedSubCategory = ref({text: 'All', id: 'ALL', value: 'ALL'} as Selection)
    const categorySelection = ref([] as Selection[])
    const subCategorySelection = ref([] as Selection[])
    const showOrderTemplateModal = ref(false)

    onBeforeMount(async () => {
        if (!props.orderDetails) return
        if (props.orderDate) orderDate.value = props.orderDate
        if (props.invoiceDate) invoiceDate.value = props.invoiceDate

        await preloadData(props.orderDetails)
        setCategory()
    })

    const preloadData = async(orderDetails: OrderDetails) => {
        if (!orderDetails) return

        await preloadProduct(orderDetails)

        if (props.userPreference && props.userPreference.orderProductView === 'CARD' && orderProductsCardsRef.value) {
            orderProductsCardsRef.value.preloadOrderItems(orderDetails, props.step === 0)
        } else {
            orderProductsBlockRef.value.preloadOrderItems(orderDetails)
        }
    }

    const submitForm = async (orderItems: OrderItem[]) => {
        emits('submit', { orderItems, orderDate: orderDate.value, invoiceDate: invoiceDate.value })
    }

    const goToPrevious = () => {
        emits('back')
    }

    const preloadProduct = async (orderDetails?: OrderDetails) => {
        showLoading()
        allProductSelections.value = []

        products.value = await productService.findAllProducts()

        if (orderDetails && orderDetails.orderItems && orderDetails.orderItems.length) {
            for (let orderItem of orderDetails.orderItems) {
                if (orderItem.availableQty || orderItem.availableQty == 0)  {
                    let product = products.value.find(p => p.productId === orderItem.productId)
                    if (product) {
                        product.availableQty = orderItem.availableQty
                    }
                }
            }
        }

        allProductSelections.value.push(...Utils.getProductsForSelection(products.value, props.controller?.onlyAvailableProducts, 
            false, props.controller?.showAvailableText))

        if (props.userPreference && props.userPreference.orderProductView === 'CARD' && orderProductsCardsRef.value) {
            orderProductsCardsRef.value.intialOrderItems(products.value)
        } else {
            orderProductsBlockRef.value?.intialOrderItems(allProductSelections.value, false)
        }

        hideLoading()
    }

    const setCategory = async() => {
        categorySelection.value = [{text: 'All', id: 'ALL', value: 'ALL'} as Selection]
        subCategorySelection.value = [{text: 'All', id: 'ALL', value: 'ALL'} as Selection]

        categorySelection.value.push(...await categoryService.getCategorySelectionFromProducts(products.value))
    }

    const orderTemplateSelected = async () => {
        showLoading()
        
        const orderTemplate: OrderTemplateDTO = selectedOrderTemplate.value.optional.obj
        await preloadSubWarehouse(orderTemplate.subWarehouseId, { subWarehouseId: orderTemplate.subWarehouseId, orderItems: orderTemplate.orderItems })

        hideLoading()
    }

    const processFilter = () => {
        if (props.userPreference && props.userPreference.orderProductView === 'CARD' && orderProductsCardsRef.value) {
            const filteredProducts = products.value.filter(p => isQualifyFilter(p.category?.id, p.subCategory?.id))
            orderProductsCardsRef.value.intialOrderItems(filteredProducts)
            orderProductsCardsRef.value.resetCurrentPage()
        } else {
            const filteredProducts: Selection[] = allProductSelections.value.filter(p => isQualifyFilter(p.optional.category?.id, p.optional.subCategory?.id))
            orderProductsBlockRef.value?.intialOrderItems(filteredProducts, true)
        }
    }

    const isQualifyFilter = (categoryId?: string, subCategoryId?: string) => {
        let categoryQualify = false
        let subCategoryQualify = false

        if (selectedCategory.value.value === 'ALL') {
            categoryQualify = true
        } else {
            categoryQualify = selectedCategory.value.value === categoryId
        }

        if (selectedSubCategory.value.value === 'ALL') {
            subCategoryQualify = true
        } else {
            subCategoryQualify = selectedSubCategory.value.value === subCategoryId
        }

        return categoryQualify && subCategoryQualify
    }

    const handleCategorySelected = () => {
        subCategorySelection.value = [{text: 'All', id: 'ALL', value: 'ALL'} as Selection]
        selectedSubCategory.value = {text: 'All', id: 'ALL', value: 'ALL'} as Selection

        if (selectedCategory.value.optional && selectedCategory.value.optional.subCategories) {
            subCategorySelection.value.push(...selectedCategory.value.optional.subCategories)
        }

        processFilter()
    }
</script>