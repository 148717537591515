import axios from 'axios'

import type OrderDetails from '@/types/OrderDetails'
import type OrderItem from '@/types/OrderItem'
import type { UpdateOrderRequest, SaveOrderRequest, SaveManualOrderRequest, ValidateOrderRequest, OrderQueryResponse, OrderQueryRequest } from '@/models/warems.models'

import APIUtil from '@/utils/api-util'

const orderService = {
    async findOrder(orderId: string, includeProduct?: boolean, excludeBooked?: boolean): Promise<OrderDetails> {
        let url = `${import.meta.env.VITE_APP_API_BASE}/order?orderId=${encodeURIComponent(orderId)}`
        if (includeProduct) url += ('&includeProduct=true')
        if (excludeBooked) url += ('&excludeBooked=true')
        return (await axios.get(url)).data.obj;
    },
    outboundCompleteOrder(req: any, pageType: string) {
        return axios.post(`${import.meta.env.VITE_APP_API_BASE}/order/${pageType}`, req);
    },
    async addOrder(saveOrderRequest: SaveOrderRequest) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/order`, saveOrderRequest)
    },
    processOrder(orderId: string, orderItemList: any) {
        return axios.post(`${import.meta.env.VITE_APP_API_BASE}/order/process`, { orderId: orderId, orderItemList })
    },
    async getAllOrders() {
        return await axios.get(`${import.meta.env.VITE_APP_API_BASE}/order`)
    },
    async cancelOrder(orderId: string, addBack: boolean) {
        return await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/order/cancel?id=${encodeURIComponent(orderId)}&addBack=${addBack}`)
    },
    async deleteOrder(orderId: string) {
        const res = await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/order?id=${encodeURIComponent(orderId)}`)
        return res?.data.obj
    },
    async updateOrder(updateOrderRequest:UpdateOrderRequest):Promise<Boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/order`, updateOrderRequest)
        return res?.data.obj
    },
    async directOrderComplete(orderId: string):Promise<Boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/order/direct-complete?id=${encodeURIComponent(orderId)}`)
        return res?.data.obj
    },
    async addManualOrder(saveManualOrderRequest:SaveManualOrderRequest) {
        await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/order/manual`, saveManualOrderRequest)
    },
    async validateOrder(validateOrderRequest: ValidateOrderRequest):Promise<OrderItem[]> {
        const res = await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/order/validate`, validateOrderRequest)
        return res?.data.list
    },
    async downloadOrderSheets(orderIds: string[]) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/order/download`, { orderIds }, { responseType: 'blob' })
    },
    async getPendingOrderCount():Promise<number> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/order/pending-count`)
        return res?.data.obj
    },
    async getOrders(payload: OrderQueryRequest):Promise<OrderQueryResponse> {
        const res = await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/order/query`, payload)
        return res?.data
    }
};

export default orderService;