<template>
    <form @submit.prevent="submit" class="mt-3 va-spacing-y-3">
        <div class="row">
            <va-input label="Email (Username)" v-model="form.username" name="Email" type="email" minlength="3" required/>
        </div>
        <div class="row">
            <va-value v-slot="isPasswordVisible" :default-value="false">
                <va-input label="Password" v-model="form.password" :type="isPasswordVisible.value ? 'text' : 'password'" name="Password" minlength="5" 
                    ref="pwdRef" required @click-append-inner="isPasswordVisible.value = !isPasswordVisible.value">
                    <template #appendInner>
                        <va-icon
                            :name="isPasswordVisible.value ? 'visibility_off' : 'visibility'"
                            size="small"
                            color="primary"
                        />
                    </template>
                </va-input>
            </va-value>
        </div>
        <div class="row" style="justify-content: space-between;margin-top: 30px;">
            <va-button type="submit">Submit</va-button>
            <va-button preset="secondary" @click="goToForgetPwdPage" v-if="!props.noForgetPwd">Forgot Password?</va-button>
        </div>
    </form>
</template>
<script setup lang="ts">
    import { reactive, ref } from 'vue'
    import { useModal } from 'vuestic-ui';

    import authService from '@/services/auth-service'

    const props = defineProps({
        noForgetPwd: Boolean
    })
    const form = reactive({
        username: '',
        password: '',
    })
    const { confirm } = useModal()
    const emits = defineEmits(['ok'])
    const pwdRef = ref()

    const submit = async() => {
        pwdRef.value.blur()

        try {
            localStorage.setItem('jwttoken', (await authService.login(form)).data.token)
            emits('ok')
        } catch(error) {
            confirm({ message: error, cancelText: '', noDismiss: true })
        }
    }

    const goToForgetPwdPage = () => {
        window.location.href = '/forget-pwd'
    }
</script>