<template>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center; padding: 40px 0 20px 0;">
        <form @submit.prevent="resetPwd" class="mt-3 va-spacing-y-3">
            <div class="row">
                <va-input label="New Password" v-model="form.password" type="password" name="Password" minlength="5" required/>
            </div>
            <div class="row">
                <va-button type="submit" :disabled="!form.password || form.password.length <= 2 || !form.token">Submit</va-button>
            </div>
        </form>
    </div>
</template>
<script setup lang="ts">
    import { reactive } from 'vue'
    import { useRoute } from 'vue-router';
    
    import authService from '@/services/auth-service'

    const route = useRoute()
    const form = reactive({
        password: '',
        token: route.query.token
    });

    const resetPwd = async() => {
        if (!route.query || !route.query.token) return

        if (await authService.resetPwd(form.password, form.token)) {
            window.alert("Reset Password success")
            window.location.href = '/login'
        }
    }
</script>