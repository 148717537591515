<template>
    <div>
        <div class="row va-spacing-x-4 va-spacing-y-3 mt-3" v-for="(item, index) in props.resBody" :key="index">
            <va-alert style="width: 100%;">
                {{ item.receiptItem.nameOnBox }} - {{ item.receiptItem.quantity }} Box
            </va-alert>

            <div v-for="(box, boxIdx) in item.boxes" :key="index+'-'+boxIdx">
                <va-chip color="success" class="mt-1 mb-3">Box {{ boxIdx + 1 }} </va-chip>
                <QRCodePrintView :qrCodeList="box.qrCodes" v-if="box.qrCodes.length > 0"/>
            </div>
        </div>
    </div>
    <div class="row va-spacing-x-3 mt-5" style="justify-content: center;">
        <QRCodePrintButton :qrCodeList="qrCodeList"/>
        <va-button @click="completeInbound">DONE</va-button>
    </div>
</template>
<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { PropType } from 'vue'
    import type InboundItem from '@/types/InboundItem'
    import type QRCodeInfo from '@/types/QRCodeInfo'

    import QRCodePrintView from '@/components/qrcode/QRCodePrintView.vue'
    import QRCodePrintButton from '@/components/qrcode/QRCodePrintButton.vue'

    const { confirm } = useModal()
    const props = defineProps({
        resBody: Array as PropType<InboundItem[]>
    })
    const qrCodeList = ref([] as QRCodeInfo[])

    onMounted(() => {
        if (props.resBody && props.resBody.length) {
            for (let inboundItemResponse of props.resBody) {
                if (!inboundItemResponse.boxes || !inboundItemResponse.boxes.length) continue

                for (let deliveryReceiptBox of inboundItemResponse.boxes) {
                    if (!deliveryReceiptBox.qrCodes) continue

                    qrCodeList.value.push(...deliveryReceiptBox.qrCodes)
                }
            }
        }
    })

    // Methods
    const completeInbound = () => {
        confirm({message: 'Are you sure you finished Inbound?', noDismiss: true}).then((ok) => {
            if (ok) {
                window.location.href = '/in/delivery-receipt'
            }
        })
    }
</script>