import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type Selection from '@/types/Selection'
import type { SubWarehouseDTO } from '@/models/warems.models'

const warehouseService = {
    async findAllSubWarehouse():Promise<SubWarehouseDTO[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/warehouse`)
        return res?.data.list
    },
    async findAllSubWarehouseSelection(): Promise<Selection[]> {
        const subWarehouseSelection: Selection[] = []
        const subWarehouses = await this.findAllSubWarehouse()

        subWarehouses.forEach((subWarehouse: SubWarehouseDTO) => {
            subWarehouseSelection.push({
                text: subWarehouse.name,
                value: subWarehouse.id
            } as Selection)
        })

        return subWarehouseSelection
    },
    async addSubWarehouse(subwarehouse: SubWarehouseDTO):Promise<Boolean> {
        const res = await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/warehouse`, subwarehouse)
        return res?.data.obj
    },
    async updateSubWarehouse(subwarehouse: SubWarehouseDTO):Promise<Boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/warehouse`, subwarehouse)
        return res?.data.obj
    },
    async deleteSubWarehouse(subwarehouse: SubWarehouseDTO):Promise<Boolean> {
        const res = await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/warehouse?id=${encodeURIComponent(subwarehouse.id)}`)
        return res?.data.obj
    }
}

export default warehouseService