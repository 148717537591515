import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type { BrandDTO } from '@/models/warems.models'
import type Selection from '@/types/Selection'

const brandService = {
    async getAllBrands():Promise<BrandDTO[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/brand`)
        return res?.data.list
    },
    async getAllBrandSelection():Promise<Selection[]> {
        const brandSelection: Selection[] = []
        const brands = await this.getAllBrands()

        brands.forEach((brand: BrandDTO) => {
            brandSelection.push({
                text: brand.name,
                value: brand.id
            } as Selection)
        })

        return brandSelection
    },
    async addBrand(brand: BrandDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/brand`, brand)
        return res?.data
    },
    async updateBrand(brand: BrandDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/brand`, brand)
        return res?.data
    },
    async deleteBrand(brand: BrandDTO):Promise<boolean> {
        const res = await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/brand?id=${encodeURIComponent(brand.id)}`)
        return res?.data
    }
};

export default brandService;