<template>
    <va-card>
        <va-card-content>
            <va-select class="mb-5" v-model="selectedSubWarehouse" :options="subWarehouseSelection" label="Warehouse" 
                @update:modelValue="subWarehouseSelected"/>

            <template v-if="!loading && allProductSelections.length">
                <AddInventoryForm @submit-res="handleFormSubmitRes" v-if="!success" :controller="controller" :inventories="productsFromCache"
                    :allProductSelections="allProductSelections" @on-changed="handleOnChanged" @reset="reset" ref="addInventoryForm"/>
                <AddInventorySuccessView v-else :resBody="addInvRes"/>
            </template>
        </va-card-content>
    </va-card>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import type OrderController from '@/types/OrderController'
    import type Inventory from '@/types/Inventory'
    import type Selection from '@/types/Selection'

    import AddInventoryForm from './AddInventoryForm.vue'
    import AddInventorySuccessView from './AddInventorySuccessView.vue'
    import inventoryService from '@/services/inventory-service'
    import warehouseService from '@/services/warehouse-service'
    import productService from '@/services/product-service'
    import Utils from '@/utils/utils'

    const addInventoryForm = ref()
    const addInvRes = ref([])
    const success = ref(false)
    const controller = ref({
        confirmWindow: {
            show: true,
            text: 'Are you sure you want to add those inventories?'
        },
        showReset: true
    } as OrderController)
    const productsFromCache = ref([] as Inventory[])
    const selectedSubWarehouse = ref()
    const subWarehouseSelection = ref([] as Selection[])
    const allProductSelections = ref([] as Selection[])
    const loading = ref(true)

    onBeforeMount(async() => {
        subWarehouseSelection.value = await warehouseService.findAllSubWarehouseSelection()
        selectedSubWarehouse.value = subWarehouseSelection.value[0]
        await subWarehouseSelected()

        const cachedProducts = localStorage.getItem('cached_add_inventory')
        if (cachedProducts) productsFromCache.value = JSON.parse(cachedProducts).products
        loading.value = false
    })

    const handleFormSubmitRes = async(data: any) => {
        const res = await inventoryService.addInventory(data)
        reset()
        addInvRes.value.push(...res.data.list)
        success.value = true
    }

    const handleOnChanged = (data: any) => {
        if (!data || !data.length || (data.length === 1 && !data[0].product)) return
        localStorage.setItem('cached_add_inventory', JSON.stringify({ products: data }))
    }

    const reset = () => {
        localStorage.removeItem('cached_add_inventory')
    }

    const subWarehouseSelected = async() => {
        let products = await productService.findAllProducts(selectedSubWarehouse.value.value)
        allProductSelections.value = Utils.getProductsForSelection(products, false, true, false)
        if (addInventoryForm.value) addInventoryForm.value.loadAllProducts(allProductSelections.value)
    }
</script>