import ApiUtil from '@/utils/api-util'

import type { OrderTemplateDTO } from '@/models/warems.models'

const orderTemplateService = {
    async createOrderTemplate(orderTemplateDTO: OrderTemplateDTO) {
        return await ApiUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/order-template`, orderTemplateDTO)
    },
    async getOrderTemplates(): Promise<OrderTemplateDTO[]> {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/order-template`)
        return res?.data.list
    },
    async getOrderTemplateById(id: string): Promise<OrderTemplateDTO> {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/order-template/query?id=${encodeURIComponent(id)}`)
        return res?.data.obj
    },
    async updateOrderTemplate(orderTemplateDTO: OrderTemplateDTO) {
        return await ApiUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/order-template`, orderTemplateDTO)
    },
    async deleteOrderTemplate(id: string) {
        return await ApiUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/order-template?id=${encodeURIComponent(id)}`)
    },
};

export default orderTemplateService;