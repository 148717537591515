import axios from 'axios'

import type { AxiosResponse } from 'axios'

export default class APIUtil{
    static async sendRequest(method: string, url: string, data?: any):Promise<AxiosResponse | null> {
        try {
            return await axios({ method, url, data: data ? data : null })
        } catch (error: any) {
            let errorMsg = 'An unexpected error occurred.'

            if (error.response && error.response.data.message) {
                errorMsg = error.response.data.message
            } else if (error.response && error.response.data) {
                errorMsg = error.response.data
            }
            
            throw errorMsg
        }
    }
}