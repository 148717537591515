<template>
    <div style="display: flex;justify-content: center; padding: 20px 40px;">
        <div v-if="props.product" class="va-spacing-y-3">
            <div class="va-spacing-y-3">
                <p class="mb-4 va-h5" style="font-weight:bold;">Basic Info</p>
                <div class="va-spacing-x-3">
                    <va-input v-model="props.product.secondaryName" label="secondaryName" required-mark/>
                    <va-input v-model="props.product.tertiaryName" label="tertiaryName" />
                    <va-select style="max-width: 250px;" v-model="props.product.subWarehouseSelection" :options="subWarehouseSelection" label="Warehouse"
                        @update:modelValue="subWarehouseUpdated" required-mark/>
                </div>
                <div class="row va-spacing-x-3">
                    <va-select style="max-width: 250px;" v-model="props.product.categorySelection" :options="currentCategorySelections" label="category 1"
                        @update:modelValue="categoryUpdatedHandler" required-mark/>
                    <va-select style="max-width: 250px;" v-model="props.product.subCategorySelection" :options="currentSubCategorySelections" label="category 2"
                        required-mark/>
                    <va-select style="max-width: 250px;" v-model="props.product.brandSelection" :options="brandSelections" label="Supplier"/>
                </div>
                <div class="row va-spacing-x-3">
                    <va-input v-model="props.product.price" label="price" type="number" step="0.01" required-mark/>
                    <va-input v-model="props.product.wholesalePrice" label="wholesale Price" type="number" step="0.01"/>
                    <va-select style="max-width: 250px;" v-model="hasWeight" :options="hasWeightOptions" label="Has Weight" required-mark :readonly="edit"/>
                </div>
                <div class="va-spacing-x-3">
                    <va-input v-model="props.product.description" label="description" />
                    <va-input v-model="props.product.reorderPoint" label="reorder point" type="number" step="1" min="0"/>
                    <va-input v-model="props.product.location" label="location" />
                </div>
                <div class="row va-spacing-x-3">
                    <va-input v-model="props.product.gtin" label="GTIN"/>
                    <va-input v-model="props.product.code" label="Product Code" />
                </div>
            </div>
            <va-divider class="mt-4"/>
            <div class="mt-4">
                <p class="mb-4 va-h5" style="font-weight:bold;">Available to</p>
                <va-data-table v-model="selectedStoreIds" :items="stores" :columns="storeColumns" select-mode="multiple" 
                    items-track-by="id" height="400px" selectable sticky-header hoverable>
                </va-data-table>
            </div>
            <!-- <va-divider class="mt-4"/> -->
            <!-- <div class="mt-4">
                <p class="mb-4 va-h5" style="font-weight:bold;">Alternative Products</p>
                <va-alert color="info" outline style="max-width: 700px;">
                    Selected:
                    <va-chip v-for="pid in selectedAltProductIds" :key="pid" class="ml-2">
                        {{ products.find(p=>p.productId === pid)?.secondaryName }}
                    </va-chip>
                </va-alert>
                <div class="mt-4">
                    <va-input v-model="filterAltProductKey" placeholder="filter..."/>
                    <va-data-table v-model="selectedAltProductIds" :items="products" :columns="productColumn" :filter="filterAltProductKey"
                        items-track-by="productId" select-mode="multiple" height="400px" selectable sticky-header hoverable>
                    </va-data-table>
                </div>
            </div> -->
            <div class="row mt-5 va-spacing-x-3" style="justify-content: center;">
                <va-button preset="outline" @click="back">Back</va-button>
                <va-button @click="submit" :disabled="disableSubmit">Submit</va-button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'

    import type { PropType } from 'vue'
    import type Product from '@/types/Product'
    import type Selection from '@/types/Selection'
    import type { UserDTO, CategoryDTO } from '@/models/warems.models'

    import warehouseService from '@/services/warehouse-service'
    import userService from '@/services/user-service'
    import categoryService from '@/services/category-service'
    import brandService from '@/services/brand-service'
    // import productService from '@/services/product-service'

    // const filterAltProductKey = ref()
    const emits = defineEmits(['submit', 'back']);
    const stores = ref([] as UserDTO[])
    // const products = ref([] as Product[])
    const selectedStoreIds = ref([] as string[])
    const selectedAltProductIds = ref([] as string[])
    const hasWeight = ref()
    const hasWeightOptions = ref(['Yes', 'No', 'Weighting'])
    const brandSelections = ref([] as Selection[])
    const currentCategorySelections = ref([] as Selection[])
    const currentSubCategorySelections = ref([] as Selection[])
    const storeColumns = [
        {key: 'name'}
    ]
    // const productColumn = [
    //     {key: 'secondaryName'},
    //     {key: 'tertiaryName'},
    // ]
    const allCategories = ref([] as CategoryDTO[])
    let subWarehouseSelection: Selection[]
    let allCategorySelections: Selection[]

    const props = defineProps({
        product: Object as PropType<Product>,
        selectedStoreIds: Object as PropType<string[]>,
        selectedAltProductIds: Object as PropType<string[]>,
        edit: Boolean
    })

    onBeforeMount(async() => {
        subWarehouseSelection = await warehouseService.findAllSubWarehouseSelection()
        stores.value = await userService.getUsersByRole('STORE')
        allCategories.value = await categoryService.getAllCategories()
        allCategorySelections = await categoryService.getAllCategorySelection(allCategories.value)
        brandSelections.value = await brandService.getAllBrandSelection()

        if (props.product?.subWarehouseSelection) {
            // const allProducts = await productService.findAllProducts(props.product.subWarehouseSelection.value)
            // products.value = allProducts.filter(product => product.productId !== props.product?.productId)
        }
        

        if (props.selectedStoreIds && props.selectedStoreIds.length) {
            selectedStoreIds.value.push(...props.selectedStoreIds)
        }
        if (props.product?.hasWeight !== undefined && props.product?.hasWeight !== null) {
            hasWeight.value = props.product?.hasWeight
        }
        if (props.selectedAltProductIds && props.selectedAltProductIds.length) {
            selectedAltProductIds.value.push(...props.selectedAltProductIds)
        }

        subWarehouseUpdated()
    })

    const disableSubmit = computed(() => {
        return !props.product || !props.product.secondaryName || !props.product.subWarehouseSelection ||
            !props.product.categorySelection || !props.product.categorySelection.value || !props.product.price || !hasWeight.value
            || !props.product.subCategorySelection || !props.product.subCategorySelection.value
    })

    const subWarehouseUpdated = async(selectedItem?: any) => {
        if (selectedItem) props.product.categorySelection = undefined
        const selections:Selection[] = []

        for (let category of allCategorySelections) {
            if (category.optional.subWarehouse.id === props.product?.subWarehouseSelection?.value) {
                selections.push(category)
            }
        }
        currentCategorySelections.value = selections

        if (props.product?.subWarehouseSelection) {
            // const allProducts = await productService.findAllProducts(props.product.subWarehouseSelection.value)
            // products.value = allProducts.filter(product => product.productId !== props.product?.productId)
        }
    }

    const submit = () => {
        if (!props.product) return
        const product: Product = props.product

        if (product.subWarehouseSelection) {
            product.subWarehouse = { id : product.subWarehouseSelection.value }
        }
        if (product.categorySelection) {
            product.category = { id : product.categorySelection.value }
        }
        if (product.subCategorySelection && product.subCategorySelection.value) {
            product.subCategory = { id : product.subCategorySelection.value }
        }
        if (product.brandSelection && product.brandSelection.value) {
            product.brand = { id : product.brandSelection.value }
        } else {
            delete product.brand
        }

        product.secondaryName = product.secondaryName.trim()
        product.hasWeight = hasWeight.value

        if (product.tertiaryName) {
            product.tertiaryName = product.tertiaryName.trim()
        }
        if (product.description) {
            product.description = product.description.trim()
        }

        const selectedAltProductsInRequest: Product[] = []
        if (selectedAltProductIds.value.length) {
            for (let i = 0; i < selectedAltProductIds.value.length; i++) {
                selectedAltProductsInRequest.push({ productId: selectedAltProductIds.value[i], sequence: i + 1 } as Product)
            }
        }

        emits('submit', product, selectedStoreIds.value, selectedAltProductsInRequest)
    }

    const back = () => {
        emits('back')
    }

    const categoryUpdatedHandler = (data: any) => {
        currentSubCategorySelections.value = []
        if (props.product) props.product.subCategorySelection = undefined

        for (let category of allCategories.value) {
            if (data.value === category.id) {
                if (!category.subCategories) continue
                const selections:Selection[] = []

                for (let subCategory of category.subCategories) {
                    currentSubCategorySelections.value.push({ id: subCategory.id, value: subCategory.id, text: subCategory.name })
                }
                break
            }
        }
    }
</script>