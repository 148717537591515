import axios from 'axios'

const deliveryReceiptService = {
    async createDeliveryReceipt(data: any) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/delivery-receipt`, data)
    },
    async getAllDeliveryReceipt() {
        return await axios.get(`${import.meta.env.VITE_APP_API_BASE}/delivery-receipt`)
    },
    async getDeliveryReceipt(deliveryReceiptId: string) {
        return await axios.get(`${import.meta.env.VITE_APP_API_BASE}/delivery-receipt?id=${encodeURIComponent(deliveryReceiptId)}`)
    }
};

export default deliveryReceiptService;