<template>
    <div style="margin: 10px 20px 0 20px;display: flex;justify-content: end;">
        <va-button @click="addSubWarehouseModal.show=true" v-if="!Utils.getViewOnlyFromToken()">Add Warehouse</va-button>
    </div>

    <va-card class="row" style="margin: 20px 0;justify-content: center;">
        <va-card-content>
            <va-data-table class="table-crud" :items="subWarehouses" :columns="columns" hoverable>
                <template #cell(actions)="{ rowData }" v-if="!Utils.getViewOnlyFromToken()">
                    <div class="va-spacing-x-3">
                        <va-icon name="edit" @click="openEditWarehouseModal(rowData)" color="primary"/>
                        <va-icon name="delete" @click="deleteWarehouse(rowData)" color="primary" />
                    </div>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>

    <va-modal v-model="addSubWarehouseModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input v-model="addSubWarehouseModal.subWarehouse.name" label="Name"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="addSubWarehouseModal.show=false;addSubWarehouseModal.subWarehouse={}" preset="secondary">Close</va-button>
                <va-button @click="addSubWarehouse(addSubWarehouseModal.subWarehouse)" :disabled="disableAddBtn">Add</va-button>
            </div>
        </div>
    </va-modal>

    <va-modal v-model="editSubWarehouseModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input v-model="editSubWarehouseModal.subWarehouse.name" label="Name"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="editSubWarehouseModal.show=false" preset="secondary">Close</va-button>
                <va-button @click="updateSubWarehouse(editSubWarehouseModal.subWarehouse)">Edit</va-button>
            </div>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { SubWarehouseDTO } from '@/models/warems.models'

    import warehouseService from '@/services/warehouse-service'
    import Utils from '@/utils/utils'

    const { confirm } = useModal()
    const subWarehouses = ref([] as SubWarehouseDTO[])
    const columns = [
        { key: 'name' },
        { key: 'actions' }
    ]
    const addSubWarehouseModal = ref({
        show: false,
        subWarehouse: {} as SubWarehouseDTO
    })
    const editSubWarehouseModal = ref({
        show: false,
        subWarehouse: {} as SubWarehouseDTO
    })

    onBeforeMount(async() => {
        subWarehouses.value = await warehouseService.findAllSubWarehouse()
    })

    const disableAddBtn = computed(() => {
        return !addSubWarehouseModal.value.subWarehouse || !addSubWarehouseModal.value.subWarehouse.name
    })

    const addSubWarehouse = async(subWarehouse: SubWarehouseDTO) => {
        if (await warehouseService.addSubWarehouse(subWarehouse)) {
            confirm({message: 'Warehouse Added!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const updateSubWarehouse = async(subWarehouse: SubWarehouseDTO) => {
        if (await warehouseService.updateSubWarehouse(subWarehouse)) {
            confirm({message: 'Warehouse Updated!', cancelText: ''}).then(()=> window.location.reload())
        }
    }

    const openEditWarehouseModal = (subWarehouse: SubWarehouseDTO) => {
        const editObj = JSON.parse(JSON.stringify(subWarehouse))
        editSubWarehouseModal.value.subWarehouse = editObj
        editSubWarehouseModal.value.show = true
    }

    const deleteWarehouse = (subWarehouse: SubWarehouseDTO) => {
        confirm({message: `Are you sure you want to delete "${subWarehouse.name}" ?`}).then(async(ok) => {
            if (ok) {
                if (await warehouseService.deleteSubWarehouse(subWarehouse)) {
                    confirm({message: 'Warehouse deleted!', cancelText: ''}).then(() => window.location.reload())
                }
            }
        })
    }
</script>