import ApiUtil from '@/utils/api-util'

import type { ActivityLogDTO, WarehouseDTO, ProductDTO, MismatchProduct } from '@/models/warems.models'

const adminService = {
    async getActivityLogs():Promise<ActivityLogDTO[]> {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/admin/activity-log`)
        return res?.data.list
    },
    async deleteAllActivityLogs():Promise<boolean> {
        const res = await ApiUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/admin/activity-log`)
        return res?.data.obj
    },
    async getAllWarehouse():Promise<WarehouseDTO[]> {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/admin/warehouse`)
        return res?.data.list
    },
    async updateWarehouse(warehouse: WarehouseDTO) {
        return await ApiUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/admin/warehouse`, warehouse)
    },
    async getMismatchQuantity():Promise<ProductDTO[]> {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/admin/quantity-mismatch`)
        return res?.data.list
    },
    async getMismatchProducts():Promise<MismatchProduct[]> {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/admin/product-mismatch`)
        return res?.data.list
    }
};

export default adminService;