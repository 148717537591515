import axios from 'axios'
import { Buffer } from 'buffer';

import type Product from '@/types/Product'
import type Selection from '@/types/Selection'
import type RowIdentifier from '@/types/RowIdentifier'

export default class Utils{
    static getExpFromToken(): number {
        return Utils.getDataFromTotken()?.exp
    }

    static getIdFromToken(): string {
        return Utils.getDataFromTotken()?.jti.replace(/=+$/, '')
    }

    static getSubFromToken(): string {
        return Utils.getDataFromTotken()?.sub.replace(/=+$/, '')
    }

    static getRoleFromToken(): string {
        return Utils.getDataFromTotken()?.r.replace(/=+$/, '')
    }

    static getWarehouseNameFromToken(): string {
        return Utils.getDataFromTotken()?.wn.replace(/=+$/, '')
    }

    static getTimezoneFromToken(): string {
        return Utils.getDataFromTotken()?.tz.replace(/=+$/, '')
    }

    static getViewOnlyFromToken(): boolean {
        return Utils.getDataFromTotken()?.v.replace(/=+$/, '') === 'true'
    }

    static getTokenExpDate(): Date {
        return new Date(Utils.getDataFromTotken()?.exp * 1000)
    }

    static getDataFromTotken(): any {
        const jwttoken = localStorage.getItem('jwttoken');

        if (jwttoken) {
            const base64Url = jwttoken.split('.')[1]
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            const jsonPayload = Buffer.from(base64, 'base64').toString('utf-8');
            return JSON.parse(jsonPayload)
        }

        return null
    }

    static isAdminRole(): boolean {
        return ['WAREHOUSE_ADMIN', 'ADMIN'].includes(Utils.getRoleFromToken())
    }

    static isStoreRole(): boolean {
        return ['STORE'].includes(Utils.getRoleFromToken())
    }

    static getProductsForSelection(list: Product[], onlyAvailable: boolean, showBrand: boolean, showAvailableText: boolean): Selection[] {
        const itemOptions = [] as Selection[]

        list.forEach((item: Product) => {
            let name = item.secondaryName

            if (item.tertiaryName) {
                name = item.tertiaryName
            }
            if (showBrand && item.brand) {
                name += ` - ${item.brand.name}`
            }
            if (item.description) {
                name += ` (${item.description})`
            }

            let itemOption:Selection = {} as Selection

            if (onlyAvailable) {
                if (item.hasWeight === 'Weighting' || item.availableQty && item.availableQty > 0) {
                    if (item.hasWeight === 'Weighting' && (!item.totalWeight || item.totalWeight <= 0)) {
                        // pass
                    } else {
                        name += (' - ' + item.availableQty + ' available')
                        itemOption = { text: name, value: item.productId, optional: {
                            hasWeight: item.hasWeight, availableQty: item.availableQty, price: item.price, wholesalePrice: item.wholesalePrice,
                            subwarehouse: item.subWarehouse?.name, totalWeight: item.totalWeight
                        }} as Selection
                    }
                }
            } else {
                if (showAvailableText) {
                    if (item.hasWeight === 'Weighting') {
                        name += (' - ' + (item.totalWeight ? item.totalWeight : 0) + ' available')
                    } else {
                        name += (' - ' + (item.availableQty ? item.availableQty : 0) + ' available')
                    }
                }
                itemOption = { text: name, value: item.productId, optional: { 
                    hasWeight: item.hasWeight, availableQty: item.availableQty, price: item.price, wholesalePrice: item.wholesalePrice,
                    subwarehouse: item.subWarehouse?.name, totalWeight: item.totalWeight
                }} as Selection
            }

            itemOption.optional.category = { id: item.category?.id }
            itemOption.optional.subCategory = { id: item.subCategory?.id }
            itemOption.optional.brand = { id: item.brand?.id }

            itemOptions.push(itemOption)
        });

        return itemOptions
    }

    static getProductName(product: Product): string {
        let name = product.secondaryName
        if (product.tertiaryName) {
            name += (product.tertiaryName)
        }
        return name
    }

    static goToElement(id: string) {
        setTimeout(() => {
            const element = document.getElementById(id)
            if (element) {
                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                })
                element.style.backgroundColor = 'rgba(21,78,193,0.3)'
            }
        }, 0)
        Utils.setPreselectRow(null, null)
    }

    static setPreselectRow(id: any, currentPage: any) {
        if (!id || !currentPage) {
            localStorage.removeItem('currentOrderRow')
        } else {
            localStorage.setItem('currentOrderRow', JSON.stringify({ id, currentPage } as RowIdentifier))
        }
    }

    static getPreselectRow(): RowIdentifier | null {
        const currentOrderRowStr = localStorage.getItem('currentOrderRow')
        if (currentOrderRowStr) {
            return JSON.parse(currentOrderRowStr)
        }
        return null
    }

    static showPrintOrderByStatus(status:string) {
        return status !== 'CANCELLED'
    }

    static openErrorModal(confirm: any, hideLoading: any, msg?: string) {
        hideLoading()
        confirm({ message: msg ? msg : 'Error: Technical error occured. Please contact IT support.', cancelText: '', noDismiss: true})
    }

    static formatDateOnDateTable(date: Date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    }

    static parseDateOnDateTable(text: string) {
        const [month, day, year] = text.split("/")
        return new Date(year, month - 1, day)
    }

    static isHasWeight(hasWeight: string) {
        return hasWeight === 'Yes' || hasWeight === 'Weighting'
    }
}