<template>
    <div style="margin: 10px 0 20px 10px">
        <va-chip color="success" v-if="clientInvoicesResponse.invoices">
            {{ clientInvoicesResponse.invoices[0].clientName }}
        </va-chip>
    </div>

    <va-data-table v-model="selectedInvoices" :items="clientInvoicesResponse.invoices" :columns="columns" select-mode="multiple" selectable sticky-header hoverable>
        <template #cell(total)="{ rowData }">
            {{ rowData.formattedTotal }}
        </template>
        <template #cell(paid)="{ rowData }">
            {{ rowData.formattedPaid }}
        </template>
        <template #cell(owed)="{ rowData }">
            {{ rowData.formattedOwed }}
        </template>
        <template #cell(balanceDue)="{ rowData }">
            {{ rowData.formattedBalanceDue }}
        </template>
        <template #cell(invoiceDate)="{ rowData }">
            {{ rowData.formattedInvoiceDate }}
        </template>
        <template #cell(relatedTo)="{ rowData }">
            {{ rowData.invoiceId.startsWith('RI') ? 'Return #' + rowData.relatedDisplayId : 'Order #' + rowData.relatedDisplayId }}
        </template>
        <template #cell(invoice)="{ rowIndex }">
            <div class="va-spacing-x-3">
                <va-button preset="plain" icon="visibility" @click="openInvoiceModal(rowIndex)"/>
                <va-button preset="plain" icon="download" @click="downloadInvoice(rowIndex)"/>
            </div>
        </template>
    </va-data-table>

    <div class="row mt-5 va-spacing-x-3" style="justify-content: center;">
        <va-button @click="back">Back</va-button>
        <va-button v-if="AccessUtil.hasInvoiceGenerationAccess()" color="success" :disabled="disableMarkAsPaid" @click="paySelectedInvoices">
            Mark Selected as Paid
        </va-button>
        <va-button :disabled="disableMultiSelection" @click="printInvoices">
            Print Selected Invoices
        </va-button>
    </div>
    
    <va-modal v-model="invoiceModal.show" no-outside-dismiss hideDefaultActions no-padding close-button>
        <InvoiceModalContent v-if="invoiceModal.show && clientInvoicesResponse.invoices" :invoice="invoiceModal.invoice" @close="invoiceModal.show = false" 
            :isFirst="invoiceModal.rowIndex === 0" :isLast="invoiceModal.rowIndex === clientInvoicesResponse.invoices?.length-1" 
            @before="goToInvoice(-1)" @next="goToInvoice(1)" enable-switch/>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { useModal } from 'vuestic-ui'

    import InvoiceModalContent from '@/components/modal/InvoiceModalContent.vue'
    import { useLoadingStore } from '@/stores/loading-store'
    import AccessUtil from '@/utils/access'
    import invoiceService from '@/services/invoice-service'

    import type { ClientInvoicesResponse, InvoiceDTO } from '@/models/warems.models'

    const { confirm } = useModal()
    const route = useRoute()
    const clientInvoicesResponse = ref({} as ClientInvoicesResponse)
    const columns = [
      { key: "invoiceId" },
      { key: "invoiceDate" },
      { key: "relatedTo" },
      { key: "total" },
      { key: "paid" },
      { key: "balanceDue" },
      { key: "invoice"}
    ];
    const invoiceModal = ref({
        show: false,
        invoice: {} as InvoiceDTO,
        rowIndex: 0
    })
    const { showLoading, hideLoading } = useLoadingStore()
    const selectedInvoices = ref([] as InvoiceDTO[])
    const disableMarkAsPaid = computed(() => {
        if (selectedInvoices.value.length === 0) return true

        for (let invoice of selectedInvoices.value) {
            if (invoice.paid === 0) return false
        }

        return true
    })
    const disableMultiSelection = computed(() => {
        return selectedInvoices.value.length === 0
    })

    onBeforeMount(async() => {
        showLoading()
        const res = await invoiceService.getInvoicesByTimeRange(route.query.userId, route.query.start, route.query.end)

        if (res.data) {
            let resBody: ClientInvoicesResponse = res.data.obj
            resBody.invoices.forEach(invoice => {
                invoice.billAddress = resBody.billAddress
                invoice.shipAddress = resBody.shipAddress
            })

            clientInvoicesResponse.value = resBody
        }
        hideLoading()
    })

    // Methods
    const openInvoiceModal = (rowIdx: number) => {
        invoiceModal.value.invoice = clientInvoicesResponse.value.invoices[rowIdx]
        invoiceModal.value.show = true
        invoiceModal.value.rowIndex = rowIdx
    }

    const downloadInvoice = async (rowIdx: number) => {
        showLoading()
        let response = await invoiceService.downloadInvoices([clientInvoicesResponse.value.invoices[rowIdx].invoiceIdQuery])

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(blob)

        const downloadLink = document.createElement('a')
        downloadLink.href = fileURL
        
        downloadLink.download = clientInvoicesResponse.value.invoices[rowIdx].invoiceId + '.pdf'
        
        document.body.appendChild(downloadLink)
        downloadLink.click();
        document.body.removeChild(downloadLink)

        URL.revokeObjectURL(fileURL)
        hideLoading()
    }

    const back = () => {
        let url = `/in/report/statement?start=${route.query.start}&end=${route.query.end}`
        if (route.query.preset) {
            url += (`&preset=${route.query.preset}`)
        }
        window.location.href = url
    }

    const goToInvoice = (changes: number) => {
        openInvoiceModal(invoiceModal.value.rowIndex + changes)
    }

    const paySelectedInvoices = async() => {
        const invoiceIds = selectedInvoices.value.filter(invoice => invoice.balanceDue && invoice.balanceDue > 0).map(invoice => invoice.invoiceIdQuery)
        if (invoiceIds.length > 0) {
            confirm({message: 'Are you sure you want to mark those invoices as Paid?'}).then(async(ok) => {
                if (ok) {
                    await invoiceService.invoicePaid({invoiceIds: invoiceIds})
                    window.location.reload()
                }
            })
        }
    }

    const printInvoices = async() => {
        showLoading()
        const invoiceIds = selectedInvoices.value.map(invoice => invoice.invoiceIdQuery)
        let response = await invoiceService.downloadInvoices(invoiceIds)

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(blob)

        const downloadLink = document.createElement('a')
        downloadLink.href = fileURL
        
        downloadLink.download = 'invoices.pdf'
        
        document.body.appendChild(downloadLink)
        downloadLink.click();
        document.body.removeChild(downloadLink)

        URL.revokeObjectURL(fileURL)
        hideLoading()
    }
</script>