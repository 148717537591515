<template>
    <va-stepper v-model="step" :steps="steps" controlsHidden :navigationDisabled="true">
        <template #step-content-0>
            <va-card>
                <va-card-content>
                    <InboundAddDeliveryReceipt @next="step = 1" :inbound-items="inboundItems" :allProducts="allProducts"/>
                </va-card-content>
            </va-card>
        </template>
        <template #step-content-1>
            <va-card>
                <va-card-content>
                    <InboundAddInventory :inbound-items="inboundItems" @next="step = 2" @previous="step = 0" :allProducts="allProducts" nextBtnText="Next"/>
                </va-card-content>
            </va-card>
        </template>
        <template #step-content-2>
            <va-card>
                <va-card-content>
                    <InboundAddInventory :inbound-items="inboundItems" @next="submitInboundRequest" @previous="step = 1" :read-only="true" nextBtnText="Confirm"/>
                </va-card-content>
            </va-card>
        </template>
        <template #step-content-3>
            <va-card>
                <va-card-content>
                    <InboundSuccessView :resBody="inboundRes" />
                </va-card-content>
            </va-card>
        </template>
    </va-stepper>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import { useRoute } from 'vue-router'
    import { v4 as uuidv4 } from 'uuid'
    import { useModal } from 'vuestic-ui'
    
    import InboundAddDeliveryReceipt from './InboundAddDeliveryReceipt.vue'
    import InboundAddInventory from './InboundAddInventory.vue'
    import InboundSuccessView from './InboundSuccessView.vue'
    import productService from '@/services/product-service'
    import deliveryReceiptService from '@/services/delivery-receipt-service'
    import inventorySevice from '@/services/inventory-service'
    import Utils from '@/utils/utils'

    import type InboundItem from '@/types/InboundItem'
    import type DeliveryReceiptBox from '@/types/DeliveryReceiptBox'
    import type QRCodeInfo from '@/types/QRCodeInfo'
    import type Inventory from '@/types/Inventory'
    import type Selection from '@/types/Selection'
    import type DeliveryReceipt from '@/types/DeliveryReceipt'
    import type { AxiosResponse, AxiosError } from 'axios'

    const { confirm } = useModal()
    const route = useRoute()
    const allProducts = ref([] as Selection[])
    const step = ref(0)
    const inboundItems = ref([] as InboundItem[])
    const inboundRes = ref([] as InboundItem[])
    const deliveryReceiptId = ref('')

    const steps = [
        { label: 'Finish Delivery Receipt' },
        { label: 'Add Inventory' },
        { label: 'Confirmation' },
        { label: 'Print QR Code' }
    ]

    onBeforeMount(async () => {
        deliveryReceiptId.value = route.query.deliveryReceiptId as string | null;
        let deliveryReceiptRes = await deliveryReceiptService.getDeliveryReceipt(deliveryReceiptId.value)
        
        if (!deliveryReceiptRes || !deliveryReceiptRes.data || !deliveryReceiptRes.data.obj || deliveryReceiptRes.data.obj.status !== 'NEW') {
            window.location.href = '/in/delivery-receipt'
        } else {
            let products = await productService.findAllProducts(null)
            allProducts.value.push(...Utils.getProductsForSelection(products, false, true, false))

            initialDeliveryReceipt(deliveryReceiptRes.data.obj)
        }
    })

    // Methods

    const initialDeliveryReceipt = (deliveryReceipt: DeliveryReceipt) => {
        deliveryReceipt.deliveryReceiptItems.forEach((deliveryReceiptItem, index) => {
            const matchedProduct = allProducts.value.find(entry => entry.value === deliveryReceiptItem.productId)
            const inboundItem = { 
                receiptItem: {
                    deliveryReceiptItemId: deliveryReceiptItem.deliveryReceiptItemId,
                    sequence: index + 1,
                    quantity: deliveryReceiptItem.quantity,
                    product: matchedProduct
                },
                boxes: Array(deliveryReceiptItem.quantity).fill(null).map(() => (
                    { boxId: uuidv4(), inventories: [{ product: matchedProduct } as Inventory] } as DeliveryReceiptBox
                ))
            } as InboundItem

            inboundItems.value.push(inboundItem)
        })
    }

    const submitInboundRequest = () => {
        let inboundItemsCopy: InboundItem[] = JSON.parse(JSON.stringify(inboundItems.value))
        
        inboundItemsCopy.forEach(inboundItem => {
            if (inboundItem.receiptItem.product) {
                delete inboundItem.receiptItem.product
            }

            inboundItem.boxes.forEach(box => {
                box.inventories.forEach(inventory => {
                    inventory.productId = inventory.product.value
                    delete inventory.product
                })
            })
        })

        const data = {
            userId: Utils.getIdFromToken(),
            inboundItems: inboundItemsCopy,
            deliveryReceiptId: deliveryReceiptId.value
        }

        inventorySevice.inboundRequest(data).then((res: AxiosResponse) => {
            if (res.status !== 201) return

            step.value = 3
            let resBody = res.data.obj.inboundItems as InboundItem[]

            resBody.forEach(resBodyItem => {
                resBodyItem.boxes.forEach(box => {
                    let qrCodes = [] as QRCodeInfo[]

                    box.inventories.forEach(inventory => {
                        qrCodes.push({
                            val: inventory.qrValue,
                            text: inventory.qrText,
                            id: inventory.inventoryId
                        })
                    })
                    
                    box.qrCodes = qrCodes
                })
            })

            resBody.sort((a, b) => a.receiptItem.sequence - b.receiptItem.sequence)
            inboundRes.value = resBody
        }, (err: AxiosError) => {
            confirm({ message: err.response?.data?.message, cancelText: '', noDismiss: true})
                .then(() => window.location.href = '/in/delivery-receipt')
        })
    }
</script>