<template>
  <div class="loading-overlay" v-if="isLoading">
    <div class="loading-spinner">
      <va-progress-circle indeterminate />
    </div>
  </div>
</template>
  
<script setup lang="ts">
  import { useLoadingStore } from '@/stores/loading-store'
  const { isLoading } = useLoadingStore()
</script>
  
<style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
</style>
  