<template>
    <ProductFormComponent :product="newProduct" :selectedStoreIds="existSelectedStoreIds" @submit="submitItem" @back="back"/>
</template>
<script setup lang="ts">
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'
    import { useModal } from 'vuestic-ui'
    
    import type Product from '@/types/Product'
    import type { SaveProductRequest } from '@/models/warems.models'

    import productService from '@/services/product-service'
    import ProductFormComponent from './ProductFormComponent.vue'

    const router = useRouter()
    const { confirm } = useModal()
    const newProduct = ref({} as Product)
    const existSelectedStoreIds = ref([] as string[])

    const submitItem = async(product: Product, selectedStoreIds: string[], selectedAltProducts: Product[]) => {
        const saveProductRequest:SaveProductRequest = {
            product,
            storeIds: selectedStoreIds,
            altProducts: selectedAltProducts
        }
        
        try {
            if (await productService.createProduct(saveProductRequest)) {
                confirm({message: 'Product created.', cancelText: ''}).then(() => router.go(-1))
            }
        } catch(err: any) {
            confirm({ message: `Error: ${err}`, cancelText: '' })
        }
    }

    const back = () => {
        router.go(-1)
    }
</script>