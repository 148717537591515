<template>
    <div class="va-spacing-y-3" v-if="props.address">
        <div class="row">
            <va-input :label="name" v-model="props.address.name" :readonly="props.readonly" required-mark/>
        </div>
        <div class="row va-spacing-x-3">
            <va-input label="address 1" v-model="props.address.address1" :readonly="props.readonly" required-mark/>
            <va-input label="address 2" v-model="props.address.address2" :readonly="props.readonly"/>
        </div>
        <div class="row va-spacing-x-3">
            <va-input label="city" v-model="props.address.city" :readonly="props.readonly" required-mark/>
            <va-select label="state" v-model="props.address.state" :options="states" :readonly="props.readonly" style="max-width:80px;" required-mark/>
            <va-input label="zipcode" v-model="props.address.zipCode" :readonly="props.readonly" style="max-width:120px;" required-mark/>
        </div>
    </div>
</template>
<script setup lang="ts">
    import type { PropType } from 'vue'

    import type { ClientAddressDTO } from '@/models/warems.models'

    const props = defineProps({
        name: String,
        address: Object as PropType<ClientAddressDTO>,
        readonly: Boolean
    })

    const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 
                    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 
                    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 
                    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 
                    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']
</script>