<template>
    <div class="mt-2 ml-3 mb-3" style="display: flex; justify-content: space-between;" v-if="AccessUtil.hasOrderPrintAccess()">
        <div class="va-spacing-x-3">
            <va-select label="Stores" v-model="selectedStores" :options="storeSelection" :max-visible-options="5" style="max-width: 500px;" 
                multiple highlight-matched-text/>
            <va-button style="margin-top: 18px" @click="updatePage" :disabled="!selectedStores.length">Filter</va-button>
        </div>
        <va-button class="mt-2 mr-2" @click="printSelectedOrderSheets" :disabled="disablePrintOrders">Print Selected Orders</va-button>
    </div>

    <va-data-table class="customize-data-table" v-model="selectedOrders" :items="orders" :columns="columns" :loading="loading" 
        :row-bind="getRowBind" select-mode="multiple" :selectable="AccessUtil.hasOrderPrintAccess()" hoverable>
        <template #cell(id)="{ rowData }">
            <a v-if="AccessUtil.isAdminGroup()" :href="`/in/order/edit?id=${encodeURIComponent(rowData.orderId)}`">
                {{ rowData.displayId }}
            </a>
            <span v-else>{{ rowData.displayId }}</span>
        </template>
        <template #cell(date)="{ rowData }">
            {{ new Date(rowData.time*1).toLocaleDateString() }}
        </template>
        <template #cell(orderItems)="{ row, isExpanded }">
            <va-button
                @click="row.toggleRowDetails()"
                :icon="isExpanded ? 'va-arrow-up': 'va-arrow-down'"
                preset="secondary"
                class="w-full"
            >
                {{ isExpanded ? 'Hide': 'Details' }}
            </va-button>
        </template>
        <template #expandableRow="{ rowData }">
            <OrderDetailsView :orderItems="rowData.orderItems" show-notes show-weight />
        </template>
        <template #cell(actions)="{ rowData }">
            <div class="va-spacing-x-2">
                <va-button v-if="AccessUtil.hasOrderPrintAccess() && Utils.showPrintOrderByStatus(rowData.status)" @click="printOrder(rowData.orderId)">
                    Print
                </va-button>
                <va-button v-if="AccessUtil.hasOrderProcessingAccess() && rowData.status === 'PENDING'" @click="handleProcessOrder(rowData)">
                    Process
                </va-button>
                <va-button v-if="AccessUtil.hasOutboundAccess() && rowData.status === 'PROCESSING'" @click="handleOutboundRequest(rowData)">
                    Outbound
                </va-button>
                <va-button v-if="AccessUtil.hasOrderConfirmAccess() && rowData.status === 'OUTBOUND'" @click="handleOrderConfirm(rowData.orderId)">
                    Confirm
                </va-button>
                <va-button v-if="AccessUtil.isAdminGroup() && rowData.status === 'OUTBOUND'" @click="directOrderComplete(rowData.orderId)" color="warning">
                    Complete Order
                </va-button>
                <va-button v-if="AccessUtil.hasInvoiceGenerationAccess() && rowData.status === 'DONE' && !rowData.invoiceIds" @click="generateInvoice(rowData)">
                    Generate Invoice
                </va-button>
                <va-button v-if="rowData.status === 'DONE' && rowData.invoiceIds" @click="showInvoice(rowData)" 
                    :color="rowData.paidFull ? 'success' : 'warning'">
                    Invoice
                </va-button>
                <va-button v-if="rowData.status === 'PENDING' || (rowData.historyOrder && rowData.status !== 'CANCELLED' && AccessUtil.isAdminGroup()) || (AccessUtil.isAdminGroup() && rowData.status === 'PROCESSING')" 
                    @click="openCancelOrder(rowData.orderId, rowData.displayId, rowData.historyOrder)" color="danger">
                    Cancel
                </va-button>
                <va-button v-if="AccessUtil.isAdminGroup() && rowData.status === 'CANCELLED'" 
                    @click="handleDeleteOrder(rowData.orderId, rowData.displayId)" color="danger">
                    Delete
                </va-button>
            </div>
        </template>
        <template #bodyAppend>
            <tr>
                <td colspan="12">
                    <div class="flex mt-5">
                        <va-pagination v-model="currentPage" :pages="totalPages" :visible-pages="10" class="justify-center sm:justify-start" 
                            @update:modelValue="updatePage"/>
                    </div>
                </td>
            </tr>
        </template>
    </va-data-table>

    <va-modal v-model="invoiceModal.show" no-outside-dismiss hideDefaultActions no-padding close-button>
        <VaButtonToggle v-model="invoiceModal.selectedInvoiceIdx" preset="secondary" border-color="primary" :options="invoiceModal.options" 
            v-if="invoiceModal.options && invoiceModal.options.length > 1" />
        <InvoiceModalContent :invoice="invoiceModal.invoices[invoiceModal.selectedInvoiceIdx]" :rowIdentifier="invoiceModal.rowIdentifier"/>
    </va-modal>

    <va-modal v-model="cancelOrderModal.show" no-outside-dismiss @close="cancelOrderModal.show=false" @ok="cancelOrder">
        <p class="va-h6">Do you want to cancel order #{{ cancelOrderModal.displayId }} ?</p>
        <va-checkbox v-if="cancelOrderModal.showAddBack" v-model="cancelOrderModal.addback" label="Add products back to System Inventory?" class="mt-4" />
    </va-modal>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useModal } from 'vuestic-ui'
    
    import type OrderDetails from '@/types/OrderDetails'
    import type { InvoiceDTO, OrderQueryRequest } from '@/models/warems.models'
    import type RowIdentifier from '@/types/RowIdentifier'

    import AccessUtil from '@/utils/access'
    import Utils from '@/utils/utils'
    import InvoiceModalContent from '@/components/modal/InvoiceModalContent.vue'
    import orderService from '@/services/order-service'
    import invoiceService from '@/services/invoice-service'
    import userService from '@/services/user-service'
    import { useLoadingStore } from '@/stores/loading-store'
    import OrderDetailsView from '@/components/order/OrderDetailsView.vue'

    const storeSelection = ref([] as Selection[])
    const selectedStores = ref([] as Selection[])
    const disablePrintOrders = computed(() => {
        return selectedOrders.value.length === 0 || selectedOrders.value.filter(o => Utils.showPrintOrderByStatus(o.status)).length === 0
    })
    const currentPage = ref(1)
    const totalPages = ref(1)
    const { confirm } = useModal()
    const invoiceModal = ref({
        show: false,
        invoices: [] as InvoiceDTO[],
        order: {} as OrderDetails,
        rowIdentifier: {} as RowIdentifier,
        options: [] as any,
        selectedInvoiceIdx: 0
    })
    const cancelOrderModal = ref({
        show: false,
        orderId: '',
        displayId: 0,
        addback: false,
        showAddBack: false
    })
    const orders = ref([] as OrderDetails[])
    const selectedOrders = ref([] as OrderDetails[])
    const columns = [
      { key: "id" },
      { key: "date", sortable: true },
      { key: "status" },
      { key: "storeName", sortable: true },
      { key: "orderItems", width: 80 },
      { key: "actions", width: 80 },
    ]
    const loading = ref(true)
    const { showLoading, hideLoading } = useLoadingStore()

    onBeforeMount(async() => {
        if (AccessUtil.hasOrderPrintAccess()) storeSelection.value = await userService.getAllStoreSelection()

        const currentRow = Utils.getPreselectRow()
        if (currentRow) {
            currentPage.value = currentRow.currentPage*1
            await updatePage()
            Utils.goToElement(`order-${currentRow.id}`)
        } else {
            updatePage()
        }
    })

    const updatePage = async() => {
        showLoading()
        const payload: OrderQueryRequest = {
            page: currentPage.value - 1,
            storeIds: selectedStores.value.length ? selectedStores.value.map(s=>s.value) : null
        }
        const res = await orderService.getOrders(payload)
        if (!res) return

        orders.value = []
        totalPages.value = res.totalPages

        if (res.list) orders.value.push(...res.list)

        loading.value = false
        window.scrollTo({ top: 0 })
        hideLoading()
    }
    
    const handleOutboundRequest = (order: OrderDetails) => {
        window.location.href = `/in/order/outbound?orderId=${encodeURIComponent(order.orderId)}&displayId=${order.displayId}&currentPage=${currentPage.value}`
    }

    const handleOrderConfirm = (orderId: string) => {
        window.location.href = ('/in/order/complete?orderId=' + encodeURIComponent(orderId))
    }

    const openCancelOrder = (orderId: string, displayId: number, historyOrder: boolean) => {
        cancelOrderModal.value.show = true
        cancelOrderModal.value.orderId = orderId
        cancelOrderModal.value.displayId = displayId
        cancelOrderModal.value.addback = false
        cancelOrderModal.value.showAddBack = historyOrder
    }

    const handleDeleteOrder = (orderId: string, displayId: number) => {
        confirm({message: `Do you really want to delete order #${displayId} ?`, cancelText: 'No', okText: 'Yes'}).then((ok) => {
            if (ok) deleteOrder(orderId)
        })
    }

    const cancelOrder = async() => {
        showLoading()
        Utils.setPreselectRow(cancelOrderModal.value.displayId, currentPage.value)

        try {
            await orderService.cancelOrder(cancelOrderModal.value.orderId, cancelOrderModal.value.addback)
            window.location.reload()
        } catch(err: any) {
            Utils.openErrorModal(confirm, hideLoading)
        }
    }

    const deleteOrder = async(orderId: string) => {
        showLoading()
        try {
            await orderService.deleteOrder(orderId)
            window.location.reload()
        } catch(err: any) {
            Utils.openErrorModal(confirm, hideLoading)
        }
    }

    const handleProcessOrder = (rowData: any) => {
        window.location.href = (`/in/order/process?orderId=${encodeURIComponent(rowData.orderId)}&displayId=${rowData.displayId}&currentPage=${currentPage.value}`)
    }

    const generateInvoice = (rowData: any) => {
        window.location.href = (`/in/order/invoice?orderId=${encodeURIComponent(rowData.orderId)}&displayId=${rowData.displayId}&currentPage=${currentPage.value}`)
    }

    const showInvoice = async(order: OrderDetails) => {
        invoiceModal.value.invoices = await invoiceService.getInvoice(order.orderId)
        invoiceModal.value.order = order
        invoiceModal.value.show = true
        invoiceModal.value.rowIdentifier = { id: order.displayId, currentPage: currentPage.value }
        invoiceModal.value.selectedInvoiceIdx = 0
        invoiceModal.value.options = []

        for (let i = 0; i < invoiceModal.value.invoices.length; i++) {
            const invoice = invoiceModal.value.invoices[i]
            invoiceModal.value.options.push({ label: invoice.invoiceId, value: i })
        }
    }

    const directOrderComplete = async(orderId: string) => {
        confirm({message: 'Are you sure you want to complete this order?', cancelText: 'No', okText: 'Yes'}).then(async(ok) => {
            if (ok) {
                showLoading()
                if (await orderService.directOrderComplete(orderId)) {
                    window.location.href = '/in/order'
                }
            }
        })
    }

    const getRowBind = (row: any) => {
        return {
          id: 'order-' + row.displayId
        }
    }

    const printOrder = async(orderId: string) => {
        handlePrintOrder([orderId])
    }

    const printSelectedOrderSheets = async() => {
        const orderIds = selectedOrders.value.filter(o => Utils.showPrintOrderByStatus(o.status)).map(o => o.orderId)
        handlePrintOrder(orderIds)
    }

    const handlePrintOrder = async(orderIds:string[]) => {
        showLoading()
        const response = await orderService.downloadOrderSheets(orderIds)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
        hideLoading()
    }
</script>