<template>
    <div class="mt-5 mb-5" style="text-align: center;">
        <div v-if="success" class="va-spacing-y-3">
            <div class="va-h5">
                <va-icon name="check_circle" size="1.5em" color="success"/> Thank you
            </div>
            <p class="mb-5">Your account have been successfully activated.</p>
            <div>
                <va-button @click="goToLogin">Go to Login</va-button>
            </div>
        </div>
        <div v-else>
            <div class="va-h5">
                <va-icon name="cancel" size="1.5em" color="danger"/> Not able to active this account.
            </div>
            <p>Please contact us for more details.</p>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import { useRoute } from 'vue-router'

    import authService from '@/services/auth-service'

    const route = useRoute()
    const token = route.query.token as string
    const success = ref(false)

    onBeforeMount(async() => {
        if (!token) return

        if (await authService.activeAccount(token)) {
            success.value = true
        }
    })

    const goToLogin = () => {
        window.location.href = '/login'
    }
</script>