<template>
    <div class="not-found-block" style="text-align: center; max-height: 250px;">
        <h1 style="font-size: large;">Page not found</h1>
    </div>
</template>
<style>
    .not-found-block {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin: 0;
    }
</style>