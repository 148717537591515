<template>
    <va-card>
        <va-card-title class="card-title">Filter</va-card-title>
        <va-card-content>
            <div class="row va-spacing-x-3">
                <va-select label="Warehouse" v-model="selectedWarehouse" :options="subWarehouseSelection" style="max-width: 150px;"
                    @update:modelValue="subWarehouseSelected"/>
                <va-select label="Category" v-model="selectedCategory" :options="categorySelection" style="max-width: 200px;"
                    @update:modelValue="updateProductSelection"/>
                <va-select label="Products" v-model="selectedProducts" :options="filteredProductsSelection" :max-visible-options="5" 
                    multiple searchable highlight-matched-text  @update:modelValue="resetProductUsageReports" :disabled="allProductChecker"/>
                <va-select label="Stores" v-model="selectedStores" :options="storeSelection" :max-visible-options="5" style="max-width: 200px;" 
                    multiple highlight-matched-text  @update:modelValue="resetProductUsageReports" :disabled="allStoresChecker"/>
                <va-date-input style="max-width: 210px;" v-model="filterDateRange" :format-date="Utils.formatDateOnDateTable" 
                    :parse-date="Utils.parseDateOnDateTable" label="Date Range"/>
            </div>
            <div class="row va-spacing-x-3 mt-3">
                <va-checkbox label="All Products" v-model="allProductChecker" @update:modelValue="allProductsChecked" :disabled="!selectedWarehouse.text || !selectedCategory.text"/>
                <va-checkbox label="All Stores" v-model="allStoresChecker" @update:modelValue="allStoresChecked" :disabled="!selectedWarehouse.text || !selectedCategory.text"/>
            </div>
            <div class="row va-spacing-x-3 mt-3" style="display: flex; justify-content: center;">
                <va-button style="margin-top: 1em;" @click="generateProductUsageReport" :disabled="disableSubmit">Generate Report</va-button>
                <va-button preset="outline" style="margin-top: 1em;" @click="resetFilter">Reset</va-button>
            </div>
        </va-card-content>
    </va-card>

    <div class="va-table-responsive mt-5" v-if="productUsageReports.length">
        <table class="va-table va-table--hoverable" style="width: 100%;">
            <thead>
                <tr class="table-header-default">
                    <th></th>
                    <th v-for="selection of selectedStores">{{ selection.text }}</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(report, idx) in productUsageReports" :key="idx">
                    <td>{{ report.productName }}</td>
                    <td v-for="selection of selectedStores" style="text-wrap: nowrap;">
                        {{ populateStoreValue(selection, report) }}
                    </td>
                    <td>
                        {{ report.hasWeight ? `${report.totalWeight} lb` : report.totalQuantity }}
                        {{ report.totalWholesalePrice ? ` / ${report.totalWholesalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}` : '' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-else style="display: flex; justify-content: center;" class="mt-5 mb-3">
        <strong>No records found</strong>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'

    import type Selection from '@/types/Selection'
    import type { ProductUsageReportRequest, ProductUsageReport } from '@/models/warems.models'

    import userService from '@/services/user-service'
    import productService from '@/services/product-service'
    import reportService from '@/services/report-service'
    import warehouseService from '@/services/warehouse-service'
    import Utils from '@/utils/utils'
    import { useLoadingStore } from '@/stores/loading-store'

    const storeSelection = ref([] as Selection[])
    const allProductsSelection = ref([] as Selection[])
    const filteredProductsSelection = ref([] as Selection[])
    const subWarehouseSelection = ref([] as Selection[])
    const categorySelection = ref([] as Selection[])
    const selectedStores = ref([] as Selection[])
    const selectedProducts = ref([] as Selection[])
    const selectedWarehouse = ref({} as Selection)
    const selectedCategory = ref({} as Selection)
    const filterDateRange = ref({ start: {} as Date, end: {} as Date, preset: null as string|null })
    const productUsageReports = ref([] as ProductUsageReport[])
    const { showLoading, hideLoading } = useLoadingStore()
    const allProductChecker = ref(false)
    const allStoresChecker = ref(false)

    onBeforeMount(async() => {
        resetFilter()

        subWarehouseSelection.value = await warehouseService.findAllSubWarehouseSelection()
        storeSelection.value = await userService.getAllStoreSelection()
    })

    const disableSubmit = computed(() => {
        return !selectedProducts.value.length || !selectedStores.value.length
    })

    const resetFilter = () => {
        let today = new Date();
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0)
        filterDateRange.value = { start: firstDayOfMonth, end: new Date(), preset: 'current' }

        selectedStores.value = [] as Selection[]
        selectedProducts.value = [] as Selection[]
        selectedWarehouse.value = {} as Selection
        selectedCategory.value = {} as Selection

        categorySelection.value = [{text: 'All', id: 'ALL'} as Selection]
        resetProductUsageReports()
    }

    const generateProductUsageReport = async() => {
        showLoading()

        const request: ProductUsageReportRequest = {
            storeIds: selectedStores.value.map(i => i.value),
            productIds: selectedProducts.value.map(i => i.value),
            start: filterDateRange.value.start.getTime(),
            end: filterDateRange.value.end.getTime(),
        }

        productUsageReports.value = await reportService.getProductUsageReport(request)

        hideLoading()
    }

    const populateStoreValue = (currentStore: Selection, report: ProductUsageReport) => {
        if (!report.stores) return '-'

        for (let store of report.stores) {
            if (store.storeId === currentStore.value) {
                let result = store.weight ? `${store.weight} lb` : store.quantity
                if (store.totalWholesalePrice) result += ` / ${store.totalWholesalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}`
                return result
            }
        }

        return '-'
    }

    const subWarehouseSelected = async () => {
        showLoading()

        const seenCategory: string[] = []
        const products  = await productService.findAllProducts(selectedWarehouse.value.value)
        allProductsSelection.value.push(...Utils.getProductsForSelection(products, false, false, false))

        products.forEach(product => {
            if (product.category && product.category.id && !seenCategory.includes(product.category.id)) {
                seenCategory.push(product.category.id)
                categorySelection.value.push({text: product.category.name, id: product.category.id})
            }
        })

        reset()
        hideLoading()
    }

    const updateProductSelection = () => {
        reset()
        filteredProductsSelection.value = allProductsSelection.value.filter(p => isQualifyFilter(p.optional.category?.id))
    }

    const reset = () => {
        selectedProducts.value = []
        selectedStores.value = []
        allStoresChecker.value = false
        allProductChecker.value = false
    }

    const isQualifyFilter = (categoryId?: string) => {
        if (selectedCategory.value.id === 'ALL') {
            return true
        } else {
            return selectedCategory.value.id === categoryId
        }
    }

    const resetProductUsageReports = () => {
        productUsageReports.value = []
    }

    const allProductsChecked = (val:boolean) => {
        if (val) {
            selectedProducts.value = JSON.parse(JSON.stringify(filteredProductsSelection.value))
        } else {
            selectedProducts.value = []
        }
    }

    const allStoresChecked = (val: boolean) => {
        if (val) {
            selectedStores.value = JSON.parse(JSON.stringify(storeSelection.value))
        } else {
            selectedStores.value = []
        }
    }
</script>