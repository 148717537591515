import './assets/main.css'
import "vuestic-ui/css";

import { createApp } from 'vue'
import i18n from './i18n'
import { createVuestic } from 'vuestic-ui'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import interceptor from "./interceptor"
import * as Sentry from "@sentry/vue"

const app = createApp(App)

if (import.meta.env.VITE_APP_BASE === 'production') {
  Sentry.init({
      app,
      dsn: "https://ca84ead2f64489f6159be15cfb2d3d4d@o4506326595403776.ingest.sentry.io/4506326600384512",
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^https:\/\/warems\.com/, /^https:\/\/www.warems\.com/],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(createVuestic({ config: vuesticGlobalConfig }))
app.use(interceptor)

app.mount('#app')
