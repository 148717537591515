<template>
    <form @submit.prevent="submitForm">
        <div class="va-spacing-y-4" style="margin-top: 10px;" v-for="(item, index) in props.inboundItems" :key="index">
            <va-alert style="margin: auto !important; width: 100%; margin-bottom: 10px; text-align: center;">
                {{ item.receiptItem.nameOnBox }} - {{ item.receiptItem.quantity }} Box
            </va-alert>
            
            <div v-for="(box, boxIdx) in item.boxes" :key="index+'-'+boxIdx">
                <va-chip color="success" class="mt-1 mb-3">Box {{ boxIdx+1 }} </va-chip>

                <div class="row va-spacing-x-3 row va-spacing-y-3" v-for="(inventory, invIdx) in box.inventories" :key="invIdx">
                    <InventoryRow
                        :inventory="inventory" 
                        :isFirst="invIdx === 0" 
                        :isLast="invIdx === box.inventories.length - 1"
                        :readOnly="props.readOnly"
                        :allProducts="allProducts"
                        :notSelectable="invIdx === 0"
                        @remove="removeInventory(box.inventories, invIdx)" 
                        @add="addInventory(box.inventories)"
                    />
                </div>
            </div>
        </div>

        <div class="row va-spacing-x-3 va-spacing-y-3" style="justify-content: center;">
            <va-button preset="secondary" border-color="primary" @click="goToPrevious">Previous</va-button>
            <va-button type="submit" style="margin-bottom: auto;" :disabled="disableNextBtn">{{ nextBtnText }}</va-button>
        </div>
    </form>
</template>
<script setup lang="ts">
    import InventoryRow from '@/components/InventoryRow.vue'
    import Utils from '@/utils/utils'

    import { computed, type PropType } from 'vue'
    import type InboundItem from '@/types/InboundItem'
    import type Inventory from '@/types/Inventory'
    import type Selection from '@/types/Selection'

    const emit = defineEmits();
    const props = defineProps({
        inboundItems: Array as PropType<InboundItem[]>,
        readOnly: Boolean,
        allProducts: Array as PropType<Selection[]>,
        nextBtnText: String
    })

    // Methods

    const disableNextBtn = computed(() => {
        if (!props.inboundItems) return true

        for (let inboundItem of props.inboundItems) {
            if (!inboundItem.boxes) return true
            for (let box of inboundItem.boxes) {
                if (!box.inventories) return true
                for (let inventory of box.inventories) {
                    if (inventory.product && inventory.product.optional && Utils.isHasWeight(inventory.product.optional.hasWeight) && !inventory.weight) {
                        return true
                    }
                }
            }
        }

        return false
    })

    const submitForm = () => {
        emit('next')
    };

    const goToPrevious = () => {
        emit('previous')
    }

    const addInventory = (inventories: Inventory[]) => {
        inventories.push({} as Inventory)
    }

    const removeInventory = (inventories: Inventory[], invIdx: number) => {
        inventories.splice(invIdx, 1)
    }
</script>