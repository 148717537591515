import axios from 'axios'

import type { ClientAddressDTO } from '@/models/warems.models'

const clientInfoService = {
    async getClientAddresses(): Promise<ClientAddressDTO[]> {
        return (await (axios.get(`${import.meta.env.VITE_APP_BASE}/api/client/address`))).data.list
    },
    async updateClientAddress(address: ClientAddressDTO) {
        return await axios.put(`${import.meta.env.VITE_APP_BASE}/api/client/address`, address)
    }
};

export default clientInfoService;