<template>
    <va-card>
        <va-card-content>
            <AddDeliveryReceiptForm @submit-res="handleFormSubmitRes" />
        </va-card-content>
    </va-card>
</template>

<script setup lang="ts">
    import type { AxiosResponse } from 'axios'

    import AddDeliveryReceiptForm from './AddDeliveryReceiptForm.vue'

    const handleFormSubmitRes = (res: AxiosResponse<any, any>) => {
        if (res.status === 201) {
            window.alert('Delivery Receipt created!')
            window.location.href = '/in/delivery-receipt'
        }
    }
</script>