<template>
    <div class="va-spacing-y-3" style="padding: 20px 40px;">
        <div class="row va-spacing-x-3 va-spacing-y-3" style="align-items: flex-start;">
            <va-card v-for="warehouse of warehouses">
                <va-card-title>
                    <div class="row" style="justify-content: space-between;text-transform: none;">
                        <p style="font-size: 1rem;font-weight: bold;margin-right: 30px;">{{ warehouse.name }}</p>
                    </div>
                </va-card-title>
                <va-card-content>
                    <div class="va-spacing-y-3">
                        <p><strong>Timezone</strong>: {{ warehouse.timezone }}</p>
                        <p><va-switch v-model="warehouse.viewOnly" label="View Only" left-label @update:modelValue="updateWarehouse(warehouse)"/></p>
                        <p><va-switch v-model="warehouse.suspended" label="Suspended" left-label @update:modelValue="updateWarehouse(warehouse)"/></p>
                    </div>
                </va-card-content>
            </va-card>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import type { WarehouseDTO } from '@/models/warems.models'

    import adminService from '@/services/admin-service'

    const warehouses = ref([] as WarehouseDTO[])

    onBeforeMount(async() => {
        warehouses.value = await adminService.getAllWarehouse()
    })

    const updateWarehouse = async(warehouse: WarehouseDTO) => {
        await adminService.updateWarehouse(warehouse)
        window.location.reload()
    }
</script>