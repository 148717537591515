<template>
    <div class="row mt-3 mr-5 mb-4" style="justify-content: end;">
        <va-button @click="deleteAllActivityLogs">Delete All</va-button>
    </div>
    <va-card class="mt-3 ml-5 mr-5">
        <va-card-title>Activity Logs</va-card-title>
        <va-card-content v-if="activityLogs">
            <va-data-table class="customize-data-table" :items="activityLogs" :columns="activityLogColumns" :loading="loading"
                :per-page="activityLogPerPage" :current-page="activityLogCurrentPage" hoverable>
                <template #cell(time)="{ value }">
                    {{ new Date(value*1).toLocaleString() }}
                </template>
                <template #cell(completed)="{ value }">
                    {{ value === 'true' ? 'Yes' : 'No' }}
                </template>
                <template #bodyAppend v-if="activityLogs.length > activityLogPerPage">
                    <tr>
                        <td colspan="12">
                        <div class="flex mt-5">
                            <va-pagination v-model="activityLogCurrentPage" :pages="activityLogPages" :visible-pages="10" 
                                class="justify-center sm:justify-start"/>
                        </div>
                        </td>
                    </tr>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { ActivityLogDTO } from '@/models/warems.models'

    import adminService from '@/services/admin-service'

    const { confirm } = useModal()
    const loading = ref(true)
    const activityLogPerPage = ref(15)
    const activityLogCurrentPage = ref(1)
    const activityLogs = ref([] as ActivityLogDTO[])
    const activityLogColumns = [
        { key: 'name' },
        { key: 'activity' },
        { key: 'time' },
        { key: 'completed' },
    ]
    const activityLogPages = computed(() => {
        if (!activityLogs.value) return
        return Math.ceil(activityLogs.value.length / activityLogPerPage.value)
    })

    onBeforeMount(async() => {
        activityLogs.value = await adminService.getActivityLogs()
        loading.value = false
    })

    const deleteAllActivityLogs = () => {
        confirm({message: 'Do you want to delete all activity logs?'}).then(async(ok) => {
            if (ok && await adminService.deleteAllActivityLogs()) {
                window.location.reload()
            }
        })
    }
</script>