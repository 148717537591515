import { createRouter, createWebHistory } from 'vue-router'

import internalRoutes from '@/router/internal-routes'
import authRoutes from '@/router/auth-routes'

import AppLayout from '@/layouts/AppLayout.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

import NotFoundView from '@/views/others/NotFoundView.vue'
import Utils from '@/utils/utils';

const INTERNAL_URL = '/in'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: { name: 'login' },
    },
    {
      name: 'auth',
      path: '/',
      component: AuthLayout,
      children: authRoutes
    },
    {
      name: 'internal',
      path: INTERNAL_URL,
      component: AppLayout,
      children: internalRoutes
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundView,
    }
  ]
})

router.beforeEach((to, from, next) => {
    let jwtToken = localStorage.getItem('jwttoken')

    if (to.name === 'register') {
      if (jwtToken) localStorage.clear()
      if (!to.query.token) {
        window.location.href = '/login'
        return
      }
    }

    if (jwtToken) {
        if (Utils.getExpFromToken() < Date.now() / 1000) {
            localStorage.clear()
            window.location.href = '/login'
            return
        } else {
            if (to.name === 'login') {
                next({ name: 'order-overview' })
                return
            }
        }
    } else {
        if (to.fullPath.startsWith('/in')) {
          localStorage.clear()
          window.location.href = '/login'
          return
        } else if (!to.name) {
          localStorage.clear()
          window.location.href = '/login'
          return
        }
    }

    const allowedRoles = to.meta.roles

    if (Array.isArray(allowedRoles)) {
        const userRole = Utils.getRoleFromToken()
        if (allowedRoles.includes(userRole)) {
            next()
        } else {
            next('/404')
        }
    } else {
        next();
    }
})

export default router
