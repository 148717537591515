<template>
    <form @submit.prevent="submitForm" class="va-spacing-y-3">
        <div class="va-spacing-y-2" v-for="(inventory, index) in inventories" :key="index">
            <div class="row" v-if="unknownUUID?.includes(inventory.uuid)">
                <va-alert color="danger" style="width: 100%;" dense>
                    <va-icon name="arrow_downward"/> This entry was not found in the Inventory
                </va-alert>
            </div>
            <div class="row">
                <InventoryRow
                    :inventory="inventory"
                    :isFirst="index === 0"
                    :isLast="index === inventories.length - 1"
                    :allProducts="allProducts"
                    :controller="props.controller"
                    :index="index"
                    @remove="removeItem(index)"
                    @add="addItem"
                    @add-same="addSameItem"
                    @selected-idx="handleSelectedIdx"
                    @multi-weight="addMultiWeights"
                    show-quantity
                    show-add-same-item-btn
                    :read-only="props.readOnly"
                />
            </div>
        </div>
        
        <div class="flex md12 sm12 xs12" style="text-align: center">
            <va-button type="submit" class="mt-3" :disabled="disableBtn">Submit</va-button>
            <va-button v-if="props.controller?.showReset" type="button" class="ml-3 mt-3" preset="outline" @click="reset">Reset</va-button>
        </div>
    </form>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount, computed, watch, onMounted } from 'vue'
    import { useModal } from 'vuestic-ui'
    import { v4 as uuidv4 } from 'uuid'

    import Utils from '@/utils/utils'
    import InventoryRow from '@/components/InventoryRow.vue'
    import productService from '@/services/product-service'

    import type { PropType } from 'vue'
    import type Inventory from '@/types/Inventory'
    import type Selection from '@/types/Selection'
    import type OrderController from '@/types/OrderController'

    const { confirm } = useModal()
    const emit = defineEmits()
    const inventories = ref([] as Inventory[])
    const allProducts = ref([] as Selection[])
    const props = defineProps({
        store: Object as PropType<OrderController>,
        controller: Object as PropType<OrderController>,
        storeSelection: Object as PropType<Selection>,
        inventories: Object as PropType<Inventory[]>,
        unknownUUID: Object as PropType<String[]>,
        allProductSelections: Object as PropType<Selection[]>,
        readOnly: Boolean,
    })

    onMounted(() => {
        watch(inventories, (newVal: Inventory[]) => {
            emit('onChanged', newVal)
        }, { deep: true })
    })

    onBeforeMount(async () => {
        if (props.inventories && props.inventories.length) {
            addInventoriesToExistingList(props.inventories)
        } else {
            addItem()
        }

        if (props.allProductSelections && props.allProductSelections.length) {
            loadAllProducts(props.allProductSelections)
        } else {
            let products = await productService.findAllProducts()
            loadAllProducts(Utils.getProductsForSelection(products, false, true, false))
        }
    })

    const disableBtn = computed(() => {
        if (!inventories.value.length) return true

        let anyInvalid = false
        
        for (let inventory of inventories.value) {
            if (!inventory.product || (inventory.product.optional && Utils.isHasWeight(inventory.product.optional.hasWeight) && !inventory.weight) ||
                (!Utils.isHasWeight(inventory.product.optional.hasWeight) && !inventory.quantity)) {
                anyInvalid = true
                break
            }
        }

        return anyInvalid
    })

    // Methods

    const loadAllProducts = (allProductSelections: Selection[]) => {
        allProducts.value = JSON.parse(JSON.stringify(allProductSelections))
    }

    const addItem = () => {
        inventories.value.push({ uuid: uuidv4() } as Inventory)
    }

    const addSameItem = () => {
        for (let i = inventories.value.length - 1; i >= 0; i--) {
            let inventory = inventories.value[i]
            if (inventory.product) {
                let newInv:Inventory = JSON.parse(JSON.stringify(inventory))
                delete newInv.weight
                delete newInv.quantity
                newInv.uuid = uuidv4()
                inventories.value = [...inventories.value, newInv]
                break
            }
        }
    }

    const addMultiWeights = (invList: Inventory[]) => {
        for (let i = inventories.value.length - 1; i >= 0; i--) {
            let inventory = inventories.value[i]
            if (!inventory.product) {
                inventories.value.splice(i, 1)
                break
            }
        }

        addInventoriesToExistingList(invList)
    }

    const addInventoriesToExistingList = (newInvs: Inventory[]) => {
        for (let inv of newInvs) {
            inv.uuid = uuidv4()
            inventories.value.push(inv)
        }
    }

    const removeItem = (idx: number) => {
        inventories.value.splice(idx, 1)
    }

    const getInventoryResult = (newVal: Inventory[]) => {
        const inventoryList: any = []
        newVal.forEach(inventory => {
            inventoryList.push({
                uuid: inventory.uuid,
                productId: inventory.product ? inventory.product.value : null,
                quantity: inventory.quantity ? inventory.quantity : null,
                weight: inventory.weight ? inventory.weight : null,
                deliveryReceiptItemId: null,
                rate: inventory.rate ? inventory.rate * 1 : null,
                returnQty: inventory.returnQty ? inventory.returnQty : null,
                returnWeight: inventory.returnWeight ? inventory.returnWeight : null,
            })
        })
        return inventoryList
    }

    const submitForm = async () => {
        const data = { inventoryList: getInventoryResult(inventories.value) }

        if (props.controller?.confirmWindow?.show) {
            confirm({message: props.controller?.confirmWindow?.text}).then(async(ok) => {
                if (ok) emit('submit-res', data)
            })
        } else {
            emit('submit-res', data)
        }
    }

    const handleSelectedIdx = (idx: number) => {
        const selectedInventory = inventories.value[idx]

        if (props.storeSelection && allProducts.value) {
            if (props.storeSelection.optional?.wholesalePrice) {
                selectedInventory.rate = selectedInventory.product.optional.wholesalePrice
            } else {
                selectedInventory.rate = selectedInventory.product.optional.price
            }
        }

        selectedInventory.weight = 0
        selectedInventory.quantity = 0

        emit('remove-unknown-uuid', selectedInventory.uuid)
    }

    const reset = () => {
        resetInventory()
        emit('reset')
    }

    const resetInventory = () => {
        inventories.value = []
        addItem()
    }

    defineExpose({ loadAllProducts, resetInventory })
</script>