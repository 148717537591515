<template>
    <div class="mt-3 ml-3 va-spacing-y-4">
        <div class="row va-spacing-x-5" style="justify-content: center;">
            <va-card stripe stripe-color="success" class="mb-5">
                <va-card-title class="card-title">Insufficient Products</va-card-title>
                <va-card-content>
                    <va-data-table :items="dashboardInfo.insufficientProducts" :columns="productColumns" :per-page="5" :current-page="currentInsufficientPage"
                        sticky-header hoverable>
                        <template #cell(productName)="{ rowData }">
                            {{ rowData.secondaryName }}
                            <span>{{ rowData.tertiaryName ? ' - ' + rowData.tertiaryName : '' }}</span>
                        </template>
                        <template #bodyAppend>
                            <tr>
                                <td colspan="12">
                                <div class="flex mt-5">
                                    <va-pagination v-model="currentInsufficientPage" :pages="insufficientCountPages" :visible-pages="5" 
                                        class="justify-center sm:justify-start"/>
                                </div>
                                </td>
                            </tr>
                        </template>
                    </va-data-table>
                </va-card-content>
            </va-card>

            <va-card stripe stripe-color="success" class="mb-5">
                <va-card-title class="card-title">Inventory Count</va-card-title>
                <va-card-content>
                    <va-data-table :items="dashboardInfo.inventoryCounts" :columns="columns" :per-page="5" :current-page="currentInvCountPage" 
                        sticky-header hoverable>
                        <template #cell(time)="{ rowData }">
                            {{ !rowData.time || new Date(rowData.time*1).toLocaleString() }}
                        </template>
                        <template #cell(count)="{ rowData }">
                            {{ !rowData.count || rowData.count.toLocaleString('en-US') }}
                        </template>
                        <template #bodyAppend>
                            <tr>
                                <td colspan="12">
                                <div class="flex mt-5">
                                    <va-pagination v-model="currentInvCountPage" :pages="invCountPages" :visible-pages="5" 
                                        class="justify-center sm:justify-start"/>
                                </div>
                                </td>
                            </tr>
                        </template>
                    </va-data-table>
                </va-card-content>
            </va-card>

            <va-card stripe stripe-color="success" class="mb-5">
                <va-card-title class="card-title">Statistics</va-card-title>
                <va-card-content class="va-spacing-y-4">
                    <div class="row va-spacing-x-3">
                        <va-card class="col-span-6" color="success">
                            <va-card-content>
                                <va-popover message="Inventories which available for sale and already booked by stores, and reserved by warehouse">
                                    <h2 class="va-h2 m-0 text-white">
                                        {{ dashboardInfo.activeInventories ? dashboardInfo.activeInventories.toLocaleString('en-US') : 0 }}
                                    </h2>
                                </va-popover>
                                <p class="text-white">Total Active Inventories</p>
                            </va-card-content>
                        </va-card>
                        <va-card class="col-span-6" color="danger">
                            <va-card-content>
                                <va-popover message="Inventories which being removed and able to restore">
                                    <h2 class="va-h2 m-0 text-white">
                                        {{ dashboardInfo.removedInventories ? dashboardInfo.removedInventories.toLocaleString('en-US') : 0 }}
                                    </h2>
                                </va-popover>
                                <p class="text-white">Removed Inventories</p>
                            </va-card-content>
                        </va-card>
                    </div>
                    <div class="row va-spacing-x-3">
                        <va-card class="col-span-6" color="success">
                            <va-card-content>
                                <h2 class="va-h2 m-0 text-white">
                                    {{ dashboardInfo.productsCount ? dashboardInfo.productsCount.toLocaleString('en-US') : 0 }}
                                </h2>
                                <p class="text-white">Total Products</p>
                            </va-card-content>
                        </va-card>
                        <va-card class="col-span-6" color="warning">
                            <va-card-content>
                                <va-popover message="Products which have 0 inventory quantity">
                                    <h2 class="va-h2 m-0 text-white">
                                        {{ dashboardInfo.noQtyProductsCount ? dashboardInfo.noQtyProductsCount.toLocaleString('en-US') : 0 }}
                                    </h2>
                                </va-popover>
                                <p class="text-white">0 Quantity Products</p>
                            </va-card-content>
                        </va-card>
                    </div>
                </va-card-content>
            </va-card>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'

    import dashboardService from '@/services/dashboard-service'

    import type { DashboardInfo } from '@/models/warems.models'

    const dashboardInfo = ref({} as DashboardInfo)
    const columns = [
        { key: 'time' },
        { key: 'count' },
    ]
    const productColumns = [
        { key: 'productName' },
        { key: 'quantity' },
        { key: 'reorderPoint' },
    ]
    const currentInvCountPage = ref(1)
    const currentInsufficientPage = ref(1)
    const invCountPages = computed(() => {
        return Math.ceil(dashboardInfo.value.inventoryCounts?.length / 5)
    })
    const insufficientCountPages = computed(() => {
        return Math.ceil(dashboardInfo.value.insufficientProducts?.length / 5)
    })

    onBeforeMount(async() => {
        dashboardInfo.value = await dashboardService.getDashboardInfo()
    })
</script>
<style>
    .card-title {
        font-size: 1.1em !important;
        text-transform: capitalize !important;
    }
</style>