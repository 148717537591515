<template>
    <div class="row" style="justify-content: center;">
        <va-icon name="check_circle" size="4rem" color="success"/>
        <h5 class="va-h5 ml-4" style="margin-top: 1rem;">Inventory Added!</h5>
    </div>
    <div class="row va-spacing-x-4 va-spacing-y-3 mt-3">
        <QRCodePrintView :qrCodeList="qrCodeList" v-if="qrCodeList.length > 0"/>
    </div>
    <div class="row mt-3 va-spacing-x-3" style="justify-content: center;">
        <QRCodePrintButton :qrCodeList="qrCodeList"/>
    </div>
    <div class="row va-spacing-x-3" style="justify-content: center;">
        <va-button preset="secondary" @click="reloadPage">Add more Inventory</va-button>
    </div>
</template>

<script setup lang="ts">
    import type { PropType } from 'vue'
    import { ref, watchEffect } from 'vue'

    import QRCodePrintView from '@/components/qrcode/QRCodePrintView.vue'
    import QRCodePrintButton from '@/components/qrcode/QRCodePrintButton.vue'

    import type QRCodeInfo from '@/types/QRCodeInfo'
    import type Inventory from '@/types/Inventory'

    const qrCodeList = ref([] as QRCodeInfo[])

    const props = defineProps({
        resBody: Array as PropType<Inventory[]>
    })

    watchEffect(() => {
        props.resBody?.forEach(body => {
            const qrCodeInfo: QRCodeInfo = {
                val: body.qrValue,
                text: body.qrText,
                id: body.inventoryId
            }

            qrCodeList.value.push(qrCodeInfo)
        })
    });

    const reloadPage = () => {
        window.location.reload()
    }
</script>