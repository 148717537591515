import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type { InvoiceDTO, UpdateInvoiceRequest, PayInvoiceRequest, InvoicesRequest } from '@/models/warems.models'

const invoiceService = {
    async getInvoicesByTimeRange(userId: string, start: number, end: number) {
        return await axios.get(`${import.meta.env.VITE_APP_API_BASE}/invoice?userId=${encodeURIComponent(userId)}&start=${start}&end=${end}`)
    },
    async downloadStatementByUserAndTimeRange(userId: string, start: number, end: number) {
        return axios.post(`${import.meta.env.VITE_APP_API_BASE}/document/statement/download`, { userId, start, end }, { responseType: 'blob' })
    },
    generateInvoice(orderId: string, orderItemList: any) {
        return axios.post(`${import.meta.env.VITE_APP_API_BASE}/invoice`, { orderId, orderItemList })
    },
    async getInvoice(orderId: string): Promise<InvoiceDTO[]> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/invoice?orderId=${encodeURIComponent(orderId)}`)
        return res?.data.list
    },
    async getInvoiceByReturnId(returnId: string): Promise<InvoiceDTO> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/invoice?returnId=${encodeURIComponent(returnId)}`)
        return res?.data.obj
    },
    async invoicePaid(request: PayInvoiceRequest) {
        return await axios.put(`${import.meta.env.VITE_APP_API_BASE}/invoice/paid`, request)
    },
    async updateInvoice(updateInvoiceRequest: UpdateInvoiceRequest): Promise<Boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/invoice`, updateInvoiceRequest)
        return res?.data.obj
    },
    async downloadInvoices(invoiceIds: string[]) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/document/invoice/download`, { invoiceIds } as InvoicesRequest, { responseType: 'blob' })
    },
};

export default invoiceService;