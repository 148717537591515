<template v-if="props.inventory">
    <div class="row va-spacing-x-3">
        <va-select v-model="props.inventory.product" label="Product" :options="props.allProducts" :readonly="props.readOnly || props.notSelectable" 
            @update:modelValue="handleProductSelected" searchable highlight-matched-text />

        <va-input v-if="props.inventory.product && props.showQuantity && !Utils.isHasWeight(props.inventory?.product.optional.hasWeight)" 
            v-model="props.inventory.quantity" type="number" step="1" label="Quantity" style="max-width: 80px;" 
            :readonly="props.controller?.showReturnProperties && props.readOnly"/>

        <va-input v-model="props.inventory.weight" type="number" step="0.01" label="Weight" style="max-width: 80px;" :readonly="props.readOnly"
            v-if="props.inventory.product && props.inventory.product.optional && Utils.isHasWeight(props.inventory?.product.optional.hasWeight)"/>
        
        <va-input label="Rate" v-if="props.controller?.rate?.show" v-model="props.inventory.rate" type="number" step="0.01" style="max-width: 110px;">
            <template #prependInner>
                <va-icon name="attach_money" />
            </template>
        </va-input>

        <va-input v-if="props.inventory.product && props.controller?.showReturnProperties && !Utils.isHasWeight(props.inventory?.product.optional.hasWeight)" 
            v-model="props.inventory.returnQty" type="number" step="1" label="Return Quantity" style="max-width: 100px;"/>

        <va-input v-model="props.inventory.returnWeight" type="number" step="0.01" label="Return Weight" style="max-width: 100px;"
            v-if="props.controller?.showReturnProperties && props.inventory.product && props.inventory.product.optional && 
            Utils.isHasWeight(props.inventory?.product.optional.hasWeight)"/>

        <va-button icon="clear" color="danger" style="margin-top:1em" @click="removeItem" v-show="!props.readOnly && !props.isFirst" />
    </div>

    <div class="flex md12 sm12 xs12 mt-3 mb-3" v-if="!props.readOnly && props.isLast">
        <va-button icon="add" class="mr-3 mt-3" preset="secondary" border-color="primary" @click="addItem">
            Add More Inventory
        </va-button>
        <va-button icon="add" class="mr-3 mt-3" preset="secondary" border-color="primary" @click="addSameItem" v-if="props.showAddSameItemBtn">
            Add Same Inventory
        </va-button>
        <va-button icon="add" class="mr-3 mt-3" preset="secondary" border-color="primary" @click="openMultiWeightModal" v-if="props.showAddSameItemBtn">
            Add Multiple Inventory Weights
        </va-button>
    </div>

    <va-modal v-model="multiWeightModal.show" @cancel="resetMultiWeightModal" @ok="processMultiWeightModal" noDismiss>
        <div class="row va-spacing-x-3">
            <va-select label="Product" v-model="multiWeightModal.selectedProduct" :options="multiWeightModal.weightedProducts" searchable highlight-matched-text 
                style="min-width: 500px;"/>
            <va-input v-model="multiWeightModal.selectedQuantity" type="number" step="1" label="Quantity" style="max-width: 80px;"/>
        </div>
        <div class="mt-4" v-if="multiWeightModal.selectedQuantity > 0">
            <label class="va-input-label" style="color: #154EC1;">Weights</label>
            <div>
                <va-input style="width: 80px;" type="number" step="0.01" v-model="multiWeightModal.weights[i-1]"
                    v-for="i in Array.from({ length: multiWeightModal.selectedQuantity }, (v, k) => k + 1)" :key="i" />
            </div>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref } from 'vue'

    import type { PropType } from 'vue'
    import type Inventory from '@/types/Inventory'
    import type Selection from '@/types/Selection'
    import type OrderController from '@/types/OrderController';

    import Utils from '@/utils/utils'

    const multiWeightModal = ref({
        show: false,
        selectedProduct: {},
        selectedQuantity: 0,
        weightedProducts: [] as Selection[],
        weights: [] as number[]
    })
    const emits = defineEmits(['remove', 'add', 'addSame', 'selectedIdx', 'multiWeight']);
    const props = defineProps({
        inventory: Object as PropType<Inventory>,
        isLast: Boolean,
        isFirst: Boolean,
        readOnly: Boolean,
        showQuantity: Boolean,
        allProducts: Object as PropType<Selection[]>,
        notSelectable: Boolean,
        showAddSameItemBtn: Boolean,
        controller: Object as PropType<OrderController>,
        index: Number
    })

    // Methods
    const addItem = () => {
        emits('add')
    }

    const addSameItem = () => {
        emits('addSame')
    }

    const removeItem = () => {
        emits('remove')
    }

    const handleProductSelected = () => {
        if (props.inventory?.product) emits('selectedIdx', props.index)
    }

    const openMultiWeightModal = () => {
        multiWeightModal.value.show = true
        multiWeightModal.value.weightedProducts = props.allProducts?.filter(p => Utils.isHasWeight(p.optional.hasWeight))
    }

    const resetMultiWeightModal = () => {
        multiWeightModal.value.show = false
        multiWeightModal.value.selectedProduct = {}
        multiWeightModal.value.selectedQuantity = 0
        multiWeightModal.value.weightedProducts = [] as Selection[],
        multiWeightModal.value.weights = [] as number[]
    }

    const processMultiWeightModal = () => {
        const inventories: Inventory[] = []

        for (let i = 0; i < multiWeightModal.value.selectedQuantity * 1; i++) {
            inventories.push({
                product: multiWeightModal.value.selectedProduct,
                weight: multiWeightModal.value.weights[i]
            } as Inventory)
        }

        emits('multiWeight', inventories)
        resetMultiWeightModal()
    }
</script>