<template>
    <va-layout>
        <template #top>
            <va-navbar color="primary" class="py-2"></va-navbar>
        </template>

        <template #content>
            <router-view />
        </template>

        <template #bottom>
            <div style="height: 5vh;">
                <va-navbar style="text-align: center;padding-bottom: 0;">
                    <template #center>
                        <span style="font-size: 12px;opacity: .8;">
                            © {{ new Date().getFullYear() }} Ternary Innovation, LLC. v{{ version }}
                        </span>
                    </template>
                </va-navbar>
            </div>
        </template>
    </va-layout>
</template>
<script setup lang="ts">
    const version = import.meta.env.VITE_APP_VERSION
</script>