<template>
    <div style="margin: 10px 20px 0 20px;display: flex;justify-content: end;">
        <va-button @click="addUserResModal.show=true" v-if="!Utils.getViewOnlyFromToken()">Add new User</va-button>
    </div>

    <va-card class="row" style="margin: 20px 0;justify-content: center;">
        <va-card-content>
            <va-data-table class="table-crud" :items="users" :columns="columns" hoverable>
                <template #cell(email)="{ rowData }">
                    {{ rowData.username }}
                </template>

                <template #cell(status)="{ rowData }">
                    <va-badge :text="rowData.disabled || !rowData.activated ? 'INACTIVE' : 'ACTIVE'" 
                        :color="rowData.disabled || !rowData.activated ? 'warning' : 'info'"/>
                </template>

                <template #cell(wholesalePrice)="{ rowData }">
                    {{ rowData.role === 'STORE' ? (rowData.wholesalePrice ? 'Yes' : 'No') : '-' }}
                </template>

                <template #cell(actions)="{ rowData }">
                    <div class="va-spacing-x-2" v-if="!Utils.getViewOnlyFromToken()">
                        <va-icon name="edit" @click="openUpdateUserModal(rowData)" color="primary"/>
                        <va-icon v-if="rowData.role === 'STORE'" name="wysiwyg" @click="goToUserDetails(rowData.id)" color="primary"/>
                        <va-icon v-if="!rowData.activated && rowData.role !== 'READ_ONLY_STORE'" name="send" color="primary" @click="resendActiveEmail(rowData)"/>
                        <!-- <va-icon v-if="rowData.role === 'STORE'" name="sync" @click="handleSyncUpInvoices(rowData)" color="danger"/> -->
                    </div>
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>

    <va-modal v-model="addUserResModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div v-if="addUserResModal.url" style="text-align: center;" class="va-spacing-y-4">
                <p>Please share below URL for register:</p>
                <p><strong>{{ addUserResModal.url }}</strong></p>
                <va-button @click="closeAddUserResModal">Close</va-button>
            </div>
            <div v-if="!addUserResModal.url" class="va-spacing-y-3">
                <div class="row">
                    <va-input v-model="newUser.name" label="Name"/>
                </div>
                <div class="row">
                    <va-select v-model="newUser.role" label="Role" :options="userRoles"/>
                </div>
                <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                    <va-button @click="closeAddUserResModal" preset="secondary">Close</va-button>
                    <va-button @click="addNewUser" :disabled="!isNewData">Submit</va-button>
                </div>
            </div>
        </div>
    </va-modal>

    <va-modal v-model="editUserResModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <va-input v-model="editUserResModal.user.name" label="Name"/>
            </div>
            <div class="row" v-if="editUserResModal.user.role === 'STORE'">
                <va-select v-model="editUserResModal.wholesalePrice" label="Wholesale Price" :options="['Yes', 'No']"/>
            </div>
            <div class="row" v-if="editUserResModal.user.role !== 'READ_ONLY_STORE'">
                <va-select v-model="editUserResModal.user.role" label="Role" :options="userRoles"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="editUserResModal.show=false" preset="secondary">Close</va-button>
                <va-button @click="updateUser">Edit</va-button>
            </div>
        </div>
    </va-modal>

    <!-- <va-modal v-model="syncUpInvoicesModal.show" hideDefaultActions noDismiss>
        <div class="va-spacing-y-4">
            <div class="row">
                <h4 class="va-h4">Invoice Syncup Request (收据同步更新请求)</h4>
            </div>
            <div style="line-height: 1.6;">
                <p>Are you sure you want to syncup the existing unpaid invoice for <span style="font-weight: bold;font-size: 1.2em;">{{syncUpInvoicesModal.user.name}}</span>?</p>
                <p>If yes and you understand the potential risks, please type 'understand' in the below input box before clicking Submit button.</p>
                
                <p>你确定要同步更新<span style="font-weight: bold;font-size: 1.2em;">{{syncUpInvoicesModal.user.name}}</span>的所有已有未付款收据吗?</p>
                <p>如果是的, 并且理解明白其中有可能带来的风险, 请在下面的框框中输入“understand”, 然后点击Submit按钮。</p>
            </div>
            <div class="row">
                <va-input v-model="syncUpInvoicesModal.text"/>
            </div>
            <div class="row va-spacing-x-3" style="justify-content: center;margin-top: 30px;">
                <va-button @click="closeSyncupRequest" preset="secondary">Close</va-button>
                <va-button @click="syncUpInvoices" :disabled="syncUpInvoicesModal.text !== 'understand'">Submit</va-button>
            </div>
        </div>
    </va-modal> -->
</template>
<script setup lang="ts">
    import { onBeforeMount, ref, computed } from 'vue'
    import { useModal } from 'vuestic-ui'

    import type { UserDTO } from '@/models/warems.models'

    import userService from '@/services/user-service'
    import correctionService from '@/services/correction-service'
    import { useLoadingStore } from '@/stores/loading-store'
    import Utils from '@/utils/utils'

    const { showLoading, hideLoading } = useLoadingStore()
    const { confirm } = useModal()
    const users = ref([] as UserDTO[])
    const columns = [
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'role', sortable: true },
        { key: 'status', sortable: true },
        { key: 'wholesalePrice' },
        { key: 'actions', width: 80 }
    ]
    const newUser = ref({} as UserDTO)
    const userRoles = ref(['STORE', 'WAREHOUSE_ADMIN', 'WAREHOUSE_OPS'])
    const addUserResModal = ref({
        show: false,
        url: ''
    })
    const editUserResModal = ref({
        show: false,
        user: {} as UserDTO,
        wholesalePrice: ''
    })
    // const syncUpInvoicesModal = ref({
    //     show: false,
    //     user: {} as UserDTO,
    //     text: ''
    // })


    onBeforeMount(async() => {
        users.value = await userService.getUsers()
    })

    const isNewData = computed(() => {
        return newUser.value.name && newUser.value.role
    })

    // Methods
    const addNewUser = async() => {
        addUserResModal.value.url = await userService.addUser(newUser.value)
    }

    const closeAddUserResModal = () => {
        addUserResModal.value.show = false
        addUserResModal.value.url = ''
        newUser.value = {} as UserDTO
    }

    const openUpdateUserModal = (user: UserDTO) => {
        editUserResModal.value.show = true
        editUserResModal.value.user = JSON.parse(JSON.stringify(user))
        editUserResModal.value.wholesalePrice = editUserResModal.value.user.wholesalePrice ? 'Yes' : 'No'
    }

    const updateUser = async() => {
        editUserResModal.value.user.wholesalePrice = editUserResModal.value.wholesalePrice === 'Yes'
        await userService.updateUser(editUserResModal.value.user)
        window.location.reload()
    }

    const goToUserDetails = (id: string) => {
        window.location.href=`/in/settings/user/details?userId=${encodeURIComponent(id)}`
    }

    const resendActiveEmail = (user: UserDTO) => {
        confirm({message: `Do you want to resend the activation email for "${user.name}" ?`}).then(async(ok) => {
            if (ok) {
                showLoading()
                if (await userService.resendActivationEmail(user.id)) {
                    hideLoading()
                    confirm({message: 'Email sent.', cancelText: ''}).then(() => window.location.reload())
                }
            }
        })
    }

    // const handleSyncUpInvoices = (user: UserDTO) => {
    //     syncUpInvoicesModal.value.show = true
    //     syncUpInvoicesModal.value.user = user
    // }

    // const syncUpInvoices = async() => {
    //     await correctionService.syncupInvoiceItemRatesRequest({userId: syncUpInvoicesModal.value.user.id})
    //     confirm({message: 'Completed. All existing unpaid invoices being sync up with the latest product rates.', cancelText: ''}).then(() => window.location.reload())
    //     closeSyncupRequest()
    // }

    // const closeSyncupRequest = () => {
    //     syncUpInvoicesModal.value.show = false
    //     syncUpInvoicesModal.value.text = ''
    //     syncUpInvoicesModal.value.user = {}
    // }
</script>
<style scoped>
    .table-crud {
        --va-form-element-default-width: 0;
        .va-input {
            display: block;
        }
    }

    .table-crud__slot {
        th {
            vertical-align: middle;
        }
    }
</style>