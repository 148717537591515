<template>
    <va-accordion v-model="accordionValue" multiple>
        <va-collapse v-for="(route, idx) in routes" :key="idx">
            <template #header>
                <va-sidebar-item @click="clickSidebar(route, undefined, idx)">
                    <va-sidebar-item-content>
                        <va-icon v-if="route.meta && route.meta.icon" :name="route.meta.icon" />

                        <va-sidebar-item-title>
                            {{ route.meta.displayName }}
                            <va-chip v-if="route.name === 'order' && pendingOrderCount > 0" color="warning" size="small" style="margin-left: 5rem;">
                                <strong>{{ pendingOrderCount }}</strong>
                            </va-chip>
                        </va-sidebar-item-title>

                        <va-icon v-if="route.children" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'" />
                    </va-sidebar-item-content>
                </va-sidebar-item>
            </template>
            <template #body>
                <va-sidebar-item v-for="(child, index) in route.children" :key="index" :active="isRouteActive(child)"
                    @click="clickSidebar(route, child, idx)">
                    <va-sidebar-item-content>
                        <va-sidebar-item-title class="ml-3">
                            {{ child.meta.displayName }}
                        </va-sidebar-item-title>
                    </va-sidebar-item-content>
                </va-sidebar-item>
            </template>
        </va-collapse>
    </va-accordion>
</template>
<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'

    import AccessUtil from '@/utils/access'
    import orderService from '@/services/order-service'

    import type { PropType } from 'vue'
    import type { INavigationRoute } from '@/router/internal-routes'

    const emit = defineEmits()
    const props = defineProps({
        items: Object as PropType<INavigationRoute[]>
    })
    const routes = ref([] as INavigationRoute[])
    const accordionValue = ref<boolean[]>([])
    const pendingOrderCount = ref(0)

    onMounted(async() => {
        if (!props.items) return

        for (let item of props.items) {
            if (item.meta && item.meta.showInSidebar) {
                if (item.children) {
                    const children: INavigationRoute[] = []
                    item.children.forEach(child => {
                        if (child.meta && child.meta.showInSidebar) {
                            children.push(child)
                        }
                    })
                    item.children = children
                }
                routes.value.push(item)
            }
        }

        accordionValue.value = routes.value.map((item) => isItemExpanded(item))
        if (AccessUtil.isAdminGroup()) pendingOrderCount.value = await orderService.getPendingOrderCount()
    })

    const clickSidebar = (parentRoute: INavigationRoute, childRoute?: INavigationRoute, parentIdx?: number) => {
        if (childRoute) {
            emit('click-sidebar-item', childRoute.children && childRoute.children.length ? childRoute.children[0] : childRoute)
        } else {
            if (parentRoute.children && parentRoute.children.length) {
                accordionValue.value.forEach((val, idx) => {
                    if (idx !== parentIdx) accordionValue.value[idx] = (idx === parentIdx)
                })
            } else {
                emit('click-sidebar-item', parentRoute)
            }
        }
    }

    function isRouteActive(item: INavigationRoute) {
        return item.name === useRoute().name
    }

    function isItemExpanded(item: INavigationRoute): boolean {
        if (!item.children) return false

        const isCurrentItemActive = isRouteActive(item)
        const isChildActive = !!item.children.find((child) =>
            child.children ? isItemExpanded(child) : isRouteActive(child),
        )

        return isCurrentItemActive || isChildActive
    }
</script>