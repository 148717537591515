import axios from 'axios'

import type QRCodeInfo from '@/types/QRCodeInfo'

const qrCodeService = {
    async downloadAvery22805PDF(qrCodes: QRCodeInfo[]) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/qr-code/avery-22805-pdf`, { qrCodes }, { responseType: 'blob' })
    }
};

export default qrCodeService;