<template>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center; padding: 40px 0 20px 0;">
        <form @submit.prevent="register" class="mt-3 va-spacing-y-3">
                <va-card>
                    <va-card-title>{{ storeName }}</va-card-title>
                    <va-card-content>
                        <va-stepper v-model="step" :steps="steps" controlsHidden :navigationDisabled="true" v-if="showAddress">
                            <template #step-content-0>
                                <div class="va-spacing-y-4" style="margin-bottom: -30px;">
                                    <AddressComponent name="Shipping Name" :address="shipAddress"/>
                                    <div style="text-align: center;">
                                        <va-button @click="step=1" :disabled="!shipAddressValid">Next</va-button>
                                    </div>
                                </div>
                            </template>
                            <template #step-content-1>
                                <div class="va-spacing-y-4" style="margin-bottom: -30px;">
                                    <div class="row">
                                        <va-checkbox v-model="sameAsShipAddr" label="Same as Shipping Address" @update:modelValue="handleSameAsShipAddr"/>
                                    </div>
                                    <AddressComponent name="Billing Name" :address="billAddress"/>
                                    <div class="row va-spacing-x-3" style="justify-content: center;">
                                        <va-button @click="step=0">Previous</va-button>
                                        <va-button @click="step=2" :disabled="!billAddressValid">Next</va-button>
                                    </div>
                                </div>
                            </template>
                            <template #step-content-2>
                                <div class="va-spacing-y-4" style="margin-bottom: -30px;">
                                    <CredentialComponent :credential="credentialInfo"/>
                                    <div class="row va-spacing-x-3" style="justify-content: center;">
                                        <va-button @click="step=1">Previous</va-button>
                                        <va-button type="submit" :disabled="!shipAddressValid || !billAddressValid || !credentialValid">
                                            Submit
                                        </va-button>
                                    </div>
                                </div>
                            </template>
                        </va-stepper>

                        <div v-if="!showAddress">
                            <div class="va-spacing-y-4">
                                <CredentialComponent :credential="credentialInfo"/>
                                <div class="row va-spacing-x-3" style="justify-content: center;">
                                    <va-button type="submit" :disabled="!credentialValid">
                                        Submit
                                    </va-button>
                                </div>
                            </div>
                        </div>
                    </va-card-content>
                </va-card>
        </form>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue'
    import { useRoute } from 'vue-router'
    import { useModal } from 'vuestic-ui'

    import type { ClientAddressDTO, LoginRequest } from '@/models/warems.models'

    import AddressComponent from '@/components/AddressComponent.vue'
    import CredentialComponent from '@/components/CredentialComponent.vue'
    import authService from '@/services/auth-service'

    const { confirm } = useModal()
    const route = useRoute()
    const billAddress = ref({ type: 'BILL' } as ClientAddressDTO)
    const shipAddress = ref({ type: 'SHIP' } as ClientAddressDTO)
    const credentialInfo = ref({} as LoginRequest)
    const step = ref(0)
    const steps = [
        { label: 'Shipping Address' },
        { label: 'Billing Address' },
        { label: 'Account Credential' }
    ]
    const sameAsShipAddr = ref(false)
    const token = route.query.token as string
    const storeName = route.query.name as string
    const showAddress = route.query.ar === '1'

    // Methods
    const shipAddressValid = computed(() => {
        return shipAddress.value.address1 && shipAddress.value.city && shipAddress.value.state && shipAddress.value.zipCode &&
        shipAddress.value.name && shipAddress.value.type
    })

    const billAddressValid = computed(() => {
        return billAddress.value.address1 && billAddress.value.city && billAddress.value.state && billAddress.value.zipCode &&
        billAddress.value.name && billAddress.value.type
    })

    const credentialValid = computed(() => {
        return credentialInfo.value.username && credentialInfo.value.password && token && storeName
    })

    const register = async() => {
        if (await authService.register({
            ...credentialInfo.value,
            billAddress: showAddress ? billAddress.value : null,
            shipAddress: showAddress ? shipAddress.value : null,
            token,
            name: storeName
        })) {
            confirm({ message: 'Register success, please check your email for activating the account.', cancelText: '', noDismiss: true })
                .then(() => window.location.href = '/login')
        }
    }

    const handleSameAsShipAddr = (val: any) => {
        if (val) {
            billAddress.value.address1 = shipAddress.value.address1
            billAddress.value.address2 = shipAddress.value.address2
            billAddress.value.city = shipAddress.value.city
            billAddress.value.state = shipAddress.value.state
            billAddress.value.zipCode = shipAddress.value.zipCode
        } else {
            billAddress.value.address1 = ''
            billAddress.value.address2 = ''
            billAddress.value.city = ''
            billAddress.value.state = ''
            billAddress.value.zipCode = ''
        }
    }
</script>