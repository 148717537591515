<template>
    <div style="padding: 20px 40px;">
        <va-input class="mb-3" label="Template Name" v-model="templateName"/>
        <AddOrderForm v-if="loaded" @submit="handleFormSubmitRes" :controller="addOrderController" :step="0" :userPreference="userPreference" 
            :orderDetails="orderDetails" @back="goBack"/>
    </div>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import { useRoute } from 'vue-router'

    import preferenceService from '@/services/preference-service'
    import ordorderTemplateService from '@/services/order-template-service'

    import type Selection from '@/types/Selection'
    import type OrderController from '@/types/OrderController'
    import type OrderItem from '@/types/OrderItem'
    import type OrderDetails from '@/types/OrderDetails'
    import type { PreferenceDTO, OrderTemplateDTO } from '@/models/warems.models'

    import AddOrderForm from '@/components/order/AddOrderForm.vue'

    const { query } = useRoute()
    const templateName = ref()
    const orderDetails = ref({} as OrderDetails)
    const userPreference = ref({} as PreferenceDTO)
    const addOrderController = ref({
        removeBtn: { show: true },
        addBtn: { show: true },
        submitBtn: { text: 'Add New Order Template' },
        onlyAvailableProducts: false,
        goBackBtn: { show: true }
    } as OrderController)
    const loaded = ref(false)
    const currentOrderTemplate = ref({} as OrderTemplateDTO)

    onBeforeMount(async() => {
        userPreference.value = await preferenceService.getUserPrefence()

        if (query.id) {
            currentOrderTemplate.value = await ordorderTemplateService.getOrderTemplateById(query.id)
            templateName.value = currentOrderTemplate.value.name
            orderDetails.value.subWarehouseId = currentOrderTemplate.value.subWarehouseId
            orderDetails.value.orderItems = currentOrderTemplate.value.orderItems
            addOrderController.value.submitBtn.text = 'Update Order Template'
        }

        loaded.value = true
    })

    const handleFormSubmitRes = async({orderItems, selectedWarehouse} : { orderItems: OrderItem[], selectedWarehouse: Selection }) => {
        const request = {
            id: currentOrderTemplate.value.id,
            name: templateName.value,
            subWarehouseId: selectedWarehouse.value,
            orderItems
        } as OrderTemplateDTO
        
        if (query.id) {
            if (await ordorderTemplateService.updateOrderTemplate(request)) {
                window.location.href = '/in/settings/order-templates'
            }
        } else {
            if (await ordorderTemplateService.createOrderTemplate(request)) {
                window.location.href = '/in/settings/order-templates'
            }
        }
    }

    const goBack = () => {
        window.location.href = '/in/settings/order-templates'
    }
</script>