<template>
    <div class="va-table-responsive" :style="{display: center ? 'flex' : 'inherit', 'justify-content': center ? 'center' : ''}">
        <table class="va-table va-table--hoverable" style="min-width: 375px;">
            <thead>
                <tr class="table-header-default">
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th v-if="props.showWeight">Weight</th>
                    <th v-if="props.showRate">Rate</th>
                    <th v-if="props.showNotes">Notes</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="orderItem of props.orderItems">
                    <td>{{ orderItem.name }}</td>
                    <td>{{ orderItem.quantity }}</td>
                    <td v-if="props.showWeight">{{ orderItem.weight ? (Math.round(orderItem.weight * 100) / 100).toFixed(2) : null }}</td>
                    <td v-if="props.showRate">${{ orderItem.rate }}</td>
                    <td v-if="props.showNotes">{{ orderItem.notes }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="props.showBackBtn || props.submitBtnText" class="flex md12 sm12 xs12 va-spacing-x-4 mt-5" style="text-align: center">
        <va-button v-if="props.showBackBtn" @click="goToPrevious" preset="outline">Back</va-button>
        <va-button v-if="props.submitBtnText" @click="submitForm">{{ props.submitBtnText }}</va-button>
    </div>
</template>
<script setup lang="ts">
    import type { PropType } from 'vue'
    import type OrderItem from '@/types/OrderItem'

    const emits = defineEmits(['submit', 'back'])
    const props = defineProps({
        subWarehouseId: String,
        orderItems: Object as PropType<OrderItem[]>,
        showBackBtn: Boolean,
        submitBtnText: String,
        center: Boolean,
        showNotes: Boolean,
        showWeight: Boolean,
        showRate: Boolean
    })

    const goToPrevious = () => emits('back')

    const submitForm = () => emits('submit')

    const qtyKeydown = (event: any) => {
        event.preventDefault()
    }
</script>
<style>
.table-header-default th {
    text-transform: capitalize;
    font-size: 15px;
}
</style>