import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type { InventoryColumnsDTO, PreferenceDTO } from '@/models/warems.models'

const preferenceService = {
    async getUserPrefence():Promise<PreferenceDTO> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/preference`)
        return res?.data.obj
    },
    async updateUserPrefence(preferenceDTO:PreferenceDTO):Promise<Boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/preference`, preferenceDTO)
        return res?.data.obj
    },
    async getInventoryColumns():Promise<InventoryColumnsDTO> {
        const res = await APIUtil.sendRequest('get', `${import.meta.env.VITE_APP_API_BASE}/preference/inventory-columns`)
        return res?.data.obj
    },
    async updateInventoryColumns(inventoryColumnsDTO:InventoryColumnsDTO):Promise<Boolean> {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/preference/inventory-columns`, inventoryColumnsDTO)
        return res?.data.obj
    },
};

export default preferenceService;