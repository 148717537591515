<template>
    <div class="qr-code-content" style="max-width: 150px;">
        <canvas ref="canvasRef"></canvas>
        <p class="qr-text">{{ props.info?.text }}</p>
        <!-- <p class="qr-id">...{{ props.info?.id.substring(props.info?.id.length -5) }}</p> -->
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import type { PropType } from 'vue';
    import QRCode from 'qrcode'

    import type QRCodeInfo from '@/types/QRCodeInfo'

    const canvasRef = ref<HTMLCanvasElement | null>(null);

    const props = defineProps({
        info: Object as PropType<QRCodeInfo>
    })

    // Life Cycles
    onMounted(() => {
        const canvas = canvasRef.value;
        if (!canvas) {
            console.error("Canvas is not available");
            return;
        }

        QRCode.toCanvas(canvas, props.info?.val, { errorCorrectionLevel: 'H', width:110, height:110 }, (error) => {
            if (error) console.error(error)
        })
    })
</script>
<style>
    .qr-code-content {
        text-align: center;
    }

    .qr-text {
        font-weight: bold;
    }

    @media print {
        body * {
            visibility: hidden;
            margin: 0 !important;
            padding: 0 !important;
        }

        #qr-print-contents, #qr-print-contents * {
            visibility: visible;
            margin-left: 5px !important;
        }
  }
</style>