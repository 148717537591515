import Utils from '@/utils/utils'

const ROLE_STORE = "STORE"
const ROLE_ADMIN = "ADMIN"
const ROLE_WAREHOUSE_ADMIN = "WAREHOUSE_ADMIN"
const ROLE_WAREHOUSE_OPS = "WAREHOUSE_OPS"

export default class AccessUtil {
    static hasAddOrderAccess(): boolean {
        return this.addOrderRoles().includes(Utils.getRoleFromToken())
    }

    static hasOrderConfirmAccess(): boolean {
        return this.orderCompleteRoles().includes(Utils.getRoleFromToken())
    }

    static hasInboundAccess(): boolean {
        return this.inboundRoles().includes(Utils.getRoleFromToken())
    }

    static hasOutboundAccess(): boolean {
        return this.outboundRoles().includes(Utils.getRoleFromToken())
    }

    static hasOrderProcessingAccess(): boolean {
        return this.orderProcessingRoles().includes(Utils.getRoleFromToken())
    }

    static hasOrderPrintAccess(): boolean {
        return this.orderPrintRoles().includes(Utils.getRoleFromToken())
    }

    static hasReturnAccess(): boolean {
        return this.returnRoles().includes(Utils.getRoleFromToken())
    }

    static hasInventoryAccess(): boolean {
        return this.inventoryRoles().includes(Utils.getRoleFromToken())
    }

    static hasAddInventoryAccess(): boolean {
        return this.addInventoryRoles().includes(Utils.getRoleFromToken())
    }

    static hasDeliveryReceiptAccess(): boolean {
        return this.deliveryReceiptRoles().includes(Utils.getRoleFromToken())
    }

    static hasAddDeliveryReceiptAccess(): boolean {
        return this.addDeliveryReceiptRoles().includes(Utils.getRoleFromToken())
    }

    static hasInvoiceGenerationAccess(): boolean {
        return this.generateInvoiceRoles().includes(Utils.getRoleFromToken())
    }

    static hasReportAccess(): boolean {
        return this.reportPageRoles().includes(Utils.getRoleFromToken())
    }

    static hasAdminReportAccess(): boolean {
        return this.adminReportPageRoles().includes(Utils.getRoleFromToken())
    }

    static hasAddressAccess(): boolean {
        return this.addressPageRoles().includes(Utils.getRoleFromToken())
    }

    static hasUserAccess(): boolean {
        return this.userPageRoles().includes(Utils.getRoleFromToken())
    }

    static hasProductPageAccess(): boolean {
        return this.productPageRoles().includes(Utils.getRoleFromToken())
    }

    static hasEditProductAccess(): boolean {
        return this.editProductPageRoles().includes(Utils.getRoleFromToken())
    }

    static hasCategoryPageAccess(): boolean {
        return this.categoryRoles().includes(Utils.getRoleFromToken())
    }

    static hasSettingsAccess(): boolean {
        return this.settingsRoles().includes(Utils.getRoleFromToken())
    }

    static isAdminGroup(): boolean {
        return this.adminRoles().includes(Utils.getRoleFromToken())
    }

    static isSystemAdmin(): boolean {
        return this.systemAdminRoles().includes(Utils.getRoleFromToken())
    }

    static systemAdminRoles(): string[] {
        return [ROLE_ADMIN]
    }

    static adminRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN]
    }

    static settingsRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_STORE]
    }

    static productPageRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN]
    }

    static editProductPageRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN]
    }

    static addressPageRoles(): string[] {
        return [ROLE_ADMIN, ROLE_STORE]
    }

    static userPageRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN]
    }

    static categoryRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN]
    }

    static reportPageRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_STORE, ROLE_WAREHOUSE_OPS]
    }

    static adminReportPageRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN]
    }

    static deliveryReceiptRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static addDeliveryReceiptRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static inventoryRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static addInventoryRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static returnRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static addReturnOrderRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static addOrderRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_STORE]
    }

    static inboundRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static viewInvoicesRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS, ROLE_STORE]
    }

    static generateInvoiceRoles(): string[] {
        if (Utils.getViewOnlyFromToken()) return [ROLE_ADMIN]
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static orderCompleteRoles(): string[] {
        return [ROLE_ADMIN, ROLE_STORE]
    }

    static outboundRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static orderProcessingRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static orderPrintRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS]
    }

    static allRoles(): string[] {
        return [ROLE_ADMIN, ROLE_WAREHOUSE_ADMIN, ROLE_WAREHOUSE_OPS, ROLE_STORE]
    }
}