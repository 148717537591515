import axios from 'axios'

import APIUtil from '@/utils/api-util'

import type { InventoryDTO, InventoryHistoryDTO } from '@/models/warems.models'
import type Inventory from '@/types/Inventory'

const inventoryService = {
    inboundRequest(data: any) {
        return axios.post(`${import.meta.env.VITE_APP_API_BASE}/inventory/inbound`, data)
    },
    async addInventory(data: any) {
        return await axios.post(`${import.meta.env.VITE_APP_API_BASE}/inventory`, data)
    },
    async updateInventory(inventory: Inventory) {
        const res = await APIUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/inventory`, inventory)
        return res?.data
    },
    async deleteInventory(inventoryIds: string[]) {
        const res = await APIUtil.sendRequest('delete', `${import.meta.env.VITE_APP_API_BASE}/inventory`, inventoryIds)
        return res?.data.obj
    },
    async getAllInventories() {
        return await axios.get(`${import.meta.env.VITE_APP_API_BASE}/inventory`)
    },
    async getInventory(inventoryId: string): Promise<InventoryDTO> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/inventory?id=${encodeURIComponent(inventoryId)}`)).data.obj
    },
    async getInventoryHistories(productId: string): Promise<InventoryHistoryDTO[]> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/inventory/history?pid=${encodeURIComponent(productId)}`)).data.list
    },
    async getInventoriesByProductId(productId: string, available?: Boolean):Promise<Inventory[]> {
        let url = `${import.meta.env.VITE_APP_API_BASE}/inventory?productId=${encodeURIComponent(productId)}`
        if (available) url += '&available=true'
        const res = await APIUtil.sendRequest('get', url)
        return res?.data.list
    },
    async downloadInventoryExcel() {
        return await axios.get(`${import.meta.env.VITE_APP_API_BASE}/document/inventory/download`, { responseType: 'blob' })
    }
};

export default inventoryService;