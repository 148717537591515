import axios from 'axios'

import type { AxiosResponse, AxiosError } from 'axios'

import ApiUtil from '@/utils/api-util'

const authService = {
    async login(form: any) {
        return await ApiUtil.sendRequest('post', `${import.meta.env.VITE_APP_BASE}/auth/login`, form)
    },
    async resetPwdToken(username: string) {
        return await axios.post(`${import.meta.env.VITE_APP_BASE}/auth/reset-pwd-token`, { username })
    },
    async register(data: any):Promise<Boolean> {
        return axios.post(`${import.meta.env.VITE_APP_BASE}/auth/register`, data).then((res: AxiosResponse) => {
            return true
        }, (err: AxiosError) => {
            window.alert(err.response?.data?.message)
            return false
        })
    },
    async resetPwd(password: string, token: string) {
        return await ApiUtil.sendRequest('post', `${import.meta.env.VITE_APP_BASE}/auth/reset-pwd`, { password, token })
    },
    async activeAccount(token: string) {
        const res = await ApiUtil.sendRequest('get', `${import.meta.env.VITE_APP_BASE}/auth/activation?token=${token}`)
        return res?.data.obj
    }
};

export default authService;