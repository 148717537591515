import axios from 'axios'

import type { UpdateUserProductByUserRequest } from '@/models/warems.models'

const userProductService = {
    async getProductIdsByStoreId(storeId: string): Promise<string[]> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/user-product/product-ids?storeId=${encodeURIComponent(storeId)}`)).data.obj
    },
    async getStoreIdsByProductId(productId: string): Promise<string[]> {
        return (await axios.get(`${import.meta.env.VITE_APP_API_BASE}/user-product/store-ids?productId=${encodeURIComponent(productId)}`)).data.obj
    },
    async updateUserProductByUser(request: UpdateUserProductByUserRequest) {
        return (await axios.put(`${import.meta.env.VITE_APP_API_BASE}/user-product/user`, request)).data.message
    }
};

export default userProductService