import LoginView from '@/views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import ResetPwdView from '@/views/auth/ResetPwdView.vue'
import ForgetPwdView from '@/views/auth/ForgetPwdView.vue'
import ActivationView from '@/views/others/ActivationView.vue'

export default [
  {
    path: 'login',
    name: 'login',
    component: LoginView
  },
  {
    path: 'register',
    name: 'register',
    component: RegisterView
  },
  {
    path: 'reset-pwd',
    name: 'reset-pwd',
    component: ResetPwdView
  },
  {
    path: 'forget-pwd',
    name: 'forget-pwd',
    component: ForgetPwdView
  },
  {
    path: 'activation',
    name: 'account-activation',
    component: ActivationView
  }
]