<template>
    <va-card class="mt-3 ml-5 mr-5">
        <va-card-content>
            <va-data-table class="customize-data-table" :items="mismatchProducts" :columns="columns" hoverable>
                <template #cell(name)="{ rowData }">
                    {{ rowData.secondaryName }}
                </template>
                <template #cell(activeInventory)="{ rowData }">
                    {{ rowData.activeInventoryCount }}
                </template>
                <template #cell(bookedInventory)="{ rowData }">
                    {{ rowData.bookedInventoryCount }}
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'

    import type { MismatchProduct } from '@/models/warems.models'

    import adminService from '@/services/admin-service'
    import { useLoadingStore } from '@/stores/loading-store'

    const { showLoading, hideLoading } = useLoadingStore()
    const mismatchProducts = ref([] as MismatchProduct[])
    const columns = [
        { key: 'productName' },
        { key: 'expectedQty' },
        { key: 'actualQty' },
        { key: 'expectedWeight' },
        { key: 'actualWeight' }
    ]

    onBeforeMount(async() => {
        showLoading()
        mismatchProducts.value = await adminService.getMismatchProducts()
        hideLoading()
    })
</script>