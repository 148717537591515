<template>
    <div class="va-table-responsive" style="margin: 20px" v-if="AccessUtil.isAdminGroup()">
        <h6 class="va-h6">Edit Invoice</h6>
        <table class="va-table va-table--hoverable">
            <thead>
                <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Weight</th>
                    <th>Rate</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(invoiceItem, idx) in props.invoice?.invoiceItems" :key="idx">
                    <td>
                        {{ invoiceItem.description }}
                        <div v-if="invoiceItem.weights" class="mt-2 va-spacing-x-2">
                            <va-input v-for="(weight, wi) of invoiceItem.weights" :key="wi" v-model="invoiceItem.weights[wi]" style="width: 60px;" preset="bordered"/>
                        </div>
                    </td>
                    <td>
                        <va-input v-model="invoiceItem.quantity" type="number" min="1" style="max-width: 80px;" v-if="!invoiceItem.weights && !invoiceItem.weight"/>
                        <span v-else>{{ invoiceItem.quantity }}</span>
                    </td>
                    <td>
                        <va-input v-model="invoiceItem.weight" type="number" min="0.01" style="max-width: 80px;" v-if="invoiceItem.weight" readonly/>
                        <span v-else>{{ invoiceItem.weight }}</span>
                    </td>
                    <td>
                        <va-input v-model="invoiceItem.rate" type="number" step="0.01" style="max-width: 110px;" min="0">
                            <template #prependInner>
                                <va-icon name="attach_money" />
                            </template>
                        </va-input>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="va-spacing-x-3" style="text-align: center;margin: 30px 0 20px 0;">
        <va-button @click="editInvoice" v-if="AccessUtil.hasInvoiceGenerationAccess()">Update</va-button>
        <va-button @click="emits('close')" v-if="AccessUtil.hasInvoiceGenerationAccess()" preset="outline">Back</va-button>
    </div>
</template>
<script setup lang="ts">
    import { useModal } from 'vuestic-ui'

    import type { PropType } from 'vue'
    import type RowIdentifier from '@/types/RowIdentifier'
    import type { UpdateInvoiceRequest, InvoiceDTO } from '@/models/warems.models'
    
    import invoiceService from '@/services/invoice-service'
    import AccessUtil from '@/utils/access'
    import Utils from '@/utils/utils'

    const emits = defineEmits(['close'])
    const { confirm } = useModal()
    const props = defineProps({
        invoice: Object as PropType<InvoiceDTO>,
        rowIdentifier: Object as PropType<RowIdentifier>,
    })

    const editInvoice = async() => {
        const payload = {
            invoiceId: props.invoice?.invoiceId,
            invoiceItems: props.invoice?.invoiceItems
        } as UpdateInvoiceRequest

        if (payload.invoiceItems) {
            for (let invoiceItem of payload.invoiceItems) {
                if (!invoiceItem.rate) invoiceItem.rate = 0
            }
        }
        
        if (await invoiceService.updateInvoice(payload)) {
            confirm({message: 'Invoice Updated!', cancelText: ''}).then(() => {
                if (props.rowIdentifier) {
                    Utils.setPreselectRow(props.rowIdentifier.id, props.rowIdentifier.currentPage)
                }
                window.location.reload()
            })
        }
    }
</script>