<template>
    <ProductFormComponent :product="productEdit" :selectedStoreIds="existSelectedStoreIds" :selectedAltProductIds="existAltProductIds" :edit="true"
        @submit="submitItem" @back="back"/>
    <va-modal v-model="applyPriceModal.show" no-outside-dismiss hideDefaultActions @close="closeApplyPriceModal">
        <div class="va-spacing-y-3">
            <div class="va-spacing-y-3" v-if="!showModifyPriceSection">
                <p class="va-h6">Do you want to modify existing invoices associated to this product?</p>
                <div class="row">
                    <va-button color="warning" @click="showModifyPriceSection=true">Yes</va-button>
                    <va-button class="ml-3" @click="back">No</va-button>
                </div>
            </div>
            <div v-else>
                <div class="va-spacing-y-3">
                    <p class="va-h6 mb-4">Modify Existing Invoices</p>
                    <div class="row">
                        <va-checkbox v-model="selectAllStoresBtn" label="All Stores" @update:modelValue="selectAllStores"/>
                    </div>
                    <div class="row va-spacing-x-3">
                        <va-select label="Stores" v-model="applyPriceModal.selectedStores" :options="applyPriceModal.storeSelection" 
                            :max-visible-options="5" style="max-width: 280px;" multiple highlight-matched-text :disabled="selectAllStoresBtn"/>
                        <va-date-input style="max-width: 210px;" v-model="applyPriceModal.filterDateRange" :format-date="Utils.formatDateOnDateTable" 
                            :parse-date="Utils.parseDateOnDateTable" label="Date Range"/>
                    </div>
                </div>
                <div class="row va-spacing-x-3 mt-5" style="justify-content: center;">
                    <va-button @click="appplyPriceAdjust">Submit</va-button>
                    <va-button preset="outline" @click="back">Cancel</va-button>
                </div>
            </div>
        </div>
    </va-modal>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useModal } from 'vuestic-ui'
    
    import type Product from '@/types/Product'
    import type Selection from '@/types/Selection'
    import type { UpdateProductRequest, UpdateInvoiceProductPriceRequest } from '@/models/warems.models'

    import productService from '@/services/product-service'
    import userProductService from '@/services/user-product-service'
    import userService from '@/services/user-service'
    import correctionService from '@/services/correction-service'
    import ProductFormComponent from './ProductFormComponent.vue'
    import Utils from '@/utils/utils'

    const { confirm } = useModal()
    const route = useRoute()
    const router = useRouter()
    const productEdit = ref({} as Product)
    const existSelectedStoreIds = ref([] as string[])
    const existAltProductIds = ref([] as string[])
    const productBackup = ref({} as Product)
    const applyPriceModal = ref({
        show: false,
        selectedStores: [] as Selection[],
        storeSelection: [] as Selection[],
        filterDateRange: { start: {} as Date, end: {} as Date }
    })
    const selectAllStoresBtn = ref(false)
    const showModifyPriceSection = ref(false)

    onBeforeMount(async() => {
        if (!route.query.id) return
        
        const product:Product = await productService.getProductById(route.query.id)
        // existAltProductIds.value = await productService.getAltProductIdsById(route.query.id)
        existSelectedStoreIds.value = await userProductService.getStoreIdsByProductId(product.productId)
        product.subWarehouseSelection = { text: product.subWarehouse?.name, value: product.subWarehouse?.id } as Selection
        product.categorySelection = { text: product.category?.name, value: product.category?.id } as Selection
        product.subCategorySelection = { text: product.subCategory?.name, value: product.subCategory?.id } as Selection
        product.brandSelection = { text: product.brand?.name, value: product.brand?.id } as Selection
        productEdit.value = product
        productBackup.value = JSON.parse(JSON.stringify(product))

        applyPriceModal.value.storeSelection = await userService.getAllStoreSelection()
        closeApplyPriceModal()
    })

    const submitItem = async(product: Product, selectedStoreIds: string[], selectedAltProducts: Product[]) => {
        const updateProductRequest:UpdateProductRequest = {
            product,
            storeIds: selectedStoreIds,
            altProducts: selectedAltProducts
        }

        try {
            if (await productService.updateProduct(updateProductRequest)) {
                confirm({message: 'Product updated.', cancelText: ''}).then(() => {
                    if (productBackup.value.price != product.price || productBackup.value.wholesalePrice != product.wholesalePrice) {
                        applyPriceModal.value.show = true
                    } else {
                        back()
                    }
                })
            }
        } catch(err: any) {
            confirm({ message: `Error: ${err}`, cancelText: '' })
        }
    }

    const back = () => {
        Utils.setPreselectRow(productEdit.value.productId, route.query.page)
        router.go(-1)
    }

    const closeApplyPriceModal = () => {
        let today = new Date();
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0)
        applyPriceModal.value.filterDateRange = { start: firstDayOfMonth, end: new Date() }

        applyPriceModal.value.selectedStores = [] as Selection[]
        applyPriceModal.value.show = false
    }

    const selectAllStores = (val: boolean) => {
        if (val) {
            applyPriceModal.value.selectedStores = JSON.parse(JSON.stringify(applyPriceModal.value.storeSelection))
        } else {
            applyPriceModal.value.selectedStores = []
        }
    }

    const appplyPriceAdjust = async() => {
        const request:UpdateInvoiceProductPriceRequest = {
            productId: decodeURIComponent(route.query.id),
            price: productEdit.value.price,
            wholesalePrice: productEdit.value.wholesalePrice,
            storeIds: applyPriceModal.value.selectedStores.map(i => i.value),
            start: applyPriceModal.value.filterDateRange.start.getTime(),
            end: applyPriceModal.value.filterDateRange.end.getTime(),
        }

        try {
            const updatedInvoiceIds = await correctionService.updateInvoiceProductPrice(request)
            confirm({message: updatedInvoiceIds && updatedInvoiceIds.length ? 
                ('Invoice price updated for this product. Invoices: ' + updatedInvoiceIds.join(', ')) : 
                'No Invoices being updated for this product.', cancelText: ''}).then(() => {
                back()
            })
        } catch(err: any) {
            confirm({ message: `Error: ${err}`, cancelText: '' })
        }
    }
</script>