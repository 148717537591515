<template>
    <va-button class="mr-2" v-if="props.qrCodeList && props.qrCodeList.length > 0" @click="printAvery22805">Print</va-button>
</template>
<script setup lang="ts">
    import type { PropType } from 'vue'
    import type QRCodeInfo from '@/types/QRCodeInfo'

    import qrCodeService from '@/services/qr-code-service'

    const props = defineProps({
        qrCodeList: Array as PropType<QRCodeInfo[]>
    })

    const printAvery22805 = async() => {
        if (!props.qrCodeList) return

        let response = await qrCodeService.downloadAvery22805PDF(props.qrCodeList)
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
    }
</script>