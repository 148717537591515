<template>
    <div v-if="allProducts.length">
        <div class="va-spacing-y-4">
            <div class="row va-spacing-x-4">
                <va-card class="mb-4" v-for="(product, index) in paginatedProducts" :key="index" style="width: 400px;">
                    <va-card-content>
                        <div style="justify-content: space-between;display: flex;">
                            <div>
                                <p style="font-weight: bold;">
                                    {{ product.secondaryName }}<span v-if="product.tertiaryName"> - {{ product.tertiaryName }}</span>
                                </p>
                                <p v-if="product.description" class="mt-1">({{ product.description }})</p>
                            </div>
                            <div v-if="props.controller?.isConfirm">
                                Selected Qty: {{ product.selectedQty }}
                            </div>
                            <div v-else>
                                <va-counter v-model="product.selectedQty" :min="0" :step="product.hasWeight==='Weighting' ? 0.01 : 1" 
                                    :manualInput="product.hasWeight==='Weighting'" @update:modelValue="handleProductSelection(product)"/>
                                <p class="mt-2" v-if="props.controller?.onlyAvailableProducts">{{ product.availableQty }} available</p>
                            </div>
                        </div>
                    </va-card-content>
                </va-card>
            </div>
        </div>

        <div class="flex mt-5">
            <va-pagination v-model="currentPage" :pages="pages" :visible-pages="10" class="justify-center sm:justify-start" @update:modelValue="updatePage"/>
        </div>

        <div class="flex md12 sm12 xs12 va-spacing-x-4 mt-5" style="text-align: center">
            <va-button class="mt-3" @click="goToPrevious" v-if="props.controller?.goBackBtn?.show">Go Back</va-button>
            <va-button @click="submitForm" class="mt-3" :disabled="disableBtn">{{ props.controller?.submitBtn?.text }}</va-button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, computed } from 'vue'

    import type { PropType } from 'vue'
    import type OrderItem from '@/types/OrderItem'
    import type OrderDetails from '@/types/OrderDetails'
    import type Product from '@/types/Product'
    import type OrderController from '@/types/OrderController'

    const emits = defineEmits(['submit', 'back'])
    const allProducts = ref([] as Product[])
    const selectedProducts = ref([] as Product[])
    const paginatedProducts = ref([] as Product[])
    const currentPage = ref(1)

    const props = defineProps({
        controller: Object as PropType<OrderController>
    })

    const pages = computed(() => {
        return Math.ceil(allProducts.value.length / 50)
    })

    const goToPrevious = () => emits('back')
    const submitForm = () => {
        const orderItems:OrderItem[] = []

        selectedProducts.value.forEach(product => {
            if (product.selectedQty) {
                orderItems.push({
                    name: product.tertiaryName ? `${product.secondaryName} - ${product.tertiaryName}` : product.secondaryName,
                    productId: product.productId,
                    quantity: product.hasWeight==='Weighting' ? null : product.selectedQty,
                    weight: product.hasWeight==='Weighting' ? product.selectedQty : null,
                    hasWeight: product.hasWeight,
                    isWeighting: product.hasWeight==='Weighting'
                } as OrderItem)
            }
        })

        emits('submit', orderItems)
    }

    const intialOrderItems = (allProducts_: Product[]) => {
        allProducts.value = [...allProducts_]
        updatePage()
    }

    const preloadOrderItems = (orderDetails: OrderDetails, showAll: boolean) => {
        if (!orderDetails.orderItems || orderDetails.orderItems.length === 0) return
        
        const selectedProducts:Product[] = []
        for (let orderItem of orderDetails.orderItems) {
            for (let product of allProducts.value) {
                if (product.productId !== orderItem.productId) continue
                if (showAll) { 
                    product.selectedQty = orderItem.quantity 
                } else { 
                    selectedProducts.push({...product, selectedQty: orderItem.quantity}) 
                }
                break
            }
        }

        if (!showAll) allProducts.value = [...selectedProducts]
    }

    const disableBtn = computed(() => {
        if (props.controller?.isConfirm) return false
        return !selectedProducts.value.find(product => product.selectedQty > 0)
    })

    const updatePage = () => {
        const start = (currentPage.value - 1) * 50
        paginatedProducts.value = allProducts.value.slice(start, start + 50)
        window.scrollTo({top:0})
    }

    const resetCurrentPage = () => {
        currentPage.value = 1
    }

    const handleProductSelection = (product: Product) => {
        if (product.selectedQty) {
            let idx = selectedProducts.value.findIndex(p=>p.productId===product.productId)
            if (idx === -1) selectedProducts.value.push(product)
        } else {
            let idx = selectedProducts.value.findIndex(p=>p.productId===product.productId)
            if (idx > -1) selectedProducts.value.slice(idx, 1)
        }
    }

    defineExpose({ intialOrderItems, preloadOrderItems, resetCurrentPage })
</script>
<style scoped>
.va-counter {
    min-width: 150px !important;
}
</style>