import ApiUtil from '@/utils/api-util'

import type { SyncupInvoiceRequest, UpdateInvoiceProductPriceRequest } from '@/models/warems.models'

const correctionService = {
    async syncupInvoiceItemRatesRequest(request:SyncupInvoiceRequest) {
        return await ApiUtil.sendRequest('post', `${import.meta.env.VITE_APP_API_BASE}/correction/sync-up-invoice-item-rates`, request)
    },
    async updateInvoiceProductPrice(request:UpdateInvoiceProductPriceRequest): Promise<string[]> {
        return (await ApiUtil.sendRequest('put', `${import.meta.env.VITE_APP_API_BASE}/correction/invoice-product-price`, request))?.data.list
    },
};

export default correctionService;